import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createIDBPersister } from './helpers/utils';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: Infinity,
            staleTime: 86400000, //24 hours
            networkMode: 'always'
        }
    }
});

const idbPersistor = createIDBPersister();


// // Check if the browser supports service workers
// if ('serviceWorker' in navigator) {
//     // Register the service worker
//     window.addEventListener('load', () => {
//         navigator.serviceWorker.register('/service-worker.js')
//             .then(registration => {
//                 console.log('Service Worker registered:', registration);
//
//                 // Listen for updates to the service worker
//                 registration.onupdatefound = () => {
//                     const installingWorker = registration.installing;
//                     if (installingWorker == null) {
//                         return;
//                     }
//                     installingWorker.onstatechange = () => {
//                         if (installingWorker.state === 'installed') {
//                             if (navigator.serviceWorker.controller) {
//                                 // New content is available; notify the user
//                                 alert('A new version of the ap is available. Please refresh the page.');
//                             } else {
//                                 // Content is cached for offline use
//                                 console.log('Content is cached for offline use.');
//                             }
//                         }
//                     };
//                 };
//             })
//             .catch(error => {
//                 console.error('Service Worker registration failed:', error);
//             });
//     });
// }

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <HashRouter>
            <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: idbPersistor }}>
                <AppWrapper></AppWrapper>
                <ReactQueryDevtools initialIsOpen={false} />
            </PersistQueryClientProvider>
        </HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
