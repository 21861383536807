import React, { ChangeEvent } from "react";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";

interface PasswordInputProps {
    passwordValue?: string;
    onPasswordChange: (e: ChangeEvent<HTMLInputElement>) => void;
    groupIcon?: string;
    inputLabel?: string;
    name: string;
    inputPlaceholder?:string,
    showHeader?:boolean,
    showFooter?:boolean
}

function PasswordInput({
                           passwordValue = "",
                           onPasswordChange,
                           groupIcon = "",
                           inputLabel = "",
                           name = "",
                           inputPlaceholder='Input Password',showHeader=false,showFooter=false
                       }: PasswordInputProps) {
    const header = <h6>{inputLabel}</h6>;

    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="col-12 pt-0">
            <Password
                className="expanded-control"
                placeholder={inputPlaceholder}
                value={passwordValue}
                onChange={onPasswordChange}
                toggleMask
                name={name}
                inputId={name}
                header={showHeader && header}
                footer={showFooter && footer}
                promptLabel="Enter password"
                weakLabel="Too simple"
                mediumLabel="Average complexity"
                strongLabel="Complex password"
            />
        </div>
    );
}

export default PasswordInput;

