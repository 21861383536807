import React, { useRef, Fragment, useState } from 'react';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import {Column} from "primereact/column";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';

interface ColumnDefinition {
    field?: string;
    header: string;
    body?: (rowData: any) => JSX.Element;
}

interface SimpleTableWithMenuProps {
    tableKey: string;
    columnsDef: ColumnDefinition[];
    tableData: any[]|undefined;
    showTableLines?: boolean;
    tableMinWidth?: number;
    menuModel?: MenuItem[];
    tableTitle?: string;
    lastTableUpdate?:number;
    numberOfRows?:number,
    searchValues?:string[],
    searchFieldPlaceHolder?:string,
    appColorScheme?:string,
    pageRows?:number[]
}

const SimpleTableWithMenu = ({
                                     tableKey,
                                     columnsDef,
                                     tableData,
                                     showTableLines = true,
                                     tableMinWidth = 80,
                                     menuModel,
                                     tableTitle = 'Table Data',
    numberOfRows=5,
    pageRows=[5, 10, 25, 50],
    lastTableUpdate,
    searchFieldPlaceHolder='',
    searchValues=[],
    appColorScheme='dark'
                                 }: SimpleTableWithMenuProps) => {
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
    });
    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...filters};

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div>
                <Menubar
                    model={menuModel}
                    end={
                        <div className="w-25rem font-bold">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={searchFieldPlaceHolder} autoComplete="off" />
                            </span>
                        </div>
                    }
                />
            </div>
        );
    };
    return (
        <React.Fragment>
            <DataTable
                style={{ zIndex: 10 }}
                value={tableData}
                paginator
                paginatorTemplate={'RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'}
                rows={numberOfRows}
                scrollable
                showGridlines={showTableLines}
                dataKey={tableKey}
                rowsPerPageOptions={pageRows}
                tableStyle={{ minWidth: `${tableMinWidth}rem` }}
                stripedRows
                header={renderHeader()}
                globalFilterFields={searchValues}
                filters={filters}
                currentPageReportTemplate="{first} to {last} of {totalRecords} Records"
                className={`${appColorScheme==='light'?'table-with-even-rows-white':'table-with-even-rows'}`}
            >
                {columnsDef.length > 0 && columnsDef.map((columnDef, index) => <Column key={index} field={columnDef.field} header={columnDef.header} body={columnDef.body} />)}
            </DataTable>
            {lastTableUpdate && <span className="smal">{`${new Date(lastTableUpdate!)}`}</span>}
            {/*<Tag value={`${new Date(lastTableUpdate!)}`} severity="success" className="mt-3" icon="pi pi-refresh"/>*/}
        </React.Fragment>
    );
};

export default React.memo(SimpleTableWithMenu);
