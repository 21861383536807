import { Button } from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import {
    DropdownOption,
    categoriesRelatedCatSubCatQuery,
    displayMessage,
    formatDate,
    getDateAndTime,
    getDefaultUserSettings,
    getOnlineData,
    isAppOnline,
    itemsListQuery,
    onInputControlFocus,
    pageDataValidation,
    remakeDropdownSelects,
    storeItemsListQuery,
    storeOutletsListQuery,
    useUserLocalStorage
} from '../../helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { GeneralPageProps, promptUserAction } from '../../helpers/utilComponents';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { clearOfflineDatabase, exportDexieDB, importAndRestoreDatabase } from '../../offline/db';
import DBUtils from '../../offline/DBUtils';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { TCustomer, TPerson, TSupplier } from '../../types/TPerson';
import { TStorePage } from '../../helpers/customTypesUtils';
import {
    IGiftCards,
    IOutlet,
    IOutLetItem,
    IPromotionalDiscounts,
    ITaxes,
    ITermsConditions,
    TAdjustmentTransaction,
    TCustomerPayment,
    TCustomerTypesAndCategories,
    TExpenditure,
    TExpenditureCategory,
    TGeneralPage,
    TItemPageData,
    TPriceChangeTransaction,
    TProformaInvoice,
    TPurchaseTransactionItem,
    TSaleTransactionItem,
    TStoreSubscription,
    TSupplierPayment,
    TTransferTransaction
} from '../../types/UtilTypes';
import { RegistrationPageInit } from '../../types/TStore';
import SettingsArena from '../../classes/SettingsArena';
import Queue from '../../classes/Queue';
import Joi from 'joi';
import { useNavigate } from 'react-router-dom';
import FilterSelect from '../../helpers/components/FilterSelect';
import GeneralPurpose from '../../classes/GeneralPurpose';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Loader } from '../sharedComponents/SharedComponents';
import SubscriptionPayment from './SubscriptionPayment';
import { useGlobalContext } from '../../helpers/GlobeManagement';
import SimpleTableWithMenu from '../../helpers/components/SimpleTableWithMenu';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
const _ = require('lodash');
type TAdminValue = TGeneralPage<any> & {
    syncInProgress: boolean;
    progressValue: number;
    showProgressValue: boolean;
    showStockMoveDialog: boolean;
    fromLocation: number;
    toLocation: number;
    storeOutlets: IOutlet[];
    availableOutlets: DropdownOption[];
    fromDescription: string;
    toDescription: string;
    showSubscriptionPayment: boolean;
    movableStock: IOutLetItem[];
    showMovableStockDialogue: boolean;
    updatableItemsList: any;
};
const INITIAL_STATE: TAdminValue = {
    editingObjectId: 0,
    editingState: false,
    hasPageLoaded: false,
    isLoading: true,
    showDialog: false,
    userSettings: getDefaultUserSettings(),
    syncInProgress: false,
    progressValue: 0,
    showProgressValue: false,
    showStockMoveDialog: false,
    fromLocation: 0,
    toLocation: 0,
    storeOutlets: [],
    availableOutlets: [],
    fromDescription: '',
    toDescription: '',
    showSubscriptionPayment: false,
    movableStock: [],
    showMovableStockDialogue: false,
    updatableItemsList: []
};
const dbUtilities = new DBUtils();
const settingsArena = new SettingsArena();
const queue = new Queue();
const generalPurpose = new GeneralPurpose();

const validateStockCopy: Joi.ObjectSchema<any> = Joi.object({
    fromLocation: Joi.number().min(1).messages({ 'number.min': 'Select Location From which copy is been iniated from.' }),
    toLocation: Joi.number().min(1).required().invalid(Joi.ref('fromLocation')).messages({
        'number.min': 'Select location to which items are being copied to.',
        'any.invalid': 'Ensure that different outlets are selected'
    })
});
const AdminDefiners = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const toastRef = useRef(null);
    const [personData] = useUserLocalStorage();
    const [state, setState] = useState(INITIAL_STATE);
    const { socket } = useGlobalContext();

    useEffect(() => {
        if (!personData) navigate('/login');
        // if (!allowAccessState('admin_panel', personData?.userRole as number)) navigate('/sales');
        syncAdminPage().catch(console.error);
    }, []);
    useEffect(() => {
        console.log('socket init');
        // socket?.on('response',(data,otherValue,callBack)=>{
        //     console.log(data);
        //     console.log(otherValue);
        //     callBack({status:'ok'})
        // })
        socket?.on('message', (data) => {
            console.log(data);
        });
    }, []);
    const syncAdminPage = async () => {
        const userSettings = { ...state.userSettings, ...(await generalPurpose.getUserSettings()) };
        const appIsOnline = isAppOnline(userSettings);
        const storeOutlets = appIsOnline ? await storeOutletsListQuery(queryClient, personData!) : (await generalPurpose.getLocalStoresAndOutlets()).outlets;
        const availableOutlets = remakeDropdownSelects(storeOutlets, 'outletDescription', 'outletId');
        setStateValues({
            availableOutlets,
            storeOutlets,
            isLoading: false
        });
    };
    /** Clears local cached indexDB database. This means any fetch again next time after this operation
     * will perform a full fetch to the remote server for the data. Don't use this option if not for necessity
     @returns {Promise<void>}
     */
    const resetAppData = async (): Promise<void> => {
        await queryClient.invalidateQueries();
        displayMessage({
            toastComponent: toastRef,
            header: 'Application Reset Success',
            message: "Application's stored local data was successfully removed and cleared.!",
            infoType: 'success',
            life: 3000
        });
    };

    const setStateValues = (stateValues: Partial<TAdminValue>) => {
        setState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    /** Exports an indexdb and save it to a local folder for the intent as a backup file.
     @returns {Promise<void>}
     */
    const exportLocalDB = async (): Promise<void> => {
        try {
            await exportDexieDB();
            displayMessage({
                toastComponent: toastRef,
                header: 'Export Success',
                message: 'Local Database was successfully exported and backed up. You are safe!',
                infoType: 'success',
                life: 3000
            });
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        }
    };
    /** Imports a locally exported indexdb back to project.
     @returns {Promise<void>}
     */
    const importDexieDB = async (event: FileUploadHandlerEvent): Promise<void> => {
        try {
            const file = event.files[0];
            await importAndRestoreDatabase(file);
            displayMessage({
                toastComponent: toastRef,
                header: 'Import Success',
                message: 'Local Database was successfully restored!',
                infoType: 'success',
                life: 3000
            });
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        }
    };

    /** Checks if local index db is persisted.
     @returns {Promise<boolean>} Promise resolved with true if data is indeed persisted.
     */
    const checkDBPersistence = async (): Promise<boolean> => {
        const dbPersistState = await dbUtilities.persist();
        return dbPersistState;
    };
    /**
     * This clears the local dataDB of any data that has been saved. User is prompted to carefully
     * consider the action since there is no way to retrieve the cleared data.
     */
    const removeLocalIndexDB = async () => {
        displayMessage({
            toastComponent: toastRef,
            header: 'Action Unavailable',
            message: 'This action is currently unavailable!',
            infoType: 'info',
            life: 3000
        });
        return;
        await clearOfflineDatabase();
        displayMessage({
            toastComponent: toastRef,
            header: 'Clear Success',
            message: 'Your Local DB was successfully cleared of any data!',
            infoType: 'success',
            life: 3000
        });
    };
    const promptDBClearing = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({
            yesAction: removeLocalIndexDB,
            yesActionDisplay: 'Yes Clear DB',
            noActionDisplay: 'HM! Let me think Again',
            event,
            displayText: `You are about to delete the local database for app. Make sure you have backed up
            your data before attempting this execution since action cannot be reversed. 
            Choose No to Abort or Yes to Proceed!`
        });
    };
    const promptDBDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({
            yesAction: exportLocalDB,
            yesActionDisplay: 'Yes Export DB',
            noActionDisplay: 'I Will Export Later',
            event,
            displayText: `Action will download a complete copy of your Local Data as a backup. 
            It is imperative that you do this very often!`
        });
    };

    /** Function sync data from remote server to local storage. The sync to local DB uses a put
     * method to populate the local tables. Should one of the syncs fail and the process has to
     * be rerun, there is no duplicate of data because the initial values synced will just
     * be overridden by the new process. Each resolved process returns true. If any of the process
     * returns false, the process will be restarted anon. There is the future notion to track which ones
     * were successful and which ones were not so that a rerun will exclude the successful ones.
     @returns {Promise<void>} Promise resolved with void since no further data is returned.
     */
    const bringRemoteDataToLocal = async (): Promise<void> => {
        try {
            const lastSyncDate = getDateAndTime((await settingsArena.getLastRemoteSyncTime()) as Date);
            setStateValues({ isLoading: true });
            const storeId = personData?.storeId;

            const discountsLink = `promotional_discount/get_promotional_discount_remote_sync?discountStore=${storeId}&lastSyncDate=${lastSyncDate}`;
            const taxesLink = `taxes/get_taxes_remote_sync?storedId=${storeId}&lastSyncDate=${lastSyncDate}`;
            const giftCardsLink = `gift_cards/get_gift_cards_remote_sync?storeId=${storeId}&lastSyncDate=${lastSyncDate}`;
            const storeTypesAndOutletCategoriesLink: string = `store_types/get_store_types`;
            const termsAndConditionsLink = `terms_conditions/get_terms_conditions_remote_sync?storeId=${storeId}&lastSyncDate=${lastSyncDate}`;
            const expendituresLink = `expenditure/get_expenditures_remote_sync?storeId=${storeId}&lastSyncDate=${lastSyncDate}`;
            const customersLInk = `customers/get_store_customers_remote_sync?storeId=${storeId}&lastSyncDate=${lastSyncDate}`;
            const suppliersLink = `suppliers/get_store_suppliers_remote_sync?storeId=${personData?.storeId}&lastSyncDate=${lastSyncDate}`;
            const usersLink = `store_users/get_app_users_remote_sync?storeId=${personData?.storeId}&lastSyncDate=${lastSyncDate}`;
            const storesLink = `stores/get_stores_list?ownerId=${personData?.personId}&lastSyncDate=${lastSyncDate}`;
            const itemsLink = `items/sync_items_list_remote_sync`;
            const storeOutletLink = `outlets/get_store_outlets_remote_sync?storeId=${personData?.storeId}&lastSyncDate=${lastSyncDate}`;
            const customerTypesCategoriesLink: string = `customers/get_customer_types_and_categories`;
            const salesLink = `sales/get_sale_transactions_remote_sync`;
            const purchasesLink = `purchases/get_purchase_transactions_remote_sync`;
            const adjustmentsLink = `adjustment/get_adjustments_remote_sync`;
            const customerPaymentsLink = `customers/get_customer_payments_remote_sync?storeOutletId=${personData?.outletId}&lastSyncDate=${lastSyncDate}`;
            const supplierPaymentsLink = `suppliers/get_supplier_payments_remote_sync?storeOutletId=${personData?.outletId}&lastSyncDate=${lastSyncDate}`;
            const proformaInvoicesLink = `sales/get_proforma_invoices_remote_sync?storeOutletId=${personData?.outletId}&lastSyncDate=${lastSyncDate}`;
            const transfersLink = `transfers/get_transfer_items_remote_sync?storeOutletId=${personData?.outletId}&lastSyncDate=${lastSyncDate}`;
            const priceChangesLinik = `items/get_price_change_history_remote_sync?storeId=${personData?.storeId!}&lastSyncDate=${lastSyncDate}`;
            const storeSubscriptionLink = `stores/get_store_subscription?storeId=${personData?.storeId!}`;
            const expenditureCategoriesLink = `expenditure/get_remote_expense_categories?storeId=${personData?.storeId!}`;

            setStateValues({ syncInProgress: true, isLoading: true });
            const customersList = await getOnlineData<TCustomer[]>(customersLInk);

            const customersSyncState = await settingsArena.syncCustomersList(customersList, personData?.storeId!);

            setStateValues({ progressValue: 7 });
            const suppliersList = await getOnlineData<TSupplier[]>(suppliersLink);
            const suppliersSyncState = await settingsArena.synSuppliersList(suppliersList, personData?.storeId!);

            setStateValues({ progressValue: 15 });
            const usersList = await getOnlineData<TPerson[]>(usersLink);
            const usersListSyncState = await settingsArena.syncUsersList(usersList, personData?.storeId!);

            setStateValues({ progressValue: 23 });
            const storesList = await getOnlineData<TStorePage[]>(storesLink);

            const storesListSyncState = await settingsArena.syncStoresList(storesList);

            setStateValues({ progressValue: 28 });
            const outletsList = await getOnlineData<IOutlet[]>(storeOutletLink);
            const outletsListSyncState = await settingsArena.syncOutletsList(outletsList, personData?.storeId!);

            setStateValues({ progressValue: 33 });
            // HANDLE THIS WITH A LOOP.
            // FIRST GET THE TOTAL NUMBER OF ITEMS THAT HAS TO BE SYNC.

            const itemsList: IOutLetItem[] = await settingsArena.getOnlinePaginatedItems<IOutLetItem>(personData?.storeId!, itemsLink, lastSyncDate, 'items/get_store_items_count');

            const itemsListSyncState = await settingsArena.syncOutletItemsList(itemsList, personData?.storeId!);

            setStateValues({ progressValue: 38 });
            const itemPageData: TItemPageData = await settingsArena.getOnlineCategoriesRelatedAndSubcategories(personData?.outletId!);
            const itemsPageMetaSyncState = await settingsArena.synItemPageMetaData(itemPageData);

            setStateValues({ progressValue: 44 });
            const customerTypesAndCategories = await getOnlineData<TCustomerTypesAndCategories>(customerTypesCategoriesLink);
            const syncCustomerTypesCategoriesSyncState = await settingsArena.syncCustomerTypesAndCategories(customerTypesAndCategories);

            setStateValues({ progressValue: 49 });
            const discountsList = await getOnlineData<IPromotionalDiscounts[]>(discountsLink);
            const discountsListSyncState = await settingsArena.syncDiscountsList(discountsList, personData?.storeId!);

            setStateValues({ progressValue: 52 });
            const taxesList = await getOnlineData<ITaxes[]>(taxesLink);
            const taxesListSyncState = await settingsArena.syncOnlineTaxes(taxesList, personData?.storeId!);

            setStateValues({ progressValue: 57 });
            const giftCardsList = await getOnlineData<IGiftCards[]>(giftCardsLink);
            const giftCardsSyncState = await settingsArena.syncOnlineGiftCards(giftCardsList, personData?.storeId!);

            setStateValues({ progressValue: 63 });
            const termsList = await getOnlineData<ITermsConditions[]>(termsAndConditionsLink);
            const termsConditionsSyncState = await settingsArena.syncTermsConditions(termsList, personData?.storeId!);

            setStateValues({ progressValue: 69 });

            const expendituresList = await getOnlineData<TExpenditure[]>(expendituresLink);

            const expenseSyncState = await settingsArena.syncExpenditures(expendituresList, personData?.storeId!);

            setStateValues({ progressValue: 73 });
            const storeTypesAndOutletCategories = await getOnlineData<RegistrationPageInit>(storeTypesAndOutletCategoriesLink);
            const storesTypesSyncState = await settingsArena.syncStoreCategoriesAndStoreTypes(storeTypesAndOutletCategories);

            setStateValues({ progressValue: 77 });
            const salesList: TSaleTransactionItem[] = await settingsArena.getOnlinePaginatedItems<TSaleTransactionItem>(personData?.outletId!, salesLink, lastSyncDate, 'sales/count_remote_sales');
            const salesListSyncState = await settingsArena.syncSalesList(salesList, personData?.outletId!);

            setStateValues({ progressValue: 80 });

            const purchasesList: TPurchaseTransactionItem[] = await settingsArena.getOnlinePaginatedItems<TPurchaseTransactionItem>(personData?.outletId!, purchasesLink, lastSyncDate, 'purchases/count_remote_purchases');

            const purchaseListSyncState = await settingsArena.syncPurchasesList(purchasesList, personData?.outletId!);
            setStateValues({ progressValue: 83 });

            const adjustmentsLists: TAdjustmentTransaction[] = await settingsArena.getOnlinePaginatedItems<TAdjustmentTransaction>(personData?.outletId!, adjustmentsLink, lastSyncDate, 'adjustments/count_remote_adjustments');

            const adjustmentsListSyncState = await settingsArena.syncAdjustmentsList(adjustmentsLists, personData?.outletId!);

            setStateValues({ progressValue: 88 });

            const customerPaymentsList = await getOnlineData<TCustomerPayment[]>(customerPaymentsLink);
            const customerPaymentsState = await settingsArena.syncCustomerPayments(customerPaymentsList, personData?.outletId!);
            setStateValues({ progressValue: 92 });

            const supplierPaymentsList = await getOnlineData<TSupplierPayment[]>(supplierPaymentsLink);
            const suppliersPaymentState = await settingsArena.syncSupplierPayments(supplierPaymentsList, personData?.outletId!);
            setStateValues({ progressValue: 95 });

            const proformaInvoicesList = await getOnlineData<TProformaInvoice[]>(proformaInvoicesLink);
            const proformaListState = await settingsArena.syncProformaInvoicesList(proformaInvoicesList, personData?.outletId!);
            setStateValues({ progressValue: 98 });

            const transfersList = await getOnlineData<TTransferTransaction[]>(transfersLink);

            const transfersState = await settingsArena.syncTransfersList(transfersList, personData?.outletId!);

            const priceChangeList = await getOnlineData<TPriceChangeTransaction[]>(priceChangesLinik);

            const priceChangeState = await settingsArena.syncPriceChangeToLocal(priceChangeList, personData?.outletId!);

            const subscription = await getOnlineData<TStoreSubscription[]>(storeSubscriptionLink);

            const storeSubscription = await settingsArena.syncStoreSubscription(subscription);

            const expenditureCategoriesList = await getOnlineData<TExpenditureCategory[]>(expenditureCategoriesLink);

            const expenditureCategories = await settingsArena.syncExpenditureCategories(expenditureCategoriesList);

            setStateValues({ progressValue: 100 });

            // //NOW GET ALL THE ITEMS THAT MIGHT HAVE THEIR STOCK AFFECTED AND UPDATE THEIR QUANTITY;
            const allItemsAffected = await settingsArena.getAllTransactionItems(salesList, purchasesList, transfersList, adjustmentsLists);
            await settingsArena.updateSyncItemsStock(itemsList, allItemsAffected, personData?.outletId!);

            setStateValues({ showDialog: false, progressValue: 0 });
            
            if (
                customersSyncState &&
                suppliersSyncState &&
                usersListSyncState &&
                storesListSyncState &&
                outletsListSyncState &&
                itemsListSyncState &&
                itemsPageMetaSyncState &&
                syncCustomerTypesCategoriesSyncState &&
                discountsListSyncState &&
                taxesListSyncState &&
                giftCardsSyncState &&
                termsConditionsSyncState &&
                expenseSyncState &&
                storesTypesSyncState &&
                salesListSyncState &&
                purchaseListSyncState &&
                adjustmentsListSyncState &&
                customerPaymentsState &&
                suppliersPaymentState &&
                proformaListState &&
                transfersState &&
                priceChangeState
            ) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Sync Success',
                    message: 'Syncing Remote Data with Local Storage was successful!',
                    infoType: 'success',
                    life: 3000
                });
                await settingsArena.createCashCustomer();
                await settingsArena.setLastRemoteSyncTimeToLocal(); //update last remote sync time locally.
            } else {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Sync Fail',
                    message: 'A process was not successfully completed. You might want to execute the process again!',
                    infoType: 'error',
                    life: 3000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    /** Function synchronises app state by taking queued offline data and send them to remote server.
     * It begins by syncing queued item categories. the resulting data is used to update item related categories if any.
     * The resulting data from Item Related Category is used to updated item Sub categories by updating it online as well.
     * This activity helps sync dependency properties of item like category, related category, subcategory and brand so that
     * their IDs matches the same of the online IDs for category, related category, subcategory and brand. Thus generated Ids for
     * online data and offline data aren't exactly the same hence the need for this operation. When the queued upload is complete,
     * we clear the queue to avoid taking the same data over the network in future sync. 
     * 
     @returns {Promise<void>} Promise resolved with void since no further data is returned.
     */
    const onLineSyncing = async (): Promise<void> => {
        //THE REMOTE SERVER AND BE COMBINED WITH LOCAL DATA FOR QUICK LOADS AND FASTER PROCESSING TIME
        try {
            setStateValues({ syncInProgress: true, isLoading: true });
            const queuedData = await settingsArena.getQueuedData();
            setStateValues({ progressValue: 8 });

            /**
             * Users are synced first with locally queued users sent over to be saved to the remote server. After which all users are retrieved from the
             * server and used for further saving processing for other syncing functions. Thus the userId for subsequent transactions (sales, purchase, proforma,transfer)
             * will be modified to reflect the online saved userId. this ensure that the online userId will be the same that shall be saved along subsequent transactions to the remote server.
             *  The remote data holds prime and ultimum esteem than local saved data.
             */

            const syncedItemCategories = await settingsArena.syncItemCategories(queuedData?.itemCategories!, personData?.outletId!);

            const syncUsers = await settingsArena.syncUsers(queuedData?.users!, personData?.outletId!);
            setStateValues({ progressValue: 15 });

            setStateValues({ progressValue: 20 });
            const syncedItemRelatedCategories = await settingsArena.syncItemRelatedCategories(syncedItemCategories, queuedData?.relatedCategories!, personData?.outletId!);
            setStateValues({ progressValue: 26 });

            const syncSubCategories = await settingsArena.syncItemSubCategories(queuedData?.subCategories!, syncedItemRelatedCategories!, personData?.outletId!);
            setStateValues({ progressValue: 32 });

            /**
             * Items are synced to the remote data and at the same time retrieved so that subsequent syncs that involves items will have the remote saved item Id to
             * perform their operations. this will ensure that online items are the same as the local items after sync.
             */
            const syncItems = await settingsArena.syncOutletItems(syncedItemCategories, syncedItemRelatedCategories, syncSubCategories, queuedData?.outletItems!, personData?.outletId!, syncUsers);
            setStateValues({ progressValue: 36 });

            const syncCustomers = await settingsArena.syncCustomers(queuedData?.customers!, personData?.outletId!, syncUsers);
            setStateValues({ progressValue: 42 });

            const syncSalesItems = await settingsArena.syncSalesItems(syncItems, queuedData?.sales!, syncCustomers, syncUsers);

            const syncProformaToRemote = await settingsArena.syncProformaInvoicesToRemote(syncItems, queuedData?.proformaInvoices!, syncCustomers, syncUsers);
            setStateValues({ progressValue: 47 });

            const syncTransferToRemote = await settingsArena.syncTransfersToRemote(syncItems, queuedData?.transfers!, syncUsers);
            /**
             * Sync suppliers takes local queued suppliers to the remote server and returns the remote server suppliers for processing of
             * purchase data for remote sync as well.
             */
            const syncSuppliers = await settingsArena.syncSuppliers(queuedData!.suppliers, personData?.outletId!, syncUsers);
            setStateValues({ progressValue: 56 });

            const syncPurchases = await settingsArena.syncPurchases(queuedData?.purchases!, syncSuppliers, syncItems, syncUsers);
            setStateValues({ progressValue: 68 });
            const syncAdjustments = await settingsArena.synAdjustmentTransactionsToRemote(queuedData?.adjustments!, syncItems, syncUsers);
            setStateValues({ progressValue: 76 });
            const syncUtilityTables = await settingsArena.syncDiscountsTaxesGiftsTermsAndExpenditure(queuedData?.discounts!, queuedData?.taxes!, queuedData?.gifts!, queuedData?.terms!, queuedData?.expenditures!, personData?.outletId!, syncUsers);

            setStateValues({ progressValue: 80 });

            const syncCustomerAndSupplierPayments = await settingsArena.syncCustomerAndSupplierPaymentsToRemote(queuedData?.accountsPayments!, queuedData?.supplierPayments!, syncCustomers, syncSuppliers, syncUsers);

            setStateValues({ progressValue: 85 });

            setStateValues({ progressValue: 92 });

            const syncPricesToRemote = await settingsArena.syncPriceChangeToRemote(syncItems, queuedData?.priceChangeHistory!, personData?.outletId!, syncUsers);

            const syncExpenseCategoriesToRemote = await settingsArena.syncExpenseCatetgoriesOnline(queuedData?.expenseCategories!, personData?.outletId!);

            setStateValues({ progressValue: 100 });

            // await queue.saveQueuedTableData(queuedData!);
            // await settingsArena.clearQueuedData();
            // await queue.createNewQue(); //save this pushed data for future reference and call back.
            displayMessage({
                toastComponent: toastRef,
                header: 'Sync Success',
                message: 'Syncing Local Data to Remote Server was successful!',
                infoType: 'success',
                life: 3000
            });
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        } finally {
            setStateValues({ syncInProgress: false, progressValue: 0, isLoading: false });
        }
    };

    /**
     * User is prompted about the gravity of the activity and given the options to continue or not.
     * @param event - the button clicked event is emitted by this action.
     */
    const promptItemsToRemoteSync = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({
            yesAction: onLineSyncing,
            event,
            displayText: 'Your action determines to sync queued Local Data to a Remote server. ' + 'Whiles the sync is in progress, be patient and wait for it to complete'
        });
    };
    /**
     * User is prompted on if they wish to really continue to sync items to remote server.
     * @param event
     */
    const promptItemsToLocalSync = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({
            yesAction: bringRemoteDataToLocal,
            event,
            displayText: 'Your chosen action suggests you are bringing Remote Data to Local Storage. ' + 'While the sync is in progress, be patient and wait for it to complete! Choose Yes to proceed or No to abort!'
        });
    };

    const onFromLocationChange = (e: DropdownChangeEvent) => {
        const selectedOutlet = state.storeOutlets.find((outlet) => outlet.outletId === parseInt(e.value));
        setStateValues({ fromDescription: selectedOutlet?.outletDescription, fromLocation: e.value });
    };
    const onToLocationChange = (e: DropdownChangeEvent) => {
        const selectedOutlet = state.storeOutlets.find((outlet) => outlet.outletId === parseInt(e.value));
        setStateValues({ toDescription: selectedOutlet?.outletDescription, toLocation: e.value });
    };
    const promptStockMove = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!pageDataValidation(validateStockCopy, { fromLocation: state.fromLocation, toLocation: state.toLocation }, toastRef)) {
            return;
        }
        promptUserAction({
            yesAction: onStockMoveWithQuantity,
            event,
            displayText: `Stock items will be copied from ${state.fromDescription} to ${state.toDescription}. Action cannot be undone. Proceed?`
        });
    };

    const onStockMoveWithQuantity=async()=>{
        try{
            setStateValues({ isLoading: true });
            const stockCopyResponse = await generalPurpose.copyStockWithQuantityToRemote(state.updatableItemsList);
            if(stockCopyResponse===1){
                queryClient.invalidateQueries(['itemsList']);
                queryClient.invalidateQueries(['storeItemsList']);
                queryClient.invalidateQueries(['itemsPageData']);
                await storeItemsListQuery(queryClient, personData!);
                await itemsListQuery(queryClient, personData!);
                await categoriesRelatedCatSubCatQuery(queryClient, personData!);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Copy Success',
                    message: 'Updated Items were successfully copied to selected destination location!',
                    infoType: 'success',
                    life: 3000
                });
            }
        }catch(error:any){
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        }finally{
            setStateValues({isLoading:false});
        }
    }
    const promptDirectCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!pageDataValidation(validateStockCopy, { fromLocation: state.fromLocation, toLocation: state.toLocation }, toastRef)) {
            return;
        }
        promptUserAction({
            yesAction: onCopyStockComplete,
            event,
            displayText: `Stock items will be copied from ${state.fromDescription} to ${state.toDescription}. Action cannot be undone. Proceed?`
        });
    };
    const onCopyStockComplete = async () => {
        try {
            setStateValues({ isLoading: true });
            const stockCopyData = { fromLocation: state.fromLocation, toLocation: state.toLocation };
            const stockCopyResponse = isAppOnline(state.userSettings!) ? await generalPurpose.copyStockToLocationsOnline(stockCopyData) : await generalPurpose.copyStockToLocationOffline(stockCopyData);
            if (stockCopyResponse === 1) {
                queryClient.invalidateQueries(['itemsList']);
                queryClient.invalidateQueries(['storeItemsList']);
                queryClient.invalidateQueries(['itemsPageData']);
                await storeItemsListQuery(queryClient, personData!);
                await itemsListQuery(queryClient, personData!);
                await categoriesRelatedCatSubCatQuery(queryClient, personData!);
                displayMessage({
                    header: 'Copy Success',
                    message: 'Items were successfully copied to the receiving location select.',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 3000
                });
            } else {
                displayMessage({
                    header: 'Copy Response',
                    message: await copyErrorMsg(stockCopyResponse),
                    infoType: 'info',
                    toastComponent: toastRef,
                    life: 3000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const copyErrorMsg = async (responseNumber: number) => {
        switch (responseNumber) {
            case 0:
                return 'An error occurred and operation was aborted. Try again!';
            case 2:
                return 'From Location cannot be the same as the location copying into.';
            case 3:
                await queryClient.invalidateQueries(['itemsPageData']); //todo: temporary do this
                await queryClient.invalidateQueries(['currentLoggedOutlet']);
                await queryClient.refetchQueries(['itemsPageData']);
                await queryClient.refetchQueries(['currentLoggedOutlet']);

                return 'Some Items already exists in the location copying to. It is recommend to use item upload option.';
            case 4:
                return 'The location where items are being copied from contains no item.';
            default:
                return 'No activity was performed!';
        }
    };
    const playWithSocket = async () => {
        console.log(socket);
        // socket?.emit('hello',{data:'received this message for now'})
        // socket?.emit('something',{username:personData?.username,room:personData?.username});
        const shopsState = await generalPurpose.checkShopsState();
        console.log(shopsState);
    };
    const onMovableStockDialogueShown = async () => {
        try {
            setStateValues({ isLoading: true });
            const itemsList = await settingsArena.getOutletPaginatedItems(state.fromLocation);
            setStateValues({ movableStock: itemsList });
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const addItemToUpdates = (e: React.FocusEvent<HTMLInputElement>) => {
        try {
            const itemId = parseInt((e.target as HTMLElement).getAttribute('data-id')!);
            const itemInList = state.movableStock.find((item: IOutLetItem) => item.itemId === itemId);
            if (!_.some(state.updatableItemsList, { itemId: itemId })) {
                //check if item does not already exist then add
                if (parseFloat(e.target.value) > 0) {
                    setStateValues({
                        updatableItemsList: [
                            ...state.updatableItemsList,
                            { ...itemInList, stockQuantity: parseFloat(e.target.value), outletId: state.toLocation, storeId: personData?.storeId, expiryDate: formatDate(new Date(itemInList?.expiryDate! as Date)) }
                        ]
                    });
                }
            }else{
                setStateValues({updatableItemsList:state.updatableItemsList.map((item:IOutLetItem)=>{
                    return itemId===item.itemId?{...item,stockQuantity:parseFloat(e.target.value),customQty:e.target.value}:item
                })})
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        }
    };
    const onInputQuantityChange = (e: InputNumberChangeEvent) => {
        const itemId = parseInt((e.originalEvent.target as HTMLElement).getAttribute('data-id')!);
        setStateValues({
            movableStock: state.movableStock.map((stock) => {
                return itemId === stock.itemId! ? { ...stock, customQty: e.value } : stock;
            })
        });
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} toastPosition="top-right" />
            <div className="card">
                <div className="p-fluid lg:pl-5">
                    <div className="grid">
                        {(personData!.userRole! as number) !== 1 && (
                            <div className="col-4">
                                <div className="line-height-3 text-600">Reset App</div>
                                <Button
                                    onClick={resetAppData}
                                    tooltip="Clears local cache data. Forces app
                            to refect data from the remote server next time a request is made for such items."
                                >
                                    Reset App
                                </Button>
                            </div>
                        )}
                        <div className="col-4 pl-3">
                            <div className="line-height-3 text-600">Export Local Database</div>
                            <div className="justify-content-center">
                                <Button onClick={promptDBDownload}>Export Local DB As Backup</Button>
                            </div>
                        </div>
                        <div className="col-4 pl-3">
                            <div className="line-height-3 text-600">Import Local Database</div>
                            <div className="w-full">
                                <FileUpload mode="basic" name="demo[]" url="/api/upload" accept=".json" customUpload uploadHandler={importDexieDB} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="p-fluid lg:pl-5">
                    <div className="grid">
                        {personData?.userRole !== 1 && (
                            <div className="col-4 pl-3">
                                <Button onClick={checkDBPersistence} tooltip="Allow Local Index DB to persisted even if the browser automatically clears data to free space">
                                    Check DB Persistence
                                </Button>
                            </div>
                        )}
                        {personData?.userRole !== 1 && (
                            <div className="col-4 pl-3">
                                <Button className="p-button-danger" onClick={promptDBClearing}>
                                    Delete Local Index DB
                                </Button>
                            </div>
                        )}
                        <div className="col-4 pl-3">
                            <Button className="p-button-success" onClick={() => setStateValues({ showDialog: true })}>
                                Sync App State
                            </Button>
                        </div>
                        <div className="col-4 pl-3">
                            <Button className="p-button-success" onClick={() => navigate('/settings')}>
                                Settings
                            </Button>
                        </div>
                        {personData?.userRole == 1 && (
                            <div className="col-4 pl-3">
                                <Button className="p-button-primary" onClick={() => navigate('/sales')}>
                                    Go Back
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {personData?.userRole !== 1 && (
                <div className="card">
                    <div className="p-fluid lg:pl-5">
                        <div className="grid">
                            <div className="col-4 pl-3">
                                <Button onClick={() => setStateValues({ showStockMoveDialog: true })} tooltip="Move Stock From One Location to another">
                                    Initiate Stock Move
                                </Button>
                            </div>
                            <div className="col-4 pl-3">
                                <Button onClick={() => setStateValues({ showSubscriptionPayment: true })}>Make Subscription Payment</Button>
                            </div>
                            <div className="col-4 pl-3">
                                <Button onClick={playWithSocket} hidden={true}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Dialog closeOnEscape={false} onHide={() => setStateValues({ showDialog: false })} visible={state.showDialog} className="w-4" header={`Sync Local And Remote Items`} position={'top'}>
                <Card>
                    {state.syncInProgress && (
                        <div>
                            <ProgressBar value={state.progressValue} className="h-2rem" />
                        </div>
                    )}

                    <div className="flex justify-content-between pt-3">
                        <div className="">
                            <button aria-label="Clear All" className="p-button p-component p-button-outlined p-button-secondary w-full h-4rem ml-2" onClick={promptItemsToRemoteSync}>
                                <span className="p-button-label p-c">Take Local Data Online</span>
                                <span role="presentation" className="p-ink"></span>
                            </button>
                        </div>
                        <div className="">
                            <button aria-label="Clear All" className="p-button p-component p-button-outlined p-button-success w-full h-4rem ml-2" onClick={promptItemsToLocalSync}>
                                <span className="p-button-label p-c">Bring Online Data to Local</span>
                                <span role="presentation" className="p-ink"></span>
                            </button>
                        </div>
                    </div>
                </Card>
            </Dialog>
            <Dialog onHide={() => setStateValues({ showStockMoveDialog: false })} visible={state.showStockMoveDialog} position="top" className="w-5" header="Copy Stock To Outlets">
                <div className="card">
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect selectableOptions={state.availableOutlets} selectedOption={state.fromLocation} onSelectChange={onFromLocationChange} elementId="fromLocations" defaultValue="From Outlets" showLabel={true} />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect selectableOptions={state.availableOutlets} selectedOption={state.toLocation} onSelectChange={onToLocationChange} elementId="toLocations" defaultValue="To Outlets" showLabel={true} />
                        </div>
                    </div>
                    <Button onClick={() => setStateValues({ showMovableStockDialogue: true })}>Initiate Process</Button>
                    <Button onClick={promptDirectCopy} className="hidden">
                        Copy Stock {state.fromDescription !== '' ? `from ${state.fromDescription}` : ''} {state.toDescription !== '' ? `to ${state.toDescription}` : ''}
                    </Button>
                </div>
            </Dialog>
            {state.showSubscriptionPayment && (
                <Dialog header="Make Subscription Payment" onHide={() => setStateValues({ showSubscriptionPayment: false })} visible={state.showSubscriptionPayment} className="h-24rem" position="top">
                    <SubscriptionPayment />
                </Dialog>
            )}
            <Dialog
                visible={state.showMovableStockDialogue}
                onHide={() => setStateValues({ showMovableStockDialogue: false })}
                onShow={onMovableStockDialogueShown}
                header={
                    <div>
                        {' '}
                        <Button onClick={promptStockMove}>
                            Copy Stock {state.fromDescription !== '' ? `from ${state.fromDescription}` : ''} {state.toDescription !== '' ? `to ${state.toDescription}` : ''}
                        </Button>
                    </div>
                }
                maximized
            >
                <div className='card'>
                <SimpleTableWithMenu
                    tableKey={'itemId'}
                    columnsDef={[
                        { field: 'itemName', header: 'Item' },
                        {
                            body: (rowData: IOutLetItem & { customQty: number }) => (
                                <div>
                                    <InputNumber onBlur={addItemToUpdates} data-id={rowData.itemId?.toString()} value={rowData.customQty ? rowData.customQty : 0} onFocus={onInputControlFocus} onChange={onInputQuantityChange} />
                                </div>
                            ),
                            header: 'Quantity'
                        }
                    ]}
                    tableData={state.movableStock}
                    menuModel={[]}
                    tableTitle="Movable Items List"
                    searchValues={['itemName']}
                    searchFieldPlaceHolder="Search Item Name"
                    showTableLines={true}
                    lastTableUpdate={new Date().getTime()}
                    appColorScheme={state.userSettings?.colorScheme}
                    pageRows={[5, 10, 50, 100, 500, 1000]}
                    numberOfRows={10}
                />
                </div>
            </Dialog>
        </>
    );
};
export default AdminDefiners;
