import { TPerson } from '../types/TPerson';
import fetchAction from '../helpers/axiosConfig';
import { AxiosResponse } from 'axios';
import { formatDate, getBaseURL, sliceObject } from '../helpers/utils';
import { IOneForAll } from '../interfaces/IOneForAll';
import { TAppUser, TUserSettings } from '../types/UtilTypes';
import { localDB } from '../offline/db';
import Queue from './Queue';
import bcrypt from 'bcryptjs';

const queuedTables = new Queue();

export class Person implements IOneForAll<TPerson>, TPerson {
    accountStatus!: string;
    username!: string;
    dateOfBirth!: string | Date | Date[];
    emailAddress!: string;
    fullName!: string;
    gender!: number;
    password!: string;
    phoneNumber!: string;
    preferredLanguage!: string;
    userRole!: string | number;
    profilePicture!: string;
    storeId: number = 0;
    outletId: number = 0;
    personId!: number;
    jsonString: string = '';
    repeatedPassword?: string | undefined;
    addressId: number = 0;
    modifiedBy: number = 0;

    async createInstance(): Promise<AxiosResponse<{ status: number; operatedData: TPerson }>> {
        const userDetails = this.getInstance();
        return await fetchAction('post', `${getBaseURL()}/store_users/save_new_user`, { userDetails });
    }

    async updateInstance(): Promise<
        AxiosResponse<{
            status: number;
            operatedData: TPerson;
        }>
    > {
        const requestBody = this.getInstance();
        return await fetchAction('patch', `${getBaseURL()}/store_users/update_user`, { requestBody, personId: requestBody.personId });
    }

    async deleteInstance(deletePerson: number | string): Promise<AxiosResponse<{ status: number; operatedData: TPerson }>> {
        return await fetchAction('delete', `${getBaseURL()}/store_users/delete_user`, { requestBody: { deletePerson } });
    }

    async updateUserSettings(userSetting: TAppUser): Promise<AxiosResponse<{ status: number; operatedData: { userSettings: TUserSettings } }>> {
        return await fetchAction('patch', `${getBaseURL()}/store_users/update_user_settings`, { requestBody: userSetting, outletId: userSetting.outletId });
    }

    async saveOfflineUser(storeDescription: string, outletDescription: string, genderDesc: string, roleDescription: string, username: string) {
        try {
            const userData = this.getInstance();
            const checkUserCount = (await localDB.users.where('username').equals(userData.username!).count());
            if (checkUserCount > 0) {
                return 0;
            }

            const hashedPassword = await this.hashUserPassword(userData.password!);
            return await localDB.transaction('rw', localDB.users, localDB.queuedTables, async () => {
                const { username, emailAddress, password, fullName, personId, customFields, modifiedBy, 
                    dateOfBirth, gender, phoneNumber, userRole, outletId, storeId, addressId } = userData;
                const newUserId = await localDB.users.add({
                    outletId,
                    userRole: userRole!,
                    accountStatus: 'active',
                    storeId,
                    dateOfBirth: dateOfBirth as Date,
                    fullName: fullName!,
                    gender: gender!,
                    phoneNumber: phoneNumber!,
                    username: username!,
                    emailAddress: emailAddress!,
                    password: hashedPassword,
                    storeDescription,
                    outletDescription,
                    genderDesc,
                    roleDescription,
                    customFields,
                    modifiedBy,
                    modifierUsername: username,
                    syncStatus:false
                });
                const newUser = await localDB.users.get(newUserId);
                await queuedTables.enqueue<TPerson>(newUser!, 'users');
                return newUser;
            });
        } catch (error) {
            throw error;
        }
    }

    async updateOfflineUser(storeDescription: string, outletDescription: string, genderDesc: string, roleDescription: string) {
        try {
            const { username, emailAddress, password, fullName, personId, customFields, modifiedBy, dateOfBirth, gender, phoneNumber, userRole, outletId, storeId, addressId } = this.getInstance();
            const updatedResult = await localDB.users.update(personId!, {
                outletId,
                userRole: userRole!,
                accountStatus: 'active',
                storeId,
                dateOfBirth: dateOfBirth as Date,
                fullName: fullName!,
                gender: gender!,
                phoneNumber: phoneNumber!,
                // username: username!,
                emailAddress: emailAddress!,
                password: password!,
                storeDescription,
                outletDescription,
                genderDesc,
                roleDescription,
                customFields,
                modifiedBy,
                modifierUsername: username
            });
            if (updatedResult === 1) {
                const updatedPerson = await localDB.users.get(personId!);
                await queuedTables.updateEnqueue<TPerson>(updatedPerson!, 'users', 'personId', personId!);
                return updatedPerson;
            }
            return undefined;
        } catch (error) {
            throw error;
        }
    }

    async deleteOfflineUser(userId: number) {
        try {
            await localDB.users.delete(userId);
            return 1;
        } catch (error: any) {
            return 0;
        }
    }

    getInstance(): Partial<TPerson> {
        const selectedProps: Array<keyof TPerson> = ['username', 'emailAddress', 'storeId', 'outletId', 'addressId', 'personId', 'password', 'fullName', 'dateOfBirth', 'gender', 'phoneNumber', 'userRole', 'personId', 'jsonString', 'modifiedBy'];
        return sliceObject(selectedProps, { ...this });
    }

    makeInstance(person: TPerson) {
        const { username, emailAddress, password, fullName, personId, customFields, modifiedBy, dateOfBirth, gender, phoneNumber, userRole, outletId, storeId, addressId } = person;
        this.fullName = fullName;
        this.username = username;
        this.dateOfBirth = formatDate(new Date(dateOfBirth as Date)) as string;
        this.emailAddress = emailAddress;
        this.gender = gender;
        this.password = password!;
        this.phoneNumber = phoneNumber;
        this.userRole = userRole!;
        this.storeId = storeId!;
        this.outletId = outletId!;
        this.jsonString = JSON.stringify(customFields);
        this.addressId = addressId!;
        this.modifiedBy = modifiedBy!;
        this.personId = personId!;
    }

    async getLocalUsersAll() {
        try {
            return localDB.users.toArray();
        } catch (error: any) {
            return [];
        }
    }

    async hashUserPassword(password: string) {
        const saltRounds = 10;
        return await bcrypt.hash(password, saltRounds);
    }

    async checkPassword(password: string, databasePassword: string) {
        return await bcrypt.compare(password, databasePassword);
    }

    async requestResetPin(phoneNumber:string): Promise<AxiosResponse<{uniqueCode:string,status:number} >> {
        return await fetchAction('post', `${getBaseURL()}/users/receive_pin_code`, { phoneNumber });
    }
    async resetPassword(password:string,personId:number): Promise<AxiosResponse<{ status: number; operatedData: TPerson} >> {
        try{
            return await fetchAction('patch', `${getBaseURL()}/users/update_user_password`, { userDetails:{password,personId} });
        }catch(error){
            throw error;
        }
    }

    async getUseronlineLocations(userId:string):Promise<{status:number,userLocations:string | number[]}>{
        try{
            const responseData= await fetchAction<any>('post', `${getBaseURL()}/users/get_user_locations`, {userId});
 
            return {status:1,userLocations:responseData.data[0].userLocations}
        }catch(error){
            throw error;
        }
    }
    async getUseronlineLocationsLocal(userId:number):Promise<{userLocations:string | number[]}>{
        try{
            return await localDB.transaction('rw',localDB.users,async()=>{
                const currerentUser=await localDB.users.get(userId);
                return {status:1,userLocations:currerentUser?.userLocations!==undefined?currerentUser?.userLocations!:[]}
            });
        }catch(error){
            throw error;
        }
    }
    async updateUserLocations(userId:number,userLocations:number[]):Promise<{status:number,operatedData:string}>{
        try{
            const updateResponse= await fetchAction<any>('post', `${getBaseURL()}/users/update_user_locations`, {userId,userLocations});
            return {status:1,operatedData:updateResponse.data}
        }catch(error){
            throw error;
        }
    }

    async updateUserLocationsLocal(userId:number,userLocations:number[]){

        try{
            return localDB.transaction('rw',localDB.users,async()=>{
                await localDB.users.update(userId,{userLocations:userLocations});
                return {status:1,operatedData:'success'}
            });
        }catch(error){
            throw error;
        }
    }
}
