import { TCustomer, TPerson, TSupplier } from '../types/TPerson';
import { localDB } from '../offline/db';
import { TStorePage } from '../helpers/customTypesUtils';
import bcrypt from 'bcryptjs';
import {
    IGiftCards,
    IOutlet,
    IOutLetItem,
    IPromotionalDiscounts,
    ITaxes,
    ITermsConditions,
    TAdjustmentItem,
    TAdjustmentTransaction,
    TAppUser,
    TCategory,
    TCustomerPayment,
    TCustomerTypesAndCategories,
    TExpenditure,
    TExpenditureCategory,
    TItemBrand,
    TItemPageData,
    TItemSubcategory,
    TPriceChangeItem,
    TPriceChangeTransaction,
    TProformaInvoice,
    TPurchaseTransactionItem,
    TrackTable,
    TRelatedItemCategory,
    TSaleTransactionItem,
    TStoreSubscription,
    TSupplierPayment,
    TTransactionItem,
    TTransferTransaction,
    TUserSettings
} from '../types/UtilTypes';
import fetchAction from '../helpers/axiosConfig';
import { formatDate, getBaseURL, getDateAndTime, getExplainedItemsPaginated, getNewSyncItems, getOnlineDataPaginated, getParsedId } from '../helpers/utils';
import { RegistrationPageInit } from '../types/TStore';
import Queue from './Queue';
import { AxiosResponse } from 'axios';
const _ =require('lodash');
const queuedTables = new Queue();


class SettingsArena {

    async syncCustomersList(customersList: TCustomer[],storeId:number) {
        try {
            return localDB.transaction('rw', localDB.customers, async () => {
                const localDBCustomers=(await localDB.customers.where({storeId:getParsedId(storeId)}).toArray());
                const getCashCustomer=await localDB.customers.get(1);//this is a default customer for all stores and must be added here.
                const incomingNewCustomers=getNewSyncItems<TCustomer>([...localDBCustomers,getCashCustomer!],customersList,'customerId');
                const customers: TCustomer[] = incomingNewCustomers.map((customer: TCustomer) => {
                    const {
                        fullName,
                        customerCompany,
                        customerType,
                        gender,
                        phoneNumber,
                        customerCategory,
                        emailAddress,
                        username,
                        creditLimit,
                        customFields,
                        addressLine,
                        addressCity,
                        addressState,
                        addressCountry,
                        postalCode,
                        categoryDescription,
                        customerTypeDescription,
                        outletId,
                        storeId,
                        customerId
                    } = customer;
                    return {
                        customerId,
                        fullName,
                        phoneNumber,
                        emailAddress,
                        username,
                        customerCompany,
                        customerType,
                        gender,
                        customerCategory,
                        creditLimit,
                        customFields,
                        addressLine,
                        addressCity,
                        addressState,
                        addressCountry,
                        postalCode,
                        categoryDescription,
                        customerTypeDescription,
                        outletId,
                        storeId
                    };
                });
                const savedCustomersKeys = await localDB.customers.bulkAdd(customers, { allKeys: true });
                return savedCustomersKeys.length === incomingNewCustomers.length;
            });
        } catch (error) {
            throw error;
        }
    }

    async synSuppliersList(suppliersList: TSupplier[],storeId:number) {
        try {
            const localSuppliers=await localDB.suppliers.where({storeId:getParsedId(storeId)}).toArray();
            const newSuppliers=getNewSyncItems<TSupplier>(localSuppliers,suppliersList,'supplierId');
            
            return localDB.transaction('rw', localDB.suppliers, async () => {
                const suppliers: TSupplier[] = newSuppliers.map((supplier) => {
                    const { supplierId, fullName, supplierCompany, emailAddress, phoneNumber, username, outletId, storeId, gender, postalCode, bankDetails, addressCity, addressLine, addressState, addressCountry, customFields }: TSupplier = supplier;
                    return {
                        supplierId,
                        fullName,
                        supplierCompany,
                        emailAddress,
                        phoneNumber,
                        username,
                        gender,
                        postalCode,
                        bankDetails,
                        addressCity,
                        addressLine,
                        addressState,
                        addressCountry,
                        customFields,
                        outletId,
                        storeId
                    };
                });
                const saveSuppliersKeys = await localDB.suppliers.bulkAdd(suppliers, { allKeys: true });
                return saveSuppliersKeys.length === newSuppliers.length;
            });
        } catch (error: any) {
            throw error;
        }
    }

    async syncUsersList(usersList: TPerson[],storeId:number) {
        try {
            const localDBUsers=await localDB.users.where({storeId:getParsedId(storeId)}).toArray();
            const newUsers=getNewSyncItems<TPerson>(localDBUsers,usersList,'personId');

            return localDB.transaction('rw', localDB.users, async () => {
                const users: TPerson[] = newUsers.map((user) => {
                    const { personId, fullName, gender, phoneNumber, username, dateOfBirth, userRole, emailAddress, outletId, storeId, customFields, outletDescription, storeDescription, roleDescription, genderDesc, accountStatus, password,userLocations } = user;
                    return {
                        personId,
                        fullName,
                        gender,
                        phoneNumber,
                        username,
                        dateOfBirth: formatDate(new Date(dateOfBirth as Date)),
                        userRole,
                        emailAddress,
                        outletId,
                        storeId,
                        customFields,
                        roleDescription,
                        genderDesc,
                        accountStatus,
                        storeDescription,
                        outletDescription,
                        password,userLocations
                    };
                });
                const savedUsersKeys = await localDB.users.bulkAdd(users, { allKeys: true });
                return savedUsersKeys.length === newUsers.length;
            });
        } catch (error) {
            throw error;
        }
    }

    async syncStoresList(storesList: TStorePage[]) {

        try {
            const localStores=await localDB.appStores.toArray();
            const newStores=getNewSyncItems<TStorePage>(localStores,storesList,'storeId');
            return localDB.transaction('rw', localDB.appStores, async () => {
                const stores: any = newStores.map((store) => {
                    const { storeId, addressCity, addressCountry, addressLine, addressState, emailAddress, phoneNumber, storeDescription, storeOwnerId, storeType, postalCode, customFields, storeTypeDescription, storeLogo } = store;
                    return {
                        storeId,
                        addressCity,
                        addressCountry,
                        addressLine,
                        addressState,
                        emailAddress,
                        phoneNumber,
                        storeDescription,
                        storeOwnerId,
                        storeType,
                        postalCode,
                        customFields,
                        storeTypeDescription,
                        storeLogo
                    };
                });
                const savedStoreIds = await localDB.appStores.bulkAdd(stores, { allKeys: true });
                return savedStoreIds.length === newStores.length;
            });
        } catch (error) {
            throw error;
        }
    }

    async syncOutletsList(outletsList: IOutlet[],storeId:number) {
        try {
            const localOutlets=await localDB.outlets.where({parentStore:getParsedId(storeId)}).toArray();
            const newOutlets=getNewSyncItems<IOutlet>(localOutlets,outletsList,'outletId');
            return localDB.transaction('rw', localDB.outlets, async () => {
                const outlets: IOutlet[] = newOutlets.map((outlet) => {
                    const {
                        outletId,
                        outletCategoryDescription,
                        outletTypeDescription,
                        outletDescription,
                        outletEmail,
                        outletPhoneNumber,
                        outletAddressLine,
                        outletAddressCity,
                        outletAddressState,
                        outletAddressCountry,
                        categoryId,
                        outletType,
                        parentStore,
                        outletAddressId,
                        customFields,
                        storeDescription,
                        outletSettings
                    } = outlet;
                    return {
                        outletId,
                        outletCategoryDescription,
                        outletTypeDescription,
                        outletDescription,
                        outletEmail,
                        outletPhoneNumber,
                        outletAddressLine,
                        outletAddressCity,
                        outletAddressState,
                        outletAddressCountry,
                        categoryId,
                        outletType,
                        parentStore,
                        outletAddressId,
                        customFields,
                        storeDescription,
                        outletSettings
                    };
                });
                const savedOutletIds = await localDB.outlets.bulkAdd(outlets, { allKeys: true });
                return savedOutletIds.length === newOutlets.length;
            });
        } catch (error: any) {
            throw error;
        }
    }
    async getTotalTotalItems(storeIdOrOutletId:number,itemsCountListLink:string):Promise<number>{
        const response= await fetchAction<number>('post', `${getBaseURL()}/${itemsCountListLink}`, {storeIdOrOutletId});
        return response.data;
    }
    async getExplainedTotalItems(storeIdOrOutletId:number,itemsCountListLink:string,requestOption:number):Promise<number>{
        const response= await fetchAction<number>('post', `${getBaseURL()}/${itemsCountListLink}`, {storeIdOrOutletId,requestOption});
        return response.data;
    }
    async getOnlinePaginatedItems<T>(storeIdOrOutletId:number,itemsLink:string,lastSyncDate:string,itemsCountLink:string):Promise<T[]>{
        let totalItemsList:T[]=[];
        const totalItemsInStore=await this.getTotalTotalItems(storeIdOrOutletId!,itemsCountLink);
        const totalPages=Math.ceil(totalItemsInStore/100);
        let pagesTracker=1;
        while (pagesTracker<=totalPages){
            const itemsList = await getOnlineDataPaginated<T[]>(itemsLink,pagesTracker,lastSyncDate,storeIdOrOutletId!);
            totalItemsList=[...totalItemsList,...itemsList]
            pagesTracker++;
        }
        return totalItemsList;
    }
    async getExplainedOnlinePaginatedItems<T>(storeIdOrOutletId:number,itemsLink:string,lastSyncDate:string,itemsCountLink:string,requestOption:number):Promise<T[]>{
        let totalItemsList:T[]=[];
        const totalItemsInStore=await this.getExplainedTotalItems(storeIdOrOutletId!,itemsCountLink,requestOption);
        const totalPages=Math.ceil(totalItemsInStore/100);
        console.log(totalPages)
        let pagesTracker=1;
        while (pagesTracker<=totalPages){
            const itemsList = await getExplainedItemsPaginated<T[]>(itemsLink,pagesTracker,lastSyncDate,storeIdOrOutletId!,requestOption);
            totalItemsList=[...totalItemsList,...itemsList]
            pagesTracker++;
        }
        return totalItemsList;
    }
    async syncOutletItemsList(itemsList: IOutLetItem[],storeId:number) {
        try {
            const localItems=await localDB.outletItems.where({storeId:getParsedId(storeId)}).toArray();
            const newItems=getNewSyncItems<IOutLetItem>(localItems,itemsList,'itemId');
            const items: IOutLetItem[] = newItems.map((item) => {
                const {
                    itemDetailsId,
                    itemId,
                    itemName,
                    itemDescription,
                    itemCategoryId,
                    itemRelatedCategoryId,
                    itemSubcategoryId,
                    brandId,
                    itemWeight,
                    itemDimension,
                    color,
                    stockQuantity,
                    minimumQuantity,
                    maximumQuantity,
                    size,
                    SKU,
                    outletId,
                    itemPrice,
                    itemCost,
                    categoryDescription,
                    relatedCategoryDescription,
                    subCategoryDescription,
                    storeId,
                    shortDescription,
                    modifiedBy,
                    expiryDate,
                    sellStockValue,
                    costStockValue,
                    barcode
                } = item;
                return {
                    itemId,
                    itemDetailsId,
                    itemName,
                    itemDescription,
                    itemCategoryId,
                    itemRelatedCategoryId,
                    itemSubcategoryId,
                    categoryDescription,
                    relatedCategoryDescription,
                    subCategoryDescription,
                    storeId,
                    shortDescription,
                    brandId,
                    itemWeight,
                    itemDimension,
                    color,
                    stockQuantity,
                    minimumQuantity,
                    maximumQuantity,
                    size,
                    SKU,
                    outletId,
                    itemPrice,
                    itemCost,
                    modifiedBy,
                    expiryDate: formatDate(new Date(expiryDate as Date)),
                    sellStockValue,
                    costStockValue,
                    barcode
                };
            });
            const savedItemsIDs = await localDB.outletItems.bulkAdd(items, { allKeys: true });
            return savedItemsIDs.length === newItems.length;
        } catch (error: any) {
            throw error;
        }
    }

    /**
     * This function uses bulkPut because we are not checking for new additions which means 
     * we saving the same old data with the option for new datas that might have been last added
     * @param itemPageMetaData 
     * @returns {Promise<boolean>}
     */
    async synItemPageMetaData(itemPageMetaData: TItemPageData): Promise<boolean> {
        try {
            return localDB.transaction('rw', localDB.itemCategories, localDB.itemRelatedCategories, localDB.itemSubCategories, localDB.itemBrands, async () => {
                await localDB.itemCategories.bulkPut(itemPageMetaData.categories);
                await localDB.itemRelatedCategories.bulkPut(itemPageMetaData.relatedCategories);
                await localDB.itemSubCategories.bulkPut(itemPageMetaData.subCategories);
                await localDB.itemBrands.bulkPut(itemPageMetaData.brands);
                return true;
            });
        } catch (error: any) {
            throw error;
        }
    }

    /**
     * This function uses bulkPut because we are not checking for new additions which means 
     * we saving the same old data with the option for new datas that might have been last added
     * @param storeCategoriesAndTypes 
     * @returns {Promise<boolean>}
     */
    async syncStoreCategoriesAndStoreTypes(storeCategoriesAndTypes: RegistrationPageInit): Promise<boolean> {
        try {
            return localDB.transaction('rw', localDB.storeOutletCategories, localDB.storeTypes, async () => {
                await localDB.storeTypes.bulkPut(storeCategoriesAndTypes.storeTypes);
                await localDB.storeOutletCategories.bulkPut(storeCategoriesAndTypes.outletCategories);
                return true;
            });
        } catch (error: any) {
            throw error;
        }
    }

    /**
     * This function uses bulkPut because we are not checking for new additions which means 
     * we saving the same old data with the option for new datas that might have been last added
     * @param customerTypesAndCategories 
     * @returns {Promise<boolean>}
     */
    async syncCustomerTypesAndCategories(customerTypesAndCategories: TCustomerTypesAndCategories): Promise<boolean> {
        try {
            return localDB.transaction('rw', localDB.customerTypes, localDB.customerCategories, async () => {
                await localDB.customerTypes.bulkPut(customerTypesAndCategories.customerTypes);
                await localDB.customerCategories.bulkPut(customerTypesAndCategories.customerCategories);
                return true;
            });
        } catch (error: any) {
            throw error;
        }
    }

    async getOnlineCategoriesRelatedAndSubcategories(outletId: number) {
        try {
            const itemPageData = await fetchAction<[TCategory[], TRelatedItemCategory[], TItemSubcategory[], TItemBrand[]]>(
                'get',
                `${getBaseURL()}/items/init_item_page?a=${outletId}&b=${outletId}&c=${outletId}&d=${outletId}`,
                {}
            );
            return { categories: itemPageData.data[0], relatedCategories: itemPageData.data[1], subCategories: itemPageData.data[2], brands: itemPageData.data[3] };
        } catch (error: any) {
            throw error;
        }
    }

    async syncDiscountsList(discountsList: IPromotionalDiscounts[],storeId:number) {
        try {
            const localDiscounts=await localDB.promotionalDiscounts.where({storeId:getParsedId(storeId)}).toArray();
            const newDiscounts=getNewSyncItems<IPromotionalDiscounts>(localDiscounts,discountsList,'discountId');
            const discounts: IPromotionalDiscounts[] = newDiscounts.map((discount) => {
                const { discountId, storeId, modifiedBy, outletId, startDate, endDate, discountDescription, discountType, discountState, discountAmount, applicableProducts } = discount;
                return {
                    discountId,
                    storeId,
                    outletId,
                    modifiedBy,
                    startDate: formatDate(new Date(startDate as Date)),
                    endDate: formatDate(new Date(endDate as Date)),
                    discountDescription,
                    discountType,
                    discountState,
                    discountAmount,
                    applicableProducts
                };
            });
            const savedDiscountIds = await localDB.promotionalDiscounts.bulkAdd(discounts, { allKeys: true });
            return savedDiscountIds.length === newDiscounts.length;
        } catch (error) {
            throw error;
        }
    }

    async syncOnlineTaxes(taxesList: ITaxes[],storeId:number) {
        try {
            const localTaxes=await localDB.taxes.where({storeId:getParsedId(storeId)}).toArray();
            const newTaxes=getNewSyncItems<ITaxes>(localTaxes,taxesList,'taxId');
            const taxes: ITaxes[] = newTaxes.map((tax) => {
                const { taxId, storeId, modifiedBy, outletId, taxDescription, taxRate, taxCountry, taxCountryState, taxActiveState, customFields } = tax;
                return {
                    taxId,
                    taxDescription,
                    taxRate,
                    taxCountry,
                    taxCountryState,
                    taxActiveState,
                    customFields,
                    outletId,
                    storeId,
                    modifiedBy
                };
            });
            const savedTaxesId = await localDB.taxes.bulkAdd(taxes, { allKeys: true });
            return savedTaxesId.length === newTaxes.length;
        } catch (error: any) {
            throw error;
        }
    }

    async syncOnlineGiftCards(cardsList: IGiftCards[],storeId:number) {
        try {
            const localGiftCards=await localDB.giftCards.where({storeId:getParsedId(storeId)}).toArray();
            const newGiftCards=getNewSyncItems(localGiftCards,cardsList,'giftCardId');
            const giftCards: IGiftCards[] = newGiftCards.map((card) => {
                const { giftCardId, outletId, storeId, modifiedBy, cardNumber, cardBalance, cardPin, customerId, expirationDate, isActive } = card;
                return {
                    giftCardId,
                    cardNumber,
                    cardBalance,
                    cardPin,
                    customerId,
                    expirationDate: formatDate(new Date(expirationDate as Date)),
                    isActive,
                    outletId,
                    modifiedBy,
                    storeId
                };
            });
            const savedGiftCardsIDs = await localDB.giftCards.bulkAdd(giftCards, { allKeys: true });
            return savedGiftCardsIDs.length === newGiftCards.length;
        } catch (error) {
            throw error;
        }
    }

    async syncTermsConditions(termsList: ITermsConditions[],storeId:number) {
        try {
            const localTermConditions=await localDB.termsConditions.where({storeId:getParsedId(storeId)}).toArray();
            const newTerms=getNewSyncItems<ITermsConditions>(localTermConditions,termsList,'termsConditionsId');
            const terms: ITermsConditions[] = newTerms.map((term) => {
                const { termsConditionsId, storeDescription, modifiedBy, outletId, storeId, termConditionsDescription, termConditionsDetails, customFields } = term;
                return {
                    termsConditionsId,
                    storeId,
                    storeDescription,
                    termConditionsDescription,
                    termConditionsDetails,
                    customFields,
                    outletId,
                    modifiedBy
                };
            });
            const savedTermsIDs = await localDB.termsConditions.bulkAdd(terms, { allKeys: true });
            return savedTermsIDs.length === newTerms.length;
        } catch (error) {
            throw error;
        }
    }

    async syncExpenditures(expendituresList: TExpenditure[],storeId:number) {
        try {
            const localExpenditures=await localDB.expenditures.where({storeId:getParsedId(storeId)}).toArray();
            const newExpenditures=getNewSyncItems<TExpenditure>(localExpenditures,expendituresList,'expenditureId');
            const expenses: TExpenditure[] = newExpenditures.map((expense) => {
                const { expenditureId, expenditureDate, expenditureCategory, expenditureAmount, outletId, storeId, description, paymentMethod, modifiedBy, outletDescription, storeDescription, categoryDescription }: TExpenditure = expense;
                return {
                    expenditureId,
                    expenditureDate: formatDate(new Date(expenditureDate as Date)),
                    expenditureCategory,
                    expenditureAmount,
                    outletId,
                    storeId,
                    description,
                    modifiedBy,
                    outletDescription,
                    storeDescription,
                    categoryDescription,
                    paymentMethod
                };
            });
            const savedExpensesIds = await localDB.expenditures.bulkPut(expenses, { allKeys: true });
            return savedExpensesIds.length === newExpenditures.length;
        } catch (error: any) {
            throw error;
        }
    }

    async syncSalesList(salesList: TSaleTransactionItem[],outletId:number) {
        try {
            const localSales=await localDB.salesTransactions.where({outletId:getParsedId(outletId)}).toArray();
            const newSales=getNewSyncItems<TSaleTransactionItem>(localSales,salesList,'invoiceNumber');
            const sales: TSaleTransactionItem[] = newSales.map((sale) => {
                const {
                    transactionItems,
                    transactionCost,
                    discountAmount,
                    customerId,
                    customerName,
                    refundAmount,
                    priceMarkup,
                    saleMode,
                    outletId,
                    invoiceNumber,
                    transactionType,
                    transactionNotes,
                    transactionDate,
                    storeId,
                    modifiedBy,
                    storeDescription,
                    transactionStatus,
                    outletDescription,
                    modifier,
                    transactionPayments,
                    transactionPaid
                } = sale;
                return {
                    transactionItems,
                    transactionCost,
                    discountAmount,
                    amountTendered: transactionPaid,
                    customerId,
                    customerName,
                    refundAmount,
                    priceMarkup,
                    saleMode,
                    outletId,
                    invoiceNumber,
                    amountPayable: transactionCost,
                    transactionType,
                    transactionNotes,
                    transactionDate: formatDate(new Date(transactionDate as Date)),
                    storeId,
                    modifiedBy,
                    storeDescription,
                    transactionStatus,
                    outletDescription,
                    modifier,
                    transactionPayments,
                    transactionPaid
                };
            });
            const savedSalesIDs = await localDB.salesTransactions.bulkAdd(sales, { allKeys: true });
            return savedSalesIDs.length === newSales.length;
        } catch (error) {
            throw error;
        }
    }

    async syncProformaInvoicesList(proformaInvoices: TProformaInvoice[],outletId:number) {
        try {
            const localProformas=await localDB.proformaInvoice.where({outletId:getParsedId(outletId)}).toArray();
            const newProforma=getNewSyncItems<TProformaInvoice>(localProformas,proformaInvoices,'invoiceNumber');
            const proformas: TProformaInvoice[] = newProforma.map((proforma) => {
                const { customerId, transactionDate, discountAmount, transactionItems, additionalNotes, markupFigure, outletId, 
                    customerName, transactionCost, invoiceNumber, priceMarkup } = proforma;
                return {
                    customerId,
                    transactionDate: formatDate(new Date(transactionDate as Date)),
                    discountAmount,
                    transactionItems,
                    additionalNotes,
                    markupFigure,
                    outletId,
                    customerName,
                    transactionCost,
                    invoiceNumber,
                    priceMarkup: markupFigure!
                };
            });
            const saveProformasIds = await localDB.proformaInvoice.bulkAdd(proformas, { allKeys: true });
            return saveProformasIds.length === newProforma.length;
        } catch (error) {
            throw error;
        }
    }
    async syncPriceChangeToLocal(priceChangeListData: TPriceChangeTransaction[],outletId:number) {
        try{
            const localPriceChanges=await localDB.priceChangeHistory.where({outletId:getParsedId(outletId)}).toArray();
            const newPriceChanges=getNewSyncItems<TPriceChangeTransaction>(localPriceChanges,priceChangeListData,'invoiceNumber');
            const priceChangeList=newPriceChanges.map((priceChangeItem)=>{
                const {invoiceNumber,outletId,storeId,priceChangeDate,priceChangeItems,modifiedBy,modifier}=priceChangeItem;
                //FIXME: BEFORE RETURNING FIND THEIR CORRESPONDING OUTLET ITEM AND UPDATE IT.
                return {
                    invoiceNumber,
                    outletId,
                    storeId,
                    priceChangeDate:formatDate(new Date(priceChangeDate as Date)),
                    priceChangeItems,
                    modifiedBy,
                    modifier
                }
            });
         
            const savedPriceHistoryIDs=await localDB.priceChangeHistory.bulkAdd(priceChangeList,{allKeys:true});

            newPriceChanges.forEach((priceChange)=>{

                const changeItems=typeof priceChange.priceChangeItems==='string'?JSON.parse(priceChange.priceChangeItems):priceChange.priceChangeItems;

                if(changeItems.length>0){
                    changeItems.forEach(async(item:TPriceChangeItem)=>{
                     
                        const dbItem=await localDB.outletItems.get(item.itemId);
                   
                        const changeHistory=dbItem!.changeHistory?[...dbItem!.changeHistory,{...item,priceChangedDate:priceChange.priceChangeDate}]:[{...item,priceChangeDate:priceChange.priceChangeDate}];
                        
                        const updatedDBItem={...dbItem,changeHistory,itemPrice:item.itemSellingPrice,itemCost:item.itemCostPrice};
                        await localDB.outletItems.put(updatedDBItem as IOutLetItem);
                    });
                }
            });
            return savedPriceHistoryIDs.length===newPriceChanges.length;
        }catch(error){
            throw error;
        }
    }

    async syncTransfersList(transfersList: TTransferTransaction[],outletId:number) {
        try{
            const localTransfers=await localDB.transfers.where({transferringOutletId:getParsedId(outletId),receivingOutletId:getParsedId(outletId)}).toArray();
            const newTransfers=getNewSyncItems<TTransferTransaction>(localTransfers,transfersList,'invoiceNumber');
            const transfers: TTransferTransaction[] = newTransfers.map((transfer) => {
                const { storeId, receivingOutletId, transferringOutletId, transferringOutlet, receivingOutlet, 
                    storeDescription, additionalNotes, transferItems, transferDate, invoiceNumber } = transfer;
                return {
                    storeId,
                    receivingOutletId,
                    transferringOutletId,
                    transferringOutlet,
                    receivingOutlet,
                    storeDescription,
                    additionalNotes,
                    transferItems,
                    transferDate: formatDate(new Date(transferDate as Date)),
                    modifiedBy: 0,
                    invoiceNumber
                };
            });
            const savedTransfers = await localDB.transfers.bulkAdd(transfers, { allKeys: true });
            return savedTransfers.length === newTransfers.length;
        }catch(error){
            throw error;
        }
    }
    async syncPurchasesList(purchasesList: TPurchaseTransactionItem[],outletId:number) {
        const localPurchases=await localDB.purchaseTransactions.where({outletId:getParsedId(outletId)}).toArray();
        const newPurchases=getNewSyncItems<TPurchaseTransactionItem>(localPurchases,purchasesList,'invoiceNumber');
        const purchases: TPurchaseTransactionItem[] = newPurchases.map((purchase) => {
            const {
                transactionItems,
                supplierId,
                discountAmount,
                transactionDate,
                outletId,
                modifiedBy,
                refundAmount,
                modifier,
                transactionPayments,
                transactionStatus,
                outletDescription,
                storeDescription,
                storeId,
                transactionNotes,
                transactionCost,
                transactionPaid,
                invoiceNumber,
                supplierName,
                transactionType
            } = purchase;
            return {
                transactionItems,
                supplierId,
                discountAmount,
                transactionDate: formatDate(new Date(transactionDate as Date)),
                outletId,
                modifiedBy,
                refundAmount,
                modifier,
                transactionPayments,
                transactionStatus,
                outletDescription,
                storeDescription,
                storeId,
                transactionNotes,
                transactionCost,
                transactionPaid,
                invoiceNumber,
                supplierName,
                transactionType
            };
        });
        const savedPurchasesIds = await localDB.purchaseTransactions.bulkAdd(purchases, { allKeys: true });
        return savedPurchasesIds.length === newPurchases.length;
    }

    async syncAdjustmentsList(adjustments: TAdjustmentTransaction[],outletId:number) {
        try{
            const localAdjustments=await localDB.adjustmentTransactions.where({outletId:getParsedId(outletId)}).toArray();
            const newAdjustments=getNewSyncItems<TAdjustmentTransaction>(localAdjustments,adjustments,'invoiceNumber')
            const adjustmentsList: TAdjustmentTransaction[] = newAdjustments.map((adjustment) => {
                const { adjustmentType, adjustmentItems, adjustmentDate, storeOutletId, modifiedBy, storeId, username, outletDescription, adjustmentNotes } = adjustment;
                return {
                    adjustmentType,
                    adjustmentItems,
                    adjustmentDate: formatDate(new Date(adjustmentDate as Date)),
                    storeOutletId,
                    modifiedBy,
                    storeId,
                    username,
                    outletDescription,
                    adjustmentNotes
                };
            });
            const savedAdjustmentIds = await localDB.adjustmentTransactions.bulkPut(adjustmentsList, { allKeys: true });
            return savedAdjustmentIds.length === newAdjustments.length;
        }catch(error){
            throw error;
        }
    }
    async syncCustomerPayments(payments: TCustomerPayment[],outletId:number) {
        try{
            const localPayments=await localDB.accountsPayments.where({outletId:getParsedId(outletId)}).toArray();
            const newPayments=getNewSyncItems<TCustomerPayment>(localPayments,payments,'paymentId');
            const paymentsList: TCustomerPayment[] = newPayments.map((payment) => {
                const { paymentId,fullName, customerCompany, paymentDate, amountTendered, paymentChange, paymentOption, 
                    customerId, storeDescription, outletDescription, storeId, outletId, modifiedBy, paymentNotes } = payment;
                return {
                    paymentId,
                    fullName,
                    customerCompany,
                    paymentDate: formatDate(new Date(paymentDate as Date)),
                    amountTendered,
                    modifiedBy,
                    storeId,
                    paymentChange,
                    outletDescription,
                    customerId,
                    storeDescription,
                    outletId,
                    paymentNotes,
                    paymentOption
                };
            });
            const savedPaymentsId = await localDB.accountsPayments.bulkAdd(paymentsList, { allKeys: true });
    
            return savedPaymentsId.length === newPayments.length;
        }catch(error){
            throw error;
        }
    }
    async syncSupplierPayments(payments: TSupplierPayment[],outletId:number) {
        try{
            const localSupplierPayments=await localDB.supplierPayments.where({outletId:getParsedId(outletId)}).toArray();
            const newPayments=getNewSyncItems<TSupplierPayment>(localSupplierPayments,payments,'supplierPaymentId');
   
            const paymentsList: TSupplierPayment[] = newPayments.map((payment) => {
                const { fullName, supplierCompany, supplierPaymentId, paymentDate, amountTendered, 
                    paymentChange, paymentOption, supplierId, storeDescription, outletDescription, 
                    storeId, outletId, modifiedBy, paymentNotes } = payment;
                return {
                    supplierPaymentId,
                    fullName,
                    supplierCompany,
                    paymentDate: formatDate(new Date(paymentDate as Date)),
                    amountTendered,
                    modifiedBy,
                    storeId,
                    paymentChange,
                    outletDescription,
                    supplierId,
                    storeDescription,
                    outletId,
                    paymentNotes,
                    paymentOption
                };
            });
            const savedPaymentsId = await localDB.supplierPayments.bulkAdd(paymentsList, { allKeys: true });
    
            return savedPaymentsId.length === newPayments.length;
        }catch(error){
            throw error;
        }
    }
    async getQueuedData() {
        try {
            return await queuedTables.getQueueTables();
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     * @param categories
     * @param outletId
     * @returns {Promise<TCategory[]>}
     */
    async syncItemCategories(categories: TCategory[], outletId: number): Promise<TCategory[]> {
        try {
            const newlyInsertCategories= await fetchAction<TCategory[]>('post', `${getBaseURL()}/items/sync_item_categories`, { categories, outletId });
            const localDBCategories=await localDB.itemCategories.toArray();
            const categoriesNewFace=localDBCategories.map((category)=>{
                const matchingCategoryFromRemote=newlyInsertCategories.data.find(remoteCategory=>remoteCategory.categoryDescription===category.categoryDescription);
                return matchingCategoryFromRemote?{...category,categoryId:matchingCategoryFromRemote.categoryId}:category;
            });
            return categoriesNewFace;
        } catch (error) {
            throw error;
        }
    }

    /** Function syncs offline related categories that has been queued to online.
     @returns {Promise<TItemSubcategory[]>} - Promise resolved with the newly pushed related categories item being returned to the calling function.
     * @param itemCategories - An array of item categories that has been returned from remote server with updated dependency props.
     * @param relatedCategories - An array of related categories.
     * @param outletId - The outlet that is initiating the action.
     */
    async syncItemRelatedCategories(itemCategories: TCategory[], relatedCategories: TRelatedItemCategory[], outletId: number): Promise<TRelatedItemCategory[]> {
        try {
            const shakeUpRelatedCategories = relatedCategories.map((relatedCategory) => {
                const parentCategory = itemCategories.find((category) => category.categoryDescription === relatedCategory.categoryDescription);
                return { ...relatedCategory, categoryId: parentCategory?.categoryId };
            });
            const newRelatedCategories= await fetchAction<TRelatedItemCategory[]>('post', `${getBaseURL()}/items/sync_item_related_categories`, { relatedCategories: shakeUpRelatedCategories, outletId });
            
            const localRelatedCategories=(await localDB.itemRelatedCategories.toArray()).filter(relatedCategory=>relatedCategory.outletId===outletId || relatedCategory.outletId===0);
          
            const combinedCategories=localRelatedCategories.map((rCategory)=>{
                const matchingCategory=newRelatedCategories.data.find((relatedCategory)=>relatedCategory.relatedCategoryDescription===rCategory.relatedCategoryDescription);
                return matchingCategory?{...rCategory,relatedCategoryId:matchingCategory.relatedCategoryId}:rCategory;
            });

            return combinedCategories;

        } catch (error) {
            throw error;
        }
    }

    /** Function syncs offline subcategories that has been queued to online.
     @returns {Promise<TItemSubcategory[]>} - Promise resolved with the newly pushed subcategories items being returned to the calling function.
     * @param subCategories - Locally queued subcategories that ready to be pushed online
     * @param relatedCategories - The freshly returned related categories from remote server whose attribute are updated
     * @param outletId
     */
    async syncItemSubCategories(subCategories: TItemSubcategory[], relatedCategories: TRelatedItemCategory[], outletId: number): Promise<TItemSubcategory[]> {
        try {
 
            const shakeupSubCategories: TItemSubcategory[] = subCategories.map((subCategory) => {
                const parentRelatedCategory = relatedCategories.find(
                    (relatedCategory) => relatedCategory.categoryDescription === subCategory.categoryDescription && relatedCategory.relatedCategoryDescription === subCategory.relatedCategoryDescription
                );
                return { ...subCategory, categoryId: parentRelatedCategory?.categoryId, relatedCategoryId: parentRelatedCategory?.relatedCategoryId };
            });
      
        
            const newSubCategories= await fetchAction<TItemSubcategory[]>('post', `${getBaseURL()}/items/sync_item_sub_categories`, { subCategories: shakeupSubCategories, outletId });
       
            const localSubCategories=(await localDB.itemSubCategories.toArray()).filter((sub)=>sub.outletId===outletId || sub.outletId===0);
            
            const combinedSubs=localSubCategories.map((local)=>{
                const matchingSub=newSubCategories.data.find((newSub)=>newSub.subCategoryDescription===local.subCategoryDescription);
                return matchingSub?{...local,itemSubCategoryId:matchingSub.itemSubCategoryId}:local;
            });
            return combinedSubs;
        } catch (error) {
            throw error;
        }
    }
    /** Function syncs offline subcategories that has been queued to online.
     @returns {Promise<IOutLetItem[]>} - Promise resolved with the newly pushed subcategories items being returned to the calling function.
     * @param itemCategories
     * @param itemRelatedCategories
     * @param itemSubCategories
     * @param items
     * @param outletId
     * @param users
     */
    async syncOutletItems(itemCategories: TCategory[], itemRelatedCategories: TRelatedItemCategory[], itemSubCategories: TItemSubcategory[], items: IOutLetItem[], outletId: number, users: TPerson[]): Promise<IOutLetItem[]> {
        try {
            const shakeupItems = items.map((item) => {
                const subCategory = itemSubCategories.find((subcategory) => subcategory.subCategoryDescription === item.subCategoryDescription);
                const relatedCategory = itemRelatedCategories.find((relatedCategory) => relatedCategory.relatedCategoryDescription === item.relatedCategoryDescription);
                const category = itemCategories.find((category) => category.categoryDescription === item.categoryDescription);
                const modifiedByUser = users.find((user) => user.username === item.username);
                return {
                    ...item,
                    itemSubcategoryId: subCategory !== undefined ? subCategory?.itemSubCategoryId : 0,
                    itemRelatedCategoryId: relatedCategory !== undefined ? relatedCategory?.relatedCategoryId : 0,
                    itemCategoryId: category !== undefined ? category?.categoryId : 0,
                    modifiedBy: modifiedByUser?.personId
                };
            });
            const newAddedItems= await fetchAction<IOutLetItem[]>('post', `${getBaseURL()}/items/sync_outlet_items`, { items: shakeupItems, outletId });
            const localItems=await localDB.outletItems.where('outletId').equals(outletId).toArray();
            const combinedItems=localItems.map((item)=>{
                const matchingItem=newAddedItems.data.find((newItem)=>newItem.itemName===item.itemName);
                return matchingItem?{...item,itemId:matchingItem.itemId}:item;
            });
            return combinedItems;
        } catch (error) {
            throw error;
        }
    }
    /** Function syncs offline customers that has been queued to online. It returns a promise of customer that are in sync with remote server
     * which is then used to update the customer values of the local sale transactions.
     @returns {Promise<TCustomer[]>} - Promise resolved with the newly pushed customers items being returned to the calling function.
     * @param customers
     * @param outletId
     * @param users
     */
    async syncCustomers(customers: TCustomer[], outletId: number, users: TPerson[]): Promise<TCustomer[]> {
        try {
            const hashedPWord=await bcrypt.hash('password', 10)

            const shakeCustomers = customers.map((customer) => {
                return { ...customer, modifiedBy: customer.modifiedBy,addressLine:'',password:hashedPWord,gender:1,customerType:1,customerCategory:3,creditLimit:0 };
            });
        
            const newCustomersAdded= await fetchAction<TCustomer[]>('post', `${getBaseURL()}/customers/sync_customers`, { customers: shakeCustomers, outletId });
            
            const localCustomers=await localDB.customers.where('outletId').equals(outletId).toArray();
            const combinedCustomers=localCustomers.map((customer)=>{

                const matchingCustomer=newCustomersAdded.data.find((newCustomer)=>newCustomer.username===customer.username);
                
                return matchingCustomer?{...customer,customerId:matchingCustomer.customerId,personId:matchingCustomer.personId,addressLine:'',password:hashedPWord}:customer;
            });
            return combinedCustomers;
        } catch (error: any) {
            throw error;
        }
    }

    /**
     * Function sync queued up sales in local db to online sales table.
     * Since ItemIds and modifier Ids could be different on remote server and local server, 
     * we match them to their various ids before pushing them to the remote server to avoid referential disintegrity.
     * @param syncedItems 
     * @param salesItems 
     * @param saleCustomers 
     * @param users 
     * @returns {Promise<AxiosResponse<TSaleTransactionItem[], any>>}
     */
    async   syncSalesItems(syncedItems: IOutLetItem[], salesItems: TSaleTransactionItem[], saleCustomers: TCustomer[], users: TPerson[]):Promise<AxiosResponse<TSaleTransactionItem[], any>> {
        try {
            const shakeupSales = salesItems.map((saleItem) => {
                const saleCustomer = saleCustomers.find((customer) => customer.fullName === saleItem.customerName);
                const saleCustomerId = saleItem.customerId === 1 ? 1 : saleCustomer?.customerId; //use cash customer default of 1 if find;
                const modifier = users.find((user) => user.username === saleItem.modifier);
                return {
                    ...saleItem,
                    customerId: saleCustomerId,
                    modifiedBy: modifier?.personId,
                    saleMode: saleItem.saleMode === 'Cash',
                    transactionItems: saleItem.transactionItems.map((transactionItem) => {
                        const itemInSyncItems =  syncedItems.find((item) => item.itemName === transactionItem.itemName);
                        return { ...transactionItem, itemId: itemInSyncItems!==undefined?itemInSyncItems?.itemId:transactionItem.itemId};
                    })
                };
            });
            return await fetchAction<TSaleTransactionItem[]>('post', `${getBaseURL()}/sales/sync_sale_transactions`, { saleTransactions: shakeupSales });
        } catch (error) {
            throw error;
        }
    }
        /**
     * Function sync queued up sales in local db to online sales table.
     * This is a mini version of the sync action in that this takes only 
     * current sales by current user and sync them online.
     * @param salesItems 
     * @param saleCustomers 
     * @returns {Promise<AxiosResponse<TSaleTransactionItem[], any>>}
     */
        async syncCurrentSales(salesItems: TSaleTransactionItem[], saleCustomers: TCustomer[]):Promise<AxiosResponse<TSaleTransactionItem[], any>> {
            try {
                const shakeupSales = salesItems.map((saleItem) => {
                    const saleCustomer = saleCustomers.find((customer) => customer.fullName === saleItem.customerName);
                    const saleCustomerId = saleItem.customerId === 1 ? 1 : saleCustomer?.customerId; //use cash customer default of 1 if find;
                    return {
                        ...saleItem,
                        customerId: saleCustomerId,
                        saleMode: saleItem.saleMode === 'Cash',
                    };
                });
                return await fetchAction<TSaleTransactionItem[]>('post', `${getBaseURL()}/sales/sync_sale_transactions`, { saleTransactions: shakeupSales });
            } catch (error) {
                throw error;
            }
        }
    async syncProformaInvoicesToRemote(localDBItems:IOutLetItem[],proformas: TProformaInvoice[],customers:TCustomer[],users:TPerson[]) {
   
        const shakeupProformas=proformas.map((proforma)=>{
            const saleCustomer = customers.find((customer) => customer.fullName === proforma.customerName);
            const saleCustomerId = proforma.customerId === 1 ? 1 : saleCustomer?.customerId; //use cash customer default of 1 if find;
            const modifier = users.find((user) => user.username === proforma.modifier);
            return {
                ...proforma,
                customerId: saleCustomerId,
                modifiedBy: modifier?.personId,
                transactionItems: proforma.transactionItems.map((transactionItem) => {
                    const itemInSyncItems = localDBItems.find((item) => item.itemName === transactionItem.itemName);

                    return { ...transactionItem, itemId: itemInSyncItems!==undefined?itemInSyncItems?.itemId:transactionItem.itemId};
                })
            };
        })

        return await fetchAction('post', `${getBaseURL()}/sales/sync_proforma_invoices`, { proformaInvoices:shakeupProformas });
    }
    async syncSuppliers(suppliers: TSupplier[], outletId: number, users: TPerson[]) {
        try {
            const shakeSuppliers = suppliers.map((supplier) => {
                const modifier = users.find((user) => user.username === supplier.modifierUsername);
                return { ...supplier, modifiedBy: modifier?.personId };
            });
            const newlyAddedSuppliers= await fetchAction<TSupplier[]>('post', `${getBaseURL()}/suppliers/sync_suppliers`, { suppliers: shakeSuppliers, outletId });
            
            const localSuppliers=await localDB.suppliers.toArray();
            const combinedSuppliers=localSuppliers.map((supplier)=>{
                const matchingSupplier=newlyAddedSuppliers.data.find((newSupplier)=>newSupplier.username===supplier.username);
                return matchingSupplier?{...supplier,supplierId:matchingSupplier.supplierId}:supplier;
            });
            return combinedSuppliers;
        } catch (error) {
            throw error;
        }
    }

    async syncPurchases(purchases: TPurchaseTransactionItem[], suppliers: TSupplier[], syncedItems: IOutLetItem[], users: TPerson[]) {
        try {
            const shakeupPurchases = purchases.map((purchase) => {
                const supplier = suppliers.find((supplier) => supplier.fullName === purchase.supplierName);
                const modifierPerson = users.find((user) => user.username === purchase.modifier);
                return {
                    ...purchase,
                    modifiedBy: modifierPerson?.personId,
                    supplierId: supplier?.supplierId,
                    transactionItems: purchase.transactionItems.map((transactionItem) => {
                        const itemInSync = syncedItems.find((item) => item.itemName === transactionItem.itemName);
                        return { ...transactionItem, itemId: itemInSync!==undefined?itemInSync?.itemId :transactionItem.itemId};
                    })
                };
            });
            return await fetchAction<TPurchaseTransactionItem[]>('post', `${getBaseURL()}/purchases/sync_purchase_transactions`, { purchaseTransactions: shakeupPurchases });
        } catch (error) {
            throw error;
        }
    }
    async synAdjustmentTransactionsToRemote(adjustments: TAdjustmentTransaction[], syncItems: IOutLetItem[], users: TPerson[]) {
        try {
            const shakeupAdjustments = adjustments.map((adjustment) => {
                const modifierPerson = users.find((user) => user.username === adjustment.username);
                return {
                    ...adjustment,
                    modifiedBy: modifierPerson?.personId,
                    adjustmentItems: adjustment.adjustmentItems.map((adjustmentItem) => {
                        const syncItem = syncItems.find((item) => item.itemName === adjustmentItem.itemName);
                        return { ...adjustmentItem, itemId: syncItem!==undefined?syncItem?.itemId :adjustmentItem.itemId};
                    })
                };
            });
            return await fetchAction<TAdjustmentTransaction[]>('post', `${getBaseURL()}/adjustments/sync_adjustments`, { adjustments: shakeupAdjustments });
        } catch (error) {
            throw error;
        }
    }
    async syncPriceChangeToRemote(syncItems:IOutLetItem[],priceChangeTransactions: TPriceChangeTransaction[], outletId: number,users:TPerson[]) {
        try{
            const shakeupPriceTransactions=priceChangeTransactions.map((priceTransaction)=>{
                const modifierPerson=users.find(user=>user.username===priceTransaction.modifier);
                return {
                    ...priceTransaction,
                    modifiedBy:modifierPerson?.personId,
                    priceChangeItems:priceTransaction.priceChangeItems.map((priceItem)=>{
                        const syncItem=syncItems.find((item)=>item.itemName===priceItem.itemName);
                        return {...priceItem,itemId:syncItem!==undefined?syncItem?.itemId:priceItem.itemId}
                    })
                }
            });
            return await fetchAction('post', `${getBaseURL()}/items/sync_price_change_history`, { priceChangeTransactions:shakeupPriceTransactions, outletId });
        }catch(error){
            throw error;
        }
    }
    async syncExpenseCatetgoriesOnline(expenseCategories:TExpenditureCategory[],outletId:number) {
        try{
            return await fetchAction('post', `${getBaseURL()}/expenditure/sync_expense_category`, { expenditureCategories:expenseCategories});
        }catch(error){
            throw error;
        }
    }
    async syncCustomerAndSupplierPaymentsToRemote(customerPayments: TCustomerPayment[], supplierPayments: TSupplierPayment[],customers:TCustomer[],suppliers:TSupplier[],users:TPerson[]) {
        const shakeupPayments=customerPayments.map((payment)=>{

            const modifierPerson=users.find(user=>user.username===payment.modifier);//coming from remote server

            const customer=customers.find((customer)=>customer.username===payment.customerUsername)//coming from remote server
            return {
                ...payment,modifiedBy:modifierPerson!==undefined?modifierPerson?.personId:payment.modifiedBy,customerId:customer!==undefined?customer?.customerId:payment.customerId
            }
        });
        const shakeupSupplierPayments=supplierPayments.map((payment)=>{
            const modifierPerson=users.find((user)=>user.username===payment.modifier);
            const supplier=suppliers.find((supplier)=>supplier.username===payment.supplierUsername);
            return {
                ...payment,modifiedBy:modifierPerson!==undefined?modifierPerson?.personId:payment.modifiedBy,supplierId:supplier!==undefined?supplier!.supplierId:payment.supplierId
            }
        })
        return await fetchAction('post', `${getBaseURL()}/customers/sync_customers_and_supplier_payments`, { customerPayments:shakeupPayments, supplierPayments:shakeupSupplierPayments });
    }
    /**
     * Syncs transfers that have been queued locally to remote database. Store outlets are not modified here for receiving 
     * and transferring location because outlets and stores are not allowed to be locally added.
     * @param syncItems 
     * @param storeOutlets 
     * @param transfersList 
     * @param users 
     * @returns 
     */
    async syncTransfersToRemote(syncItems:IOutLetItem[],transfersList: TTransferTransaction[],users:TPerson[]) {
        const transfers=transfersList.map((transfer)=>{
            const modifiedPerson=users.find((user)=>user.username===transfer.modifierUsername);
            return {
                ...transfer,
                modifiedBy:modifiedPerson?.personId,
                transferItems:transfer.transferItems.map((transferItem)=>{

                    const syncTransferItem=syncItems.find(item=>item.itemName===transferItem.itemName);

                    return {...transferItem,itemId:syncTransferItem!==undefined?syncTransferItem?.itemId:transferItem.itemId}
                })
            }
        });
        return await fetchAction('post', `${getBaseURL()}/transfers/sync_transfer_transactions`, { transfersList:transfers });
    }

    /** Function sync the remaining utilities queued tables data to remote server. These remaining tables includes discounts,taxes,gift cards and terms conditions.
     * The implemented fetch function returns array of the mentioned table data which is returned for the function;
     @returns {Promise<[IPromotionalDiscounts[],ITaxes[],IGiftCards[],ITermsConditions[]]>}
     * @param discounts
     * @param taxes
     * @param gifts
     * @param terms
     * @param expenses
     * @param outletId
     * @param users
     */
    async syncDiscountsTaxesGiftsTermsAndExpenditure(
        discounts: IPromotionalDiscounts[],
        taxes: ITaxes[],
        gifts: IGiftCards[],
        terms: ITermsConditions[],
        expenses: TExpenditure[],
        outletId: number,
        users: TPerson[]
    ): Promise<{ data: [[], IPromotionalDiscounts[], ITaxes[], IGiftCards[], ITermsConditions[], TExpenditure[]] }> {
        const shakeDiscounts = discounts.map((discount) => {
            const modifier = users.find((user) => user.username === discount.modifierUsername);
            return { ...discount, modifiedBy: modifier?.personId };
        });
        const shakeTaxes = taxes.map((tax) => {
            const taxModifier = users.find((taxUser) => taxUser.username === tax.modifierUsername);
            return { ...tax, modifiedBy: taxModifier?.personId };
        });
        const shakeGifts = gifts.map((gift) => {
            const giftModifier = users.find((user) => user.username === gift.modifierUsername);
            return { ...gift, modifiedBy: giftModifier };
        });
        const shakeTerms = terms.map((term) => {
            const termsModifier = users.find((user) => user.username === term.modifierUsername);
            return { ...term, modifiedBy: termsModifier?.personId };
        });
        const shakeExpenses = expenses.map((expense) => {
            const expenseModifier = users.find((user) => user.username === expense.username);
            return { ...expense, modifiedBy: expenseModifier?.personId };
        });
        const discountsTaxesGiftsTermsAndExpenses = { discounts: shakeDiscounts, taxes: shakeTaxes, gifts: shakeGifts, terms: shakeTerms, expenses: shakeExpenses };

        return await fetchAction<[[], IPromotionalDiscounts[], ITaxes[], IGiftCards[], ITermsConditions[], TExpenditure[]]>('post', `${getBaseURL()}/outlets/sync_utility_tables`, { discountsTaxesGiftsTermsAndExpenses, outletId });
    }
    /** Function syncs offline users with online users. It creates for online, all users that have been created online since last sync.
     * This latest data of users will therefore be used to update further syncs to accommodate current dependency properties that are in tandem with
     * remote server properties. [like userId]
     @returns {Promise<TPerson[]>} - Promise resolved with the newly pushed related categories item being returned to the calling function.
     * @param users - The users data to be synced.
     * @param outletId - The outlet that is initiating the action.
     */
    async syncUsers(users: TPerson[], outletId: number): Promise<TPerson[]> {
        try {
            const newUsers= await fetchAction<TPerson[]>('post', `${getBaseURL()}/store_users/sync_users`, { users, outletId });
            const localUsers=await localDB.users.where('userRole').belowOrEqual(3).toArray();
            const combinedUsers=localUsers.map((user)=>{
                const matchingUser=newUsers.data.find((newUser)=>newUser.username===user.username);
                return matchingUser?{...user,userId:matchingUser.personId}:user;
            });
            return combinedUsers;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Takes queued customer and suppliers payments to remote server.
     * @param customerPayments
     * @param supplierPayments
     */

    /**
     * Loops through the queued tables and reset their values to empty.
     */
    async clearQueuedData() {
        try {
            localDB.transaction('rw', localDB.queuedTables, async () => {
                await localDB.queuedTables.clear();
            });
        } catch (error) {
            throw error;
        }
    }

    async createCashCustomer() {
        await localDB.customers.put({
            customerId: 1,
            fullName: 'Cash Customer',
            username: 'Cash',
            phoneNumber: '0000000000',
            gender: 0,
            emailAddress: 'mail@mail.com',
            addressCity: '',
            addressLine: '',
            addressCountry: '',
            addressState: '',
            customerType: 0,
            customerCompany: '',
            customerCategory: 0
        });
    }

    /**
     * save a user's settings data to local database for assessment when user turns app to offline mode;
     * @param settings
     */
    async saveUserSettings(settings: TUserSettings) {
        try {
            return localDB.transaction('rw', localDB.userSettings, async () => {
                return await localDB.userSettings.put({ settingsId: 1, ...settings });
            });
        } catch (error: any) {
            throw error;
        }
    }

    /**
     * 
     * @param salesList 
     * @param purchasesList 
     * @param transfers 
     * @param adjustments 
     * @returns {promise<[TTransactionItem,TTransactionItem,TTransactionItem,TAdjustmentItem]>}
     */
    async getAllTransactionItems(salesList:TSaleTransactionItem[],purchasesList:TPurchaseTransactionItem[],transfers:TTransferTransaction[],adjustments:TAdjustmentTransaction[]){

        const salesListItems=salesList.map(saleItem=>typeof saleItem.transactionItems==='string'?JSON.parse(saleItem.transactionItems):saleItem.transactionItems);
        const purchaseItems=purchasesList.map(purchase=>typeof purchase.transactionItems==='string'?JSON.parse(purchase.transactionItems):purchase.transactionItems);
        const transferItems=transfers.map(transfer=>typeof transfer.transferItems==='string'?JSON.parse(transfer.transferItems):transfer.transferItems);
        const adjustmentItems=adjustments.map(adjustment=>typeof adjustment.adjustmentItems==='string'?JSON.parse(adjustment.adjustmentItems):adjustment.adjustmentItems);
  

        const allSalesItems=_.flatten(salesListItems).map((saleItem:TTransactionItem)=>{
            return {...saleItem,itemId:getParsedId(saleItem.itemId),itemName:saleItem.itemName}
        });
   
        const allTransferItems=_.flatten(transferItems).map((transferItem:TTransactionItem)=>{
            return {...transferItem,itemId:getParsedId(transferItem.itemId),itemName:transferItem.itemName}
        });

        const allAdjustmentItems=_.flatten(adjustmentItems).map((adjustmentItem:TAdjustmentItem)=>{
            return {itemId:getParsedId(adjustmentItem.itemId),itemName:adjustmentItem.itemName}
        });

        const allPurchaseItems=_.flatten(purchaseItems).map((purchaseItem:TTransactionItem)=>{
            return {...purchaseItem,itemId:getParsedId(purchaseItem.itemId),itemName:purchaseItem.itemName}
        });


        const allItems=_.flatten([allSalesItems,allTransferItems,allAdjustmentItems,allPurchaseItems])

        return _.uniqBy(allItems,'itemId');
    }

    async updateSyncItemsStock(outletItems:IOutLetItem[],transactionItems:Pick<TTransactionItem,'itemId'|'itemName'>[],currentLocationId:number){
        const currentUserLocationItems:IOutLetItem[]=_.filter(outletItems,{outletId:getParsedId(currentLocationId)})
        return localDB.transaction('rw',localDB.outletItems,async()=>{
            transactionItems.forEach(async(transactionItem)=>{
                const remoteItem=currentUserLocationItems.find(correspondingItem=>correspondingItem.itemId===transactionItem.itemId);
                if(remoteItem!==undefined){
                    await localDB.outletItems.update(remoteItem.itemId!,{stockQuantity:remoteItem.stockQuantity})
                }
            });
        });
    }

    async getLastRemoteSyncTime(){
        try{
            const lastRemoteSyncTime=await localDB.trackTable.get(1);
            return lastRemoteSyncTime!==undefined?lastRemoteSyncTime.lastSyncOnline:new Date('2024-01-01');//set time to when before anyone used the app.
        }catch(error){
            throw error;
        }
    }

    async getLastLocalSyncTime(){
        try{
            const lastRemoteSyncTime=await localDB.trackTable.get(1);
            return lastRemoteSyncTime!==undefined?lastRemoteSyncTime.lastSyncLocal:new Date('2024-01-01');//set time to when before anyone used the app.
        }catch(error){
            throw error;
        }
    }

    async setLastRemoteSyncTimeToLocal(){
        try{
            const lastSyncProperties:TrackTable={trackId:1,lastSyncOnline:getDateAndTime(new Date())};
            await localDB.trackTable.put(lastSyncProperties);
        }catch(error){
            throw error;
        }
    }

    async setLastSalesRemoteSyncTimeToLocal(){
        try{
            await localDB.trackTable.update(1,{lastSalesSyncToLocal:getDateAndTime(new Date())});
        }catch(error){
            throw error;
        }
    }
    
    async getSalesLastSyncToLocalTime(){
        try{
            const lastRemoteSyncTime=await localDB.trackTable.get(1);
            return lastRemoteSyncTime!==undefined?lastRemoteSyncTime.lastSalesSyncToLocal!==undefined?lastRemoteSyncTime.lastSalesSyncToLocal:new Date('2024-01-01'):new Date('2024-01-01');//set time to when before anyone used the app.
        }catch(error){
            throw error;
        }
    }
    async setLastItemsOnlySync(){
        try{
            await localDB.trackTable.update(1,{lastItemsSyncToLocal:getDateAndTime(new Date())});
        }catch(error){
            throw error;
        }
    }
    async getLastItemsOnlySync(){
        try{
            const lastRemoteSyncTime=await localDB.trackTable.get(1);
            return lastRemoteSyncTime!==undefined?lastRemoteSyncTime.lastItemsSyncToLocal!==undefined?lastRemoteSyncTime.lastItemsSyncToLocal:new Date('2024-01-01'):new Date('2024-01-01');//set time to when before anyone used the app.
        }catch(error){
            throw error;
        }
    }
    async syncStoreSubscription(subscription:TStoreSubscription[]){
        try{
            return localDB.transaction('rw',localDB.storeSubscriptions,async()=>{
                await localDB.storeSubscriptions.bulkPut(subscription);
            });
        }catch(error){
            throw error;
        }
    }
    async syncExpenditureCategories(expenditureCategories:TExpenditureCategory[]){
        try{
            return localDB.transaction('rw',localDB.expenseCategories,async()=>{
                await localDB.expenseCategories.bulkPut(expenditureCategories);
            });
        }catch(error){
            throw error;
        }
    }
    async getLocalExplainedItems(outletId:number,requestionOption:number){
        try{
            return localDB.transaction('r',localDB.outletItems,async()=>{
                return requestionOption===1? await localDB.outletItems.where({outletId:outletId}).filter((outletItem)=>parseInt(outletItem.stockQuantity)===0).toArray():requestionOption===2?await localDB.outletItems.where({outletId}).filter((outletItem)=>parseInt(outletItem.stockQuantity)<0).toArray():await localDB.outletItems.where({outletId}).filter((outletItem)=>parseInt(outletItem.stockQuantity)>0).toArray();
            });
        }catch(error){
            throw error;
        }
    }

    async getOutletPaginatedItems(outletId:number){
        try{
            await localDB.outletItems.clear();// remove all local items;
            const lastSyncDate= getDateAndTime(new Date('2024-01-01'));
            const itemsLink = `items/sync_outlet_items_remote`;
            const itemsList:IOutLetItem[]=await this.getOnlinePaginatedItems<IOutLetItem>(outletId!,itemsLink,lastSyncDate,"items/get_outlet_items_count");
            return itemsList
        }catch(error){
            throw error;
        }
    }
}

export default SettingsArena;
