import React, { memo, MutableRefObject, ReactNode, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataView } from 'primereact/dataview';
import { Divider } from 'primereact/divider';
import { DynamicInputElement, TSaleTransactionItem, TTransactionItem } from '../types/UtilTypes';
import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';
import SimpleTableWithMenu from './components/SimpleTableWithMenu';
import { formatDate } from '@fullcalendar/core';

type TIconTextInput = {
    value: string | number;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholderValue: string;
    iconText: string;
    componentId: string;
    customClasses?: string;
    showLabel?: boolean;
    inputType?: 'number' | 'text' | 'password' | 'email';
};
export const IconTextInput = ({ value, onInputChange, placeholderValue, iconText, componentId, customClasses, showLabel = true, inputType = 'text' }: TIconTextInput) => {
    return (
        <>
            <div className={`expanded-control ${customClasses}`}>
                {showLabel && (
                    <div className="mb-2">
                        <label htmlFor={componentId}>{placeholderValue}</label>
                    </div>
                )}
                <span className="p-input-icon-left">
                    <i className={`pi ${iconText}`} />
                    <InputText type={inputType} id={componentId} value={value === null ? '' : value.toString()} placeholder={placeholderValue} onChange={onInputChange} autoComplete="false" />
                </span>
            </div>
        </>
    );
};

export interface DatePickerProps {
    dateValue: string | Date | Date[];
    onDateChange: (e: CalendarChangeEvent) => void;
    labelText: string;
    controlId: string;
    dateFormat?: string;
    displayTime?: boolean;
    selectionType?: 'single' | 'multiple' | 'range';
    displayButtonBar?: boolean;
    minimumDateValue?: Date;
    maximumDateValue?: Date;
    view?: 'month' | 'year' | 'date';
    showCalendarAsInline?: boolean;
    onClearDates?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    pageTabIndex?: number;
}

export const DatePicker: React.FC<DatePickerProps> = ({
    dateValue,
    onDateChange,
    labelText,
    controlId,
    dateFormat = 'yy-mm-dd',
    displayTime = false,
    selectionType = 'single',
    displayButtonBar = false,
    minimumDateValue,
    maximumDateValue,
    view = 'date',
    showCalendarAsInline = false,
    pageTabIndex,
    onClearDates = () => {}
}) => {
    return (
        <Calendar
            name={controlId}
            id={controlId}
            placeholder={labelText}
            className="expanded-control mr-3"
            value={dateValue}
            onChange={onDateChange}
            dateFormat={dateFormat}
            selectionMode={selectionType}
            showButtonBar={displayButtonBar}
            showTime={displayTime}
            minDate={minimumDateValue}
            maxDate={maximumDateValue}
            view={view}
            inline={showCalendarAsInline}
            autoZIndex={true}
            showIcon={true}
            onClearButtonClick={onClearDates}
            tabIndex={pageTabIndex}
        />
    );
};

interface PromptUserActionProps {
    yesAction?: () => void;
    noAction?: () => void;
    event: React.MouseEvent<HTMLButtonElement>;
    displayText: string;
    yesActionDisplay?: string;
    noActionDisplay?: string;
    textSize?: number;
}

export const promptUserAction = ({ yesAction = () => {}, noAction = () => {}, event, displayText, yesActionDisplay = 'Yes', noActionDisplay = 'No', textSize = 14 }: PromptUserActionProps) => {
    confirmPopup({
        target: event.currentTarget,
        message: displayText,
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-success',
        rejectClassName: 'p-button-danger',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        accept: yesAction,
        reject: noAction,
        acceptLabel: yesActionDisplay,
        rejectLabel: noActionDisplay,
        style: { width: '400px', fontSize: textSize }
    });
};

interface ISpinner {
    customClasses: string;
}
export const DefaultSpinner = ({ customClasses = '' }: ISpinner) => {
    return (
        <>
            <i className={`pi pi-spin pi-spinner ${customClasses}`}></i>
            <span> ...Working on It. Please wait!</span>
        </>
    );
};

export const tableEditOption = (editClick: (e: React.MouseEvent<HTMLButtonElement>) => void, deleteClick: (e: React.MouseEvent<HTMLButtonElement>) => void, idProperty = '', recordStatus?: 'online' | 'offline') => {
    return (
        <React.Fragment>
            <Button id={idProperty} icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={editClick} />
            <Button icon="pi pi-trash" name={idProperty} className="p-button-rounded p-button-warning" onClick={deleteClick} />
        </React.Fragment>
    );
};

interface DynamicInputProps {
    setStateValues: Function;
    customFields: DynamicInputElement[];
}

export const DynamicInputComponent: React.FC<DynamicInputProps> = ({ setStateValues, customFields }) => {
    const [label, setLabel] = useState('');
    const [inputText, setInputText] = useState('');
    const [editableIndex, setEditableIndex] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const handleAddInput = () => {
        if (label && inputText) {
            const newInput: DynamicInputElement = {
                label,
                inputText,
                index: customFields.length
            };
            setStateValues({ customFields: [...customFields, newInput] });
            setLabel('');
            setInputText('');
        }
    };

    const handleEdit = (index: number) => {
        setEditableIndex(index);
        setIsEditing(true);
    };
    const handleRemove = (index: number) => {
        if (customFields.length === 1) {
            setStateValues({ customFields: [] });
            return;
        }
        customFields.splice(index, 1);
        const updatedInputs = [...customFields];
        setStateValues({ customFields: updatedInputs });
    };
    const handleInputChange = (index: number, newValue: string) => {
        const updatedInputs = [...customFields];
        updatedInputs[index].inputText = newValue;
        setStateValues({ customFields: updatedInputs });
    };
    const updateCustomField = () => {
        setIsEditing(false);
    };

    const viewTemplate = (customField: { inputText: string; label: string; index: number }) => {
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="grid">
                        <div className="lg:col-4 md:col-4 col-12">{customField.label}</div>
                        <div className="lg:col-4 md:col-4 col-12">
                            {editableIndex === customField.index && isEditing ? <InputText value={customField.inputText} onChange={(e) => handleInputChange(customField.index, e.target.value)} /> : <span>{customField.inputText}</span>}
                        </div>
                        <div className="lg:col-2 md:col-2 col-12">
                            <Button
                                icon={editableIndex === customField.index && isEditing ? 'pi pi-refresh' : 'pi pi-pencil'}
                                onClick={editableIndex === customField.index && isEditing ? updateCustomField : () => handleEdit(customField.index)}
                                className="bg-green-600 mr-2"
                                size="small"
                            />
                        </div>
                        <div className="lg:col-2 md:col-2 col-12">
                            <Button icon="pi pi-trash" onClick={() => handleRemove(customField.index)} className="bg-red-800" size="small" />
                        </div>
                    </div>
                </div>
                <Divider />
            </React.Fragment>
        );
    };
    return (
        <div>
            <div className="lg:col-12 md:col-12 col-12 p-fluid grid">
                <div className="lg:col-5 md:col-12 col-12">
                    <InputText placeholder="Label" value={label} onChange={(e) => setLabel(e.target.value)} />
                </div>
                <div className="lg:col-5 md:col-12 col-12">
                    <InputText placeholder="Value" value={inputText} onChange={(e) => setInputText(e.target.value)} />
                </div>
                <div className="lg:col-2 md:col-12 col-12">
                    <Button label="Add" onClick={handleAddInput} />
                </div>
            </div>
            <div className="card w-full">
                <DataView value={customFields} itemTemplate={viewTemplate}></DataView>
            </div>
        </div>
    );
};

interface GeneralPagePropsProps {
    toastRef: MutableRefObject<Toast | null>;
    toastPosition?: 'top-right' | 'center' | 'top-left' | 'bottom-left' | 'bottom-right' | 'top-center' | 'bottom-center' | undefined;
}

export const GeneralPageProps: React.FC<GeneralPagePropsProps> = ({ toastRef, toastPosition = 'bottom-right' }) => {
    return (
        <>
            <Toast ref={toastRef} position={toastPosition} />
            <ConfirmPopup />
        </>
    );
};

type TGallery = {
    itemImages: any[];
    // galleryRef:React.RefObject<Galleria>,
    destoryImage: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
export const ImageGallery = ({ itemImages, destoryImage }: TGallery) => {
    console.log('rendering');
    const responsiveOptions: GalleriaResponsiveOptions[] = [
        {
            breakpoint: '1500px',
            numVisible: 5
        },
        {
            breakpoint: '1024px',
            numVisible: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];
    const itemTemplate = (item: any) => {
        return (
            <div>
                <Button id={item.publicId} className="p-button-rounded p-button-danger mb-2" icon="pi pi-trash" onClick={destoryImage} />
                <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />
            </div>
        );
    };

    const thumbnailTemplate = (item: { thumbnailImageSrc: string; alt: string }) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block', width: '100px', height: '80px' }} />;
    };
    return (
        <>
            <div className="card flex justify-content-center">
                <Galleria value={itemImages} responsiveOptions={responsiveOptions} numVisible={9} style={{ maxWidth: '50%' }} circular showItemNavigators={true} item={itemTemplate} thumbnail={thumbnailTemplate} showThumbnails={true} />
            </div>
        </>
    );
};

type TDTView = {
    itemsList: any;
    listTemplate: ReactNode;
    layout: string;
};

export const menuItemRenderer = (item: any) => (
    <a className="flex align-items-center p-menuitem-link bg-blue-700 mr-2" onClick={item.command}>
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
        {/* {item.badge && <Badge className="ml-auto" value={item.badge} />}
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>} */}
    </a>
);

type TSyncsBySideProps = {
    localData: TSaleTransactionItem[];
    remoteData: TSaleTransactionItem[];
};
export const SyncsBySides = ({ localData, remoteData }: TSyncsBySideProps) => {
    const checkSyncs = async () => {
        const localTableRows = document.getElementById('localQueuedTransactions')?.querySelectorAll('tr');
        const remoteTableRows = document.getElementById('remoteTransactions')?.querySelectorAll('tr');

        localTableRows!.forEach((row, index) => {
            let i;
            const localTableInvoiceCell = row.querySelectorAll('td')[1];

            for (i = 0; i < remoteTableRows!.length; i++) {
                if (localTableInvoiceCell === undefined) continue;
                const cellValue = localTableInvoiceCell.textContent;
                const currentRemoteTableRowCells = remoteTableRows![i].querySelectorAll('td');
                if (currentRemoteTableRowCells.length > 0) {
                    const remoteInvoiceCellValue = currentRemoteTableRowCells[1].textContent;
                    if (remoteInvoiceCellValue === cellValue) {
                        currentRemoteTableRowCells[1].classList.add('text-green-500');
                        localTableInvoiceCell.classList.add('text-green-500');
                    }
                }
            }
        });
    };
    return (
        <>
            <div className="grid">
                <div className="lg:col-6" id="localQueuedTransactions">
                    <SimpleTableWithMenu
                        tableKey={'invoiceNumber'}
                        columnsDef={[
                            { body: (transaction: TSaleTransactionItem) => <div>{formatDate(new Date(transaction.transactionDate as Date))}</div>, header: 'Date' },
                            { field: 'invoiceNumber', header: 'Invoice' }
                        
                        ]}
                        tableData={localData}
                        menuModel={[]}
                        tableTitle="Transactions Lists"
                        searchValues={['invoiceNumber', 'customerName', 'transactionDate']}
                        searchFieldPlaceHolder="Search Invoice Number, Customer or Transaction Date"
                        numberOfRows={100}
                        pageRows={[100,300,500]}
                    />
                </div>
                <div className="lg:col-6" id="remoteTransactions">
                    <SimpleTableWithMenu
                        tableKey={''}
                        columnsDef={[
                            { body: (transaction: TSaleTransactionItem) => <div>{formatDate(new Date(transaction.transactionDate as Date))}</div>, header: 'Date' },
                            { field: 'invoiceNumber', header: 'Invoice' }
                        ]}
                        tableData={remoteData}
                        menuModel={[]}
                        tableTitle="Transactions Lists"
                        searchValues={['invoiceNumber', 'customerName', 'transactionDate']}
                        searchFieldPlaceHolder="Search Invoice Number, Customer or Transaction Date"
                        numberOfRows={100}
                        pageRows={[100,300,500]}
                    />
                </div>
                <Button onClick={checkSyncs}>Check Syncs</Button>
            </div>
        </>
    );
};
