import { TQueuedTable, TSaleTransactionItem } from 'UtilTypes';
import { localDB } from '../offline/db';
import { generateUnique13DigitNumber, getDateAndTime,formatDate } from '../helpers/utils';
import { TCustomer } from 'TPerson';
const _ = require('lodash');
class Queue{
    async initialize(){
        const queuedData=await localDB.queuedTables.get(1);
        if(queuedData!==undefined){
            await localDB.queuedTables.put({
                tableId:1,
                taxes:queuedData.taxes!,
                adjustments:queuedData.adjustments,
                customers:queuedData.customers,
                discounts:queuedData.discounts,
                gifts:queuedData.gifts,
                expenditures:queuedData.expenditures,
                itemCategories:queuedData.itemCategories,
                outlets:queuedData.outlets,
                relatedCategories:queuedData.relatedCategories,
                outletItems:queuedData.outletItems,
                purchases:queuedData.purchases,
                sales:queuedData.sales,
                stores:queuedData.stores,
                subCategories:queuedData.subCategories,
                suppliers:queuedData.suppliers,
                terms:queuedData.terms,
                users:queuedData.users,
                accountsPayments:queuedData.accountsPayments!==undefined?queuedData.accountsPayments:[],
                supplierPayments:queuedData.supplierPayments!==undefined?queuedData.supplierPayments:[],
                proformaInvoices:queuedData.proformaInvoices!==undefined?queuedData.proformaInvoices:[],
                transfers:queuedData.transfers!==undefined?queuedData.transfers:[],
                priceChangeHistory:queuedData.priceChangeHistory!==undefined?queuedData.priceChangeHistory:[],
                barcodes:queuedData.barcodes!==undefined?queuedData.barcodes:[],
                expenseCategories:queuedData.exp
            });
        }else{
            await this.createNewQue();
        }

    }
    async enqueue<T>(item:T,tableDescription:string){
        try{
            return localDB.transaction('rw',localDB.queuedTables,async()=>{
                const tableOnlyRecord=await localDB.queuedTables.get(1);
                let updatedTableData=tableOnlyRecord![tableDescription];
                updatedTableData.push(item);
                tableOnlyRecord![tableDescription]=updatedTableData;
                return localDB.queuedTables.put(tableOnlyRecord!);
            });
        }catch(error:any){
            throw error;
        }
    }
    async updateEnqueue<T>(item:T,tableDescription:string,tableIdProperty:string,updatingTransactionId:number){
        try{
            localDB.transaction('rw', localDB.queuedTables, async () => {
                const tableOnlyRecord = await localDB.queuedTables.get(1);
                let updatedTableData = tableOnlyRecord![tableDescription];
                const index=_.findIndex(updatedTableData,{[tableIdProperty]:updatingTransactionId});
                if(index!==-1){
                    updatedTableData[index]=item;
                    await localDB.queuedTables.update(1,{[tableDescription]:updatedTableData});
                }
            });
        }catch(error:any){
            throw error;
        }
    }

    async getQueueTables(){
        try{
            return await localDB.queuedTables.get(1);
        }catch(error:any){
            throw error;
        }
    }
    async getQueuedSalesAndCustomers():Promise<{customers:TCustomer[],saleTransactions:TSaleTransactionItem[]}>{
        try{
            const localQueue= await localDB.queuedTables.get(1);
            const customers= localQueue?.customers?localQueue.customers:[];
            const sales=localQueue?.sales?localQueue.sales:[]
            return {customers,saleTransactions:sales}
        }catch(error){
            throw error;
        }
    }
    async createNewQue(){
        try{
            await localDB.queuedTables.put({
                tableId:1,
                taxes:[],
                adjustments:[],
                customers:[],
                discounts:[],
                gifts:[],
                expenditures:[],
                itemCategories:[],
                outlets:[],
                relatedCategories:[],
                outletItems:[],
                purchases:[],
                sales:[],
                stores:[],
                subCategories:[],
                suppliers:[],
                terms:[],
                users:[],
                accountsPayments:[],
                supplierPayments:[],
                proformaInvoices:[],
                transfers:[],
                priceChangeHistory:[],
                barcodes:[],
                expenseCategories:[]
            });
        }catch(error){
            throw error;
        }
    }
    
    async saveQueuedTableData(previousQueuedData:TQueuedTable){
        try{
            return localDB.transaction('rw',localDB.previousQueuedData,async()=>{
                const queuedTableData={
                    previousQueuedData: previousQueuedData, previousQueuedTimeStamp: getDateAndTime(new Date),
                    previousQueuedDataId: generateUnique13DigitNumber()
                };
                await localDB.previousQueuedData.add(queuedTableData);
            });
        }catch(error:any){
            throw error;
        }
    }

    async getPreviousQueueTableData(savedDate:string){
        try{
            return localDB.transaction('rw',localDB.previousQueuedData,async()=>{
                return localDB.previousQueuedData.filter((queuedData)=>{
                    return formatDate(new Date(queuedData.previousQueuedTimeStamp))===savedDate;
                }).toArray();
            });
        }catch(error){
            throw error;
        }
    }
}
export default Queue;