import React, { ReactNode, useState, useEffect } from 'react';
import { Error } from '../../pages/Error';

interface ErrorBoundaryProps {
    children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const errorHandler = (error: ErrorEvent) => {
            console.error('Error caught by error boundary:', error);
            setHasError(true);
        };

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);

    if (hasError) {
        return <Error />;
    }

    return <>{children}</>;
};

export default ErrorBoundary;
