import {QueryClient, QueryKey, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import fetchAction from "./axiosConfig";
import {TLoginData} from "./customTypesUtils";
import {addNewItemToCache, deleteCacheItem, getBaseURL, updateCacheItem} from "./utils";
import {TPerson} from "../types/TPerson";
const _=require('lodash');
export const doUserLogin = async (userDetails: TLoginData) => {
    const response: AxiosResponse<any> = await fetchAction('post',
        `${getBaseURL()}/registration/user_log_in`, {userDetails});
    if (response.data.status === 0) {
        throw new Error(response.data.error);
    }
    return response.data.data;
}
export const fetchData=async <T>(urlLink:string,queryClient: QueryClient,queryKey: QueryKey,staleTime:number=86400000,cacheTime:number=86400000)=>{
    return await queryClient.fetchQuery({
        queryKey:queryKey,
        queryFn:()=>fetchAction<T>('get',
            `${getBaseURL()}/${urlLink}`, {}).then((data)=>{
                return data.data;
        }).catch((error: any)=>{
            throw new Error('Fetch Failed');
        }),
        staleTime:staleTime,
        cacheTime:cacheTime,
        networkMode:'always'
    });
}

export async function addRecordToCache<T>(queryClient: QueryClient, keyInCache: QueryKey, newItem: T) {
    try {
        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const updatedArray = await addNewItemToCache(existingArray, newItem);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, updatedArray);
    } catch (error) {
        console.log(error);
    }
}

export async function updateCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey, data: any) {
    try {
        const updatedItem = data[0]

        const itemId = data[1] as string;

        const itemIdProp = data[2] as string;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const selectedRecord = existingArray?.find((item: any) => item[itemIdProp] === itemId);

        if (selectedRecord) {
            const updatedArray = await updateCacheItem<T | string>(updatedItem, selectedRecord!, existingArray);

            // Update the cache with the updated array
            queryClient.setQueryData(keyInCache, [...updatedArray]);
        }
    } catch (error: any) {
        console.log(error);
    }
}

export async function deleteCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey, data: any[]) {
    try {

        const deletingItem = data[0]

        const itemId = data[1] as string;

        const itemIdProp = data[2] as string;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];

        const selectedRecord = existingArray.find((rec: any) => rec[itemIdProp] === itemId);

        const remainingData = await deleteCacheItem(deletingItem, selectedRecord, existingArray);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, remainingData);
    } catch (error) {
        console.log(error);
    }
}
