import React from "react";
import PasswordInput from "../helpers/components/PasswordInput";
import {usePasswordContext} from "./ChangePassword";
import { Button } from 'primereact/button';

export const PasswordChange = () => {
    const {stateValueChange,componentState,resetPassword}=usePasswordContext();
    return (
        <React.Fragment>
            <div className="flex justify-content-center flex-wrap">
                <div className="grid">
                    <div className="col-12 mb-2 lg:col-6 lg:mb-0">
                        <div className="mb-2"><label className="ml-2">Password</label></div>
                        <PasswordInput inputLabel="Enter Password" onPasswordChange={stateValueChange!} name="password"
                                       passwordValue={componentState!.password} groupIcon={"pi pi-lock"}/>
                    </div>
                    <div className="col-6 mb-2 lg:col-6 lg:mb-0">
                        <div className="mb-2"><label className="ml-2">Repeat Password</label></div>
                        <PasswordInput inputPlaceholder="Repeat Password" inputLabel="Enter Same Password Again"
                                       onPasswordChange={stateValueChange!} name="repeatPassword"
                                       passwordValue={componentState!.repeatPassword} groupIcon={"pi pi-lock"}/>
                    </div>

                </div>
                <Button onClick={resetPassword}>Reset Password</Button>
            </div>
        </React.Fragment>
    )
}
