import { DatePicker, IconTextInput } from '../../helpers/utilComponents';
import FilterSelect from '../../helpers/components/FilterSelect';
import React, { memo, RefObject } from 'react';
import { IAddress } from '../../interfaces/IAddress';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { currencyFormat, DropdownOption, formatAccountingNumbers, onInputControlFocus } from '../../helpers/utils';
import { InputNumber, InputNumberChangeEvent, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import { IOutLetItem, TAdjustmentItem, TEditingWindowProps, TMetaSummary, TPriceChangeItem, TSelectButtonOption, TTransactionItem } from '../../types/UtilTypes';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { CalendarChangeEvent } from 'primereact/calendar';
import type { SVGProps } from 'react';
interface ACIType extends IAddress {
    countries: DropdownOption[];
    controlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCountryChange: (e: DropdownChangeEvent) => void;
}

export const Address: React.FC<ACIType> = ({ addressLine = '', addressCity = '', addressState = '', addressCountry = '', postalCode = '', countries = [], controlChange, onCountryChange }) => {
    return (
        <>
            <IconTextInput value={addressLine} onInputChange={controlChange} placeholderValue="Address LIne *" iconText="pi pi-map-marker" componentId="addressLine" customClasses="lg:col-6 md:col-12 col-12" />

            <IconTextInput value={addressCity} onInputChange={controlChange} placeholderValue="Address City" iconText="pi pi-map-marker" componentId="addressCity" customClasses="lg:col-6 md:col-12 col-12" />

            <IconTextInput value={addressState} onInputChange={controlChange} placeholderValue="Address State" iconText="pi pi-map" componentId="addressState" customClasses="lg:col-6 md:col-12 col-12" />
            <div className="field lg:col-6 md:col-12 col-12">
                <FilterSelect selectableOptions={countries} selectedOption={addressCountry} onSelectChange={onCountryChange} elementId="addressCountry" defaultValue="Countries" />
            </div>
            <IconTextInput value={postalCode!} onInputChange={controlChange} placeholderValue="Postal Code" iconText="pi pi-ticket" componentId="postalCode" customClasses="lg:col-6 md:col-12 col-12" />
        </>
    );
};

interface PersonDetails {
    fullName: string;
    phoneNumber: string;
    emailAddress: string;
    username: string;
    controlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PersonalDetails: React.FC<PersonDetails> = ({ fullName = '', phoneNumber = '', emailAddress = '', username = '', controlChange }) => {
    return (
        <>
            <IconTextInput value={fullName} onInputChange={controlChange} placeholderValue="Full Name *" iconText="pi pi-user-edit" componentId="fullName" customClasses="lg:col-6 md:col-12 col-12" />

            <IconTextInput value={phoneNumber} onInputChange={controlChange} inputType="number" placeholderValue="Phone Number *" iconText="pi pi-phone" componentId="phoneNumber" customClasses="lg:col-6 md:col-12 col-12" />

            <IconTextInput value={emailAddress} onInputChange={controlChange} placeholderValue="Email Address" iconText="pi pi-at" componentId="emailAddress" customClasses="lg:col-6 md:col-12 col-12" inputType="email" />

            <IconTextInput value={username} onInputChange={controlChange} placeholderValue="User Name *" iconText="pi pi-user" componentId="username" inputType="text" customClasses="lg:col-6 md:col-12 col-12" />
        </>
    );
};
interface TQuickCustomer {
    fullName: string;
    phoneNumber: string;
    onPhoneNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFullNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const QuickCustomer: React.FC<TQuickCustomer> = ({ fullName = '', phoneNumber = '', onPhoneNumberChange,onFullNameChange }) => {
    return (
        <div className="p-fluid grid">
            <h5>Customer Quick Add</h5>
            <IconTextInput value={phoneNumber} onInputChange={onPhoneNumberChange} inputType="number" placeholderValue="Phone Number *" iconText="pi pi-phone" componentId="phoneNumber" customClasses="lg:col-12 md:col-12 col-12" />
            <IconTextInput value={fullName} onInputChange={onFullNameChange} placeholderValue="Full Name *" iconText="pi pi-user-edit" componentId="fullName" customClasses="lg:col-12 md:col-12 col-12" />
        </div>
    );
};
interface IStoreDetails {
    storeDescription: string;
    storeType: number;
    storeTypes: DropdownOption[];
    emailAddress: string;
    phoneNumber: string;
    controlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSelectChange: (e: DropdownChangeEvent) => void;
    onStoreTypeChangeEvent: (e: DropdownChangeEvent) => void;
}

export const StoreDetails: React.FC<IStoreDetails> = ({ storeDescription, storeType, storeTypes, emailAddress, phoneNumber, controlChange, onSelectChange, onStoreTypeChangeEvent }) => {
    return (
        <>
            <IconTextInput componentId="storeDescription" value={storeDescription} onInputChange={controlChange} placeholderValue="Store Description *" iconText="pi-at" customClasses="field lg:col-6 md:col-12 col-12" />
            <div className="field lg:col-6 md:col-12 col-12">
                <FilterSelect selectableOptions={storeTypes} selectedOption={storeType} onSelectChange={onStoreTypeChangeEvent!} defaultValue={'Store Types *'} elementId="storeType" />
            </div>
            <IconTextInput componentId="emailAddress" value={emailAddress} onInputChange={controlChange} placeholderValue="Email Address" iconText="pi-at" customClasses="field lg:col-6 md:col-12 col-12" />

            <IconTextInput componentId="phoneNumber" value={phoneNumber} onInputChange={controlChange} placeholderValue="Phone Number *" iconText="pi-at" customClasses="field lg:col-6 md:col-12 col-12" />
        </>
    );
};
type TOutletDetails = {
    outletDescription: string;
    outletEmail: string;
    outletAddressLine: string;
    outletAddressCity: string;
    outletAddressState: string;
    outletPhoneNumber: string;
    countries: DropdownOption[];
    outletTypes: DropdownOption[];
    outletType: number;
    outletAddressCountry: string;
    outletCategories: DropdownOption[];
    categoryId: number;
    onSelectChange: (e: DropdownChangeEvent) => void;
    outletCountryChange: (e: DropdownChangeEvent) => void;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectedOutletCountry: string;
    onCategoryChangeEvent?: (e: DropdownChangeEvent) => void;
    onTypeChangeEvent?: (e: DropdownChangeEvent) => void;
};
export const OutletDetails: React.FC<TOutletDetails> = ({
    outletDescription,
    outletEmail,
    outletAddressLine,
    outletAddressCity,
    outletPhoneNumber,
    outletAddressState,
    countries,
    outletAddressCountry,
    outletCategories,
    categoryId,
    onSelectChange,
    onInputChange,
    outletTypes,
    outletType,
    outletCountryChange,
    selectedOutletCountry,
    onCategoryChangeEvent,
    onTypeChangeEvent
}) => {
    return (
        <>
            <IconTextInput componentId="outletDescription" value={outletDescription} onInputChange={onInputChange} placeholderValue="initial Outlet Description *" iconText="pi-building" customClasses="field lg:col-4 md:col-12 col-12" />

            <IconTextInput componentId="outletEmail" value={outletEmail} onInputChange={onInputChange} placeholderValue="Outlet Email Address" iconText="pi-at" customClasses="field lg:col-4 md:col-12 col-12" />

            <IconTextInput componentId="outletPhoneNumber" value={outletPhoneNumber} onInputChange={onInputChange} placeholderValue="Outlet Phone Number *" iconText="pi-phone" inputType="number" customClasses="field lg:col-4 md:col-12 col-12" />

            <IconTextInput componentId="outletAddressLine" value={outletAddressLine} onInputChange={onInputChange} placeholderValue="Outlet Address Line *" iconText="pi-map-marker" customClasses="field lg:col-4 md:col-12 col-12" />

            <IconTextInput componentId="outletAddressCity" value={outletAddressCity} onInputChange={onInputChange} placeholderValue="City *" iconText="pi-map" customClasses="field lg:col-4 md:col-12 col-12" />

            <IconTextInput componentId="outletAddressState" value={outletAddressState} onInputChange={onInputChange} placeholderValue="Outlet State/Province/Region *" iconText="pi-map-marker" customClasses="field lg:col-4 md:col-12 col-12" />
            <div className="field lg:col-4 md:col-12 col-12">
                <FilterSelect selectableOptions={countries} selectedOption={selectedOutletCountry} onSelectChange={outletCountryChange} defaultValue={'Country *'} elementId="outletAddressCountry" />
            </div>
            <div className="field lg:col-4 md:col-12 col-12">
                <FilterSelect selectableOptions={outletTypes} selectedOption={outletType} onSelectChange={onTypeChangeEvent ? onTypeChangeEvent : onSelectChange} defaultValue={'Outlet Type *'} elementId="outletType" />
            </div>
            <div className="field lg:col-4 md:col-12 col-12">
                <FilterSelect selectableOptions={outletCategories} selectedOption={categoryId} onSelectChange={onCategoryChangeEvent ? onCategoryChangeEvent : onSelectChange} defaultValue={'Outlet Category *'} elementId="categoryId" />
            </div>
        </>
    );
};
type TNumberInputWithButtons = {
    numberInputId?: string;
    numberInputName?: string;
    inputValue: number;
    inputValueChange: (e: InputNumberValueChangeEvent) => void;
    disableState?: boolean;
    allowDecimalValues?: boolean;
};
export const NumberInputWithButtons = ({ inputValue, inputValueChange, numberInputName, numberInputId, disableState = false, allowDecimalValues = true }: TNumberInputWithButtons) => {
    return (
        <InputNumber
            inputId="horizontal-buttons"
            id={numberInputId}
            name={numberInputName}
            value={inputValue}
            onValueChange={inputValueChange}
            showButtons
            buttonLayout="horizontal"
            step={1}
            decrementButtonClassName="p-button-danger"
            incrementButtonClassName="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            mode="decimal"
            min={0}
            disabled={disableState}
            minFractionDigits={0}
            maxFractionDigits={allowDecimalValues ? 2 : undefined}
        />
    );
};

export const Loader = () => {
    return (
        <div className="loader-container">
            <ProgressSpinner strokeWidth="4" animationDuration="1.0s" />
        </div>
    );
};
export const SmallLoader = () => {
    return (
        <div className="loader-container-small">
            <ProgressSpinner strokeWidth="2" animationDuration="1.5s" style={{ width: '50px', height: '50px' }} />
        </div>
    );
};
export const TransactionItemTemplate = (item: TTransactionItem) => {
    return (
        <>
            <div className="col-12">
                <div className="grid" id={item.itemId.toString()}>
                    <div className="col-3 lg:col-3 xl:col-3 md:col-3 sm:col-12">
                        {`${item.itemName}`}
                        <i style={{ color: 'red' }}>{parseFloat(item.qtyReturned) > 0 && `(${item.qtyReturned})`}</i>
                        <i style={{ color: 'greenyellow' }}>{parseFloat(item.qtyAdded) > 0 && `[${item.qtyAdded}]`}</i>
                    </div>
                    <div className="col-2 lg:col-2 l:col-2 md:col-2 sm:col-12">
                        <InputText value={item.itemQty} onChange={item.inputValueQtyChange} min={0} disabled={item.editState} className="w-10" onFocus={item.onInputFocus} onBlur={item.onInputFocus} />
                    </div>
                    <div className="col-2 lg:col-2 xl:col-2 md:col-2 sm:col-12">
                        <InputText value={item.itemPrice} onChange={item.inputValuePriceChange} min={0} disabled={item.editState || item.allowSalesPriceEdit===false} className="w-10" onFocus={item.onInputFocus} onBlur={item.onInputFocus} />
                    </div>
                    <div className="col-2 lg:col-2 xl:col-2 md:col-2 sm:col-12" tabIndex={-1}>
                        <span className="justify-content-end">{formatAccountingNumbers(item.itemTotalCost)}</span>
                    </div>
                    <div className="col-3 lg:col-3 xl:col-3 md:col-3 sm:col-12" tabIndex={-1}>
                        <Button icon="pi pi-minus" className="p-button-rounded p-button-danger mr-1" size="small" onClick={item.quantityDecrease} tabIndex={-1} />
                        <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-1" size="small" onClick={item.quantityIncrease} tabIndex={-1} />
                        {!item.editState && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" size="small" onClick={item.itemDelete} tabIndex={-1} />}
                        {item.editState && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success" onClick={item.editLineMeta} tabIndex={-1} />}
                    </div>
                </div>
            </div>
            <Divider />
        </>
    );
};
export const AdjustmentItemTemplate = (item: TAdjustmentItem) => {
    return (
        <>
            <div className="col-12">
                <div className="grid">
                    <div className="col-6 lg:col-6 xl:col-6 md:col-6 sm:col-12" tabIndex={-1}>
                        {item.itemName}
                    </div>
                    <div className="col-3 lg:col-3 xl:col-3 md:col-3 sm:col-12">
                        <InputNumber value={item.itemQty} className="col-12 expanded-control" maxFractionDigits={2} onChange={item.inputValueChange} id={item.itemId.toString()} onBlur={onInputControlFocus} onFocus={onInputControlFocus} />
                    </div>
                    <div className="col-3 lg:col-3 xl:col-3 md:col-3 sm:col-12" data-name={item.itemId}>
                        <Button icon="pi pi-minus" className="p-button-rounded p-button-danger mr-2" onClick={item.quantityDecrease} tabIndex={-1} />
                        <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" onClick={item.quantityIncrease} tabIndex={-1} />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={item.itemDelete} tabIndex={-1} />
                    </div>
                </div>
            </div>
            <Divider />
        </>
    );
};
export const PriceChangeItemsTemplate = (item: TPriceChangeItem) => {
    return (
        <>
            <div className="col-12">
                <div className="grid" id={item.itemId.toString()}>
                    <div className="col-3 lg:col-3 xl:col-3 md:col-3 sm:col-12 underline text-yellow-500 cursor-pointer" onClick={item.viewMore}>{`${item.itemName}`}</div>
                    <div className="col-2 lg:col-2 l:col-2 md:col-2 sm:col-12">
                        <InputText disabled value={item.oldSellingPrice} onChange={() => {}} min={0} className="w-10" onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                    </div>
                    <div className="col-2 lg:col-2 l:col-2 md:col-2 sm:col-12">
                        <InputText value={item.itemSellingPrice} onChange={item.itemSellingPriceChange} min={0} className="w-10" onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                    </div>
                    <div className="col-2 lg:col-2 xl:col-2 md:col-2 sm:col-12">
                        <InputText disabled value={item.oldCostPrice} onChange={() => {}} min={0} className="w-10" onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                    </div>
                    <div className="col-2 lg:col-2 xl:col-2 md:col-2 sm:col-12">
                        <InputText value={item.itemCostPrice} onChange={item.itemCostPriceChange} min={0} className="w-10" onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                    </div>
                    <div className="col-1 lg:col-1 xl:col-1 md:col-1 sm:col-12" tabIndex={-1}>
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-1" size="small" onClick={item.itemDelete} tabIndex={-1} />
                    </div>
                </div>
            </div>
            <Divider />
        </>
    );
};
export const priceMarginTypes: TSelectButtonOption[] = [
    { name: 'Cash', value: 1 },
    { name: 'Percentage', value: 2 }
];
export const discountOptions: TSelectButtonOption[] = [
    { name: 'Cash Discount', value: 1 },
    { name: 'Percentage Discount', value: 2 }
];
export const itemEditOptions: TSelectButtonOption[] = [
    { name: 'New Addition', value: 1 },
    { name: 'Return Item', value: 2 }
];
export const paymentOptions: TSelectButtonOption[] = [
    { name: 'Cash', value: 1,icon:'pi pi-dollar' },
    { name: 'Momo', value: 2,icon:'pi pi-money-bill' },
    { name: 'Cheque', value: 3,icon:'pi pi-credit-card' },
];
type TDiscountProps = {
    discountOP: RefObject<OverlayPanel>;
    selectableOptions: SelectItemOptionsType;
    selectedDiscountOption: number;
    selectButtonChangeEvent: (e: SelectButtonChangeEvent) => void;
    discountValue: number;
    discountValueChangeEvent: (e: InputNumberChangeEvent) => void;
    applyDiscountEvent: () => void;
};
export const DiscountTemplate: React.FC<TDiscountProps> = ({ discountOP, selectedDiscountOption, selectableOptions, selectButtonChangeEvent, discountValueChangeEvent, applyDiscountEvent, discountValue }) => {
    return (
        <OverlayPanel ref={discountOP} className="w-4">
            <div className="card flex justify-content-center">
                <div className="grid">
                    <div className="col-12">
                        <SelectButton value={selectedDiscountOption} onChange={selectButtonChangeEvent} optionLabel="name" options={selectableOptions} />
                    </div>
                    <div className="col-12">
                        <InputNumber onFocus={onInputControlFocus} onBlur={onInputControlFocus} value={discountValue} className="col-12 expanded-control" maxFractionDigits={2} onChange={discountValueChangeEvent} min={0} />
                    </div>
                    <div className="col-12">
                        <Button className="col-12 p-button-success text-center" onClick={applyDiscountEvent}>
                            Apply Discount
                        </Button>
                    </div>
                </div>
            </div>
        </OverlayPanel>
    );
};

interface TListItemsType {
    allItems: any[]; // Adjust 'YourItemType' based on your actual item type
    onCardClick: (item: any) => void;
    colorScheme: string;
    loadImages?: boolean;
}

interface YourItemType {
    itemId: string;
    itemImages: string | { secure_url: string }[];
    itemName: string;
    categoryDescription: string;
    itemPrice: number;
    // ... other properties
}

export const DisplaySalesItemsList: React.FC<TListItemsType> = memo(({ allItems, onCardClick, colorScheme, loadImages = false }) => {
    const parseItemImages = (item: YourItemType) => {
        return typeof item.itemImages === 'string' ? JSON.parse(item.itemImages) : [];
    };

    const renderItemImage = (itemImages: { secure_url: string }[], itemName: string) => {
        return itemImages.length > 0 && <img className="sm:w-16rem xl:w-5rem xl:h-4rem md:w-5rem md:h-4rem shadow-2 block xl:block mx-auto border-round" src={itemImages[0].secure_url} alt={itemName} />;
    };
    const renderCategoryInfo = (item: IOutLetItem & { categoryDescription: string }) => {
        return (
            <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                <div className="text-1xl font-bold text-900">
                    {item.itemName}
                    <span className="text-blue-300 ml-1">({`${item.stockQuantity}`})</span>
                </div>
                <div className="flex align-items-center gap-3">
                    <span className="flex align-items-center gap-2">
                        <i className="pi pi-tag"></i>
                        <span className="font-semibold">{item.categoryDescription}</span>
                    </span>
                </div>
            </div>
        );
    };

    const renderPriceAndCartButton = (item: any) => {
        return (
            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                <span className="text-1xl font-semibold">{formatAccountingNumbers(item.itemPrice)}</span>
                <Button icon="pi pi-shopping-cart" className="p-button-rounded"></Button>
            </div>
        );
    };
    //TODO:ALLOW USERS TO CHOOSE WHETHER TO DISPLAY IMAGES OR NOT
    return (
        <div className="flex flex-wrap justify-content-evenly">
            {allItems.map((item) => (
                <div
                    className={`col-5 lg:col-5 col-12 md:col-5 sm:col-12 xl:col-5 card cursor-pointer
                ${colorScheme === 'light' && 'bg-yellow-100'}`}
                    onClick={() => onCardClick(item)}
                    id={item.itemId}
                    key={item.itemId}
                >
                    <div className="flex flex-column xl:flex-row xl:align-items-start p-2 gap-2">
                        {loadImages && renderItemImage(parseItemImages(item), item.itemName)}
                        <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-2">
                            {renderCategoryInfo(item)}
                            {renderPriceAndCartButton(item)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
});

export const EditingWindow: React.FC<TEditingWindowProps> = ({ OPItemMetaEdit, selectedOption, onSelectButtonChange, selectableOptions, editingQtyValue, editingQtyValueChangEvent, onEditConfirmEvent }) => {
    return (
        <>
            <OverlayPanel ref={OPItemMetaEdit} className="w-20rem">
                <div>
                    <label>Select Edit Option</label>
                    <SelectButton value={selectedOption} onChange={onSelectButtonChange} optionLabel="name" options={selectableOptions} className="bg-yellow-500 w-full mt-2 border-round" />
                    <InputNumber value={editingQtyValue} placeholder="Editing Quantity" className="col-12 expanded-control" maxFractionDigits={2} onChange={editingQtyValueChangEvent} onFocus={onInputControlFocus} onBlur={onInputControlFocus} />
                    <div className="flex-1">
                        <Button className="w-full" onClick={onEditConfirmEvent}>
                            Accept Edit
                        </Button>
                    </div>
                </div>
            </OverlayPanel>
        </>
    );
};
type TDashboardSnippet = {
    title: string;
    variance?: number;
    titleFigure?: number;
    descriptiveImage: 'rate' | 'value' | 'quantity';
};
export const DashboardSnippet = (snippetData: TDashboardSnippet) => {
    return (
        <>
            <div className="col-12 md:col-4">
                <div className={`card widget-overview-box widget-overview-box-${snippetData.variance !== undefined ? (snippetData.variance < 0 ? 1 : snippetData.variance > 0 ? 2 : 3) : 0}`}>
                    <span className="overview-title">{snippetData.title}</span>
                    <div className="flex justify-content-between">
                        <div className="overview-detail flex justify-content-between">
                            <div className="overview-badge flex justify-content-center align-items-center">
                                <i className={`pi ${snippetData.variance !== undefined ? (snippetData.variance < 0 ? 'pi-arrow-down' : snippetData.variance > 0 ? 'pi-arrow-up' : 'pi-minus') : ''}`}></i>
                                <span>{snippetData.variance && Math.abs(snippetData.variance)}</span>
                            </div>
                            <div className="overview-text">{snippetData.titleFigure}</div>
                        </div>
                    </div>
                    <img src={`assets/layout/images/dashboard/${snippetData.descriptiveImage}.svg`} alt={snippetData.descriptiveImage} />
                </div>
            </div>
        </>
    );
};
type TSoldItemsTemplate = {
    soldItems: TTransactionItem[];
    hideDialogAction: () => void;
    visibilityState: boolean;
};
export const TransactionItemsView = ({ soldItems, hideDialogAction, visibilityState }: TSoldItemsTemplate) => {
    const soldItemsTemplate = (item: TTransactionItem) => {
        return (
            <div className="col-12">
                <div className="grid">
                    <div className="col-6 lg:col-6 xl:col-6 md:col-3 sm:col-12">
                        {`${item.itemName}`}
                        <i style={{ color: 'red' }}>{parseFloat(item.qtyReturned) > 0 && `(${item.qtyReturned})`}</i>
                        <i style={{ color: 'greenyellow' }}>{parseFloat(item.qtyAdded) > 0 && `[${item.qtyAdded}]`}</i>
                    </div>
                    <div className="col-2 lg:col-2 l:col-2 md:col-2 sm:col-12">{item.itemQty}</div>
                    <div className="col-2 lg:col-2 l:col-2 md:col-2 sm:col-12">{item.itemPrice}</div>
                    <div className="col-2 lg:col-2 l:col-2 md:col-2 sm:col-12">{item.itemTotalCost}</div>
                </div>
                <Divider />
            </div>
        );
    };
    return (
        <Dialog header={"Items In this Transaction"} onHide={hideDialogAction} visible={visibilityState} dismissableMask className="w-7 fadeinup animation-duration-300" position="top">
            <div className="card">
                <div className="grid">
                    <div className="col-6 font-bold">Item</div>
                    <div className="col-2 font-bold">Qty</div>
                    <div className="col-2 font-bold">Price</div>
                    <div className="col-2 font-bold">Total</div>
                </div>
                <Divider />
                <DataView itemTemplate={soldItemsTemplate} value={soldItems} />
            </div>
        </Dialog>
    );
};
type TDistributionProps={
    backgroundColor:string,
    customerName:string,
    customerTransactionsTotal:number
}
export const CustomerDistributionsLabel=({backgroundColor,customerName,customerTransactionsTotal}:TDistributionProps)=>{
    return <li className="flex justify-content-between align-items-center">
    <div className="flex justify-content-between align-items-center">
        <div className="color" style={{ backgroundColor: backgroundColor}}></div>
        <span>{customerName}</span>
    </div>
    <span>{customerTransactionsTotal}</span>
</li>
}

type SaleSummaryComponentProps={
    todayActivitySummary:TMetaSummary,
    visibilityState:boolean,
    currentUser:string,
    userRole:number,
    todaySummaryQueryDate:string | Date | Date[],
    onDateChhangeEvent:(e:CalendarChangeEvent)=>void,
    currentUserSummaryEvent:()=>void,
    outletSummaryEvent:()=>void,
    currencyType:string,
    summaryRequeryOption:string,
    onDialogHideEvent:()=>void,

}
export const SaleSummaryComponent=({todayActivitySummary,visibilityState,currentUser,userRole,todaySummaryQueryDate,currentUserSummaryEvent,outletSummaryEvent,currencyType,summaryRequeryOption,onDateChhangeEvent,onDialogHideEvent}:SaleSummaryComponentProps)=>{

    return (
        <Dialog onHide={onDialogHideEvent} visible={visibilityState} position="top" className="w-5" header={`TODAY ACTIVITIES SUMMARY - ${currentUser}`}>
            <div className="card">
                <div className="grid">
                    <div className="field lg:col-6 md:col-12 col-12" hidden={userRole !== 3}>
                        <DatePicker maximumDateValue={new Date()} pageTabIndex={0} dateValue={todaySummaryQueryDate} onDateChange={onDateChhangeEvent} labelText="Sales Date" controlId="todaySummaryDate" />
                    </div>
                    <div className="field lg:col-6 md:col-12 col-12" hidden={userRole !== 3}>
                        <Button onClick={summaryRequeryOption === 'User' ? currentUserSummaryEvent : outletSummaryEvent}>Query By Date</Button>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Transactions Today:</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.periodTransactionCost, currencyType)}</div>
                    <Divider />
                    {summaryRequeryOption !== 'User' && (
                        <>
                            <div className="col-6 lg:col-6 md:col-6 sm:col-12">Cost of Sales:</div>
                            <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.costOfSales, currencyType)}</div>
                            <Divider />
                        </>
                    )}

                    {/*<div className="col-6 lg:col-6 md:col-6 sm:col-12">Profit/Loss (Estimated):</div>*/}
                    {/*<div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl text-green-500">*/}
                    {/*    {currencyFormat(state.todayActivitySummary.periodTransactionCost - state.todayActivitySummary.costOfSales, state.userSettings?.currencyType)}*/}
                    {/*</div>*/}
                    {/*<Divider />*/}
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Cash Payments:</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.cashPayments, currencyType)}</div>
                    <Divider />
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Momo Payments:</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.momo, currencyType)}</div>
                    <Divider />
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Cheque Payments:</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.cheque, currencyType)}</div>
                    <Divider />
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Discount Given:</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.discountAmount, currencyType)}</div>
                    <Divider />
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Amount Refunded:</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.refundAmount, currencyType)}</div>
                    <Divider />
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Expected Amount In Hand (MOMO+Cash-(Refund+Discount)):</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.expectedAmountToday, currencyType)}</div>
                    <Divider />
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12">Unpaid Amount [Credit]:</div>
                    <div className="col-6 lg:col-6 md:col-6 sm:col-12 font-bold text-xl">{currencyFormat(todayActivitySummary.unpaidAmount, currencyType)}</div>
                </div>
            </div>
            <span className="font-italic bold text-blue-700">NB: Estimated Profit does not consider expenditure deduction</span>
        </Dialog>
    );
}

export const WifiOff = (props: SVGProps<SVGSVGElement>)=> {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256" {...props}><path fill="currentColor" d="M213.92 210.62a8 8 0 1 1-11.84 10.76l-52-57.15a60 60 0 0 0-57.41 7.24a8 8 0 1 1-9.42-12.93A75.43 75.43 0 0 1 128 144c1.28 0 2.55 0 3.82.1l-26.92-29.61A108 108 0 0 0 61 135.31A8 8 0 0 1 49.73 134A8 8 0 0 1 51 122.77a124.3 124.3 0 0 1 41.71-21.66L69.37 75.4a155.4 155.4 0 0 0-40.29 24A8 8 0 0 1 18.92 87A172 172 0 0 1 58 62.86L42.08 45.38a8 8 0 1 1 11.84-10.76ZM128 192a12 12 0 1 0 12 12a12 12 0 0 0-12-12M237.08 87A172.3 172.3 0 0 0 106 49.4a8 8 0 1 0 2 15.87A158 158 0 0 1 128 64a156.25 156.25 0 0 1 98.92 35.37A8 8 0 0 0 237.08 87M195 135.31a8 8 0 0 0 11.24-1.3a8 8 0 0 0-1.3-11.24a124.25 124.25 0 0 0-51.73-24.2a8 8 0 1 0-3.21 15.67a108.1 108.1 0 0 1 45 21.07"></path></svg>);
}

export const WifiOn=(props: SVGProps<SVGSVGElement>)=> {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" {...props}><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} color="currentColor"><path d="M12 18.5h.012m-3.762-3c2-2 5.5-2 7.5 0m2.75-3c-3.768-3.333-9-3.333-13 0"></path><path d="M2 9.5c6.316-5.333 13.684-5.333 20 0"></path></g></svg>);
}
