import { localDB } from '../offline/db';
import { formatDate, formatToTwoDecimalPlaces, getBaseURL, getDefaultUserSettings, getParsedId } from '../helpers/utils';
import bcrypt from 'bcryptjs';
import fetchAction from '../helpers/axiosConfig';
import { IOutLetItem, TCustomerDistribution, TDashboardMeta, TNetworkServiceResponse, TPaymentInformation, TSaleTransactionItem, TStoreSubscription, TUserSettings } from 'UtilTypes';
import { TPaystackPaymentResponse, TStore } from 'TStore';
import { AxiosResponse } from 'axios';
import { differenceInDays, endOfMonth, endOfYear, isWithinInterval, startOfMonth, startOfYear, subDays } from 'date-fns';
import { TCustomer } from 'TPerson';
const _ = require('lodash');
/** This is a general purpose class created to hold functions that
 * cannot be explicitly given to a part one class and is more likely
 * to be used by many other classes;
 */
class GeneralPurpose {
    /**
     * Get the current user settings that has been saved to the local indexDB.
     * If no settings have been performed, we return the default app settings.
     * @returns - Promise<TUserSettings>
     */
    async getUserSettings() {
        try {
            const localSettings = await localDB.userSettings.get(1);
            return localSettings !== undefined ? localSettings : getDefaultUserSettings();
        } catch (error) {
            throw error;
        }
    }

    async saveUserSettingsLocal(settings: TUserSettings) {
        try {
            return localDB.transaction('rw', localDB.userSettings, async () => {
                return localDB.userSettings.put({ settingsId: 1, ...settings });
            });
        } catch (error: any) {
            throw error;
        }
    }
    /**
     * Verifies a new user login locally without resorting to remote assistance.
     * @param username
     * @param password
     */
    async verifyUserLogin(username: string, password: string) {
        const user = await localDB.users.get({ username });
        if (user) {
            const userState = await bcrypt.compare(password, user.password!);
            if (userState) {
                const storeSubscription: TStoreSubscription[] = await localDB.storeSubscriptions.toArray();
                const subscriptionDetails = storeSubscription[0];
                const daysLeft = differenceInDays(new Date(subscriptionDetails.dateExpiring), new Date());

                return { ...user, daysLeftToExpiry: daysLeft };
            }
        }
        return undefined;
    }

    /**
     * Get Stores and Outlets that have been saved to the local indexDB;
     */
    async getLocalStoresAndOutlets() {
        try {
            const stores = await localDB.appStores.toArray();
            const outlets = await localDB.outlets.toArray();

            return { stores, outlets };
        } catch (error) {
            throw error;
        }
    }
    async getLocalItems(): Promise<IOutLetItem[]> {
        try {
            return localDB.outletItems.toArray();
        } catch (error: any) {
            throw error;
        }
    }
    async saveReceivedPINCode() {
        try {
            // await localDB.userSettings.update()
        } catch (error) {
            throw error;
        }
    }
    async copyStockToLocationsOnline({ fromLocation, toLocation }: { fromLocation: number; toLocation: number }) {
        const copyResults = await fetchAction<TNetworkServiceResponse<any>>('post', `${getBaseURL()}/items/copy_stock`, { fromLocation, toLocation });
        return copyResults.data.status;
    }

    async copyStockToLocationOffline({ fromLocation, toLocation }: { fromLocation: number; toLocation: number }) {
        return localDB.transaction('rw', localDB.outletItems, async () => {
            if (fromLocation === toLocation) {
                return 2;
            }

            if ((await localDB.outletItems.where({ outletId: fromLocation }).count()) === 0) {
                return 4;
            }
            if ((await localDB.outletItems.where({ outletId: toLocation }).count()) > 0) {
                return 3;
            }
            const fromLocationItems = await localDB.outletItems.where({ outletId: fromLocation }).toArray();
            const fixedItems: IOutLetItem[] = fromLocationItems.map((item) => {
                const { itemId, outletId, stockQuantity, ...itemWithOutId } = item; //removes the initial listed property from object.

                return { ...itemWithOutId, outletId: toLocation, stockQuantity: '0' };
            });
            await localDB.outletItems.bulkAdd(fixedItems);
            return 1;
        });
    }
    async copyStockWithQuantityToRemote(stockItems:IOutLetItem[] ) {
        const copyResults = await fetchAction<TNetworkServiceResponse<any>>('post', `${getBaseURL()}/items/copy_stock_with_quantities`, { stockItems });
        return copyResults.data.status;
    }
    /**
     * Retrieve the store currently being in use by user.
     * @param storeId
     * @returns
     */
    async getSubscriptionDetails(storeId: number) {
        const copyResults = await fetchAction<TStore[]>('post', `${getBaseURL()}/stores/get_store_data`, { requestBody: { storeId: getParsedId(storeId) } });
        return copyResults.data[0]; // we know this will return only the store belonging to the passed storeId;
    }

    async initiatePaystackPayment(paymentInfo: TPaymentInformation): Promise<AxiosResponse<TPaystackPaymentResponse>> {
        return await fetchAction('post', `${getBaseURL()}/registration/pay_with_stack`, { paymentInformation: paymentInfo });
    }

    async verifyPaystackPayment(transactionRef: string) {
        const verificationResponse = await fetchAction<any>('post', `${getBaseURL()}/paystack/verify`, { transactionRef });
        console.log(verificationResponse);
    }
    async verifyOTPNumber(transactionRef: string, otp: string): Promise<AxiosResponse<TPaystackPaymentResponse>> {
        return await fetchAction<any>('post', `${getBaseURL()}/paystack/verify_otp`, { otpData: { transactionRef, otp } });
    }
    /**
     * This is a duplicated function from settings arena to make us not redeclare a new object in the sales transactions.
     * @param settings a
     * @returns
     */
    async updateAppMode(appMode: string) {
        try {
            return localDB.transaction('rw', localDB.userSettings, async () => {
                return await localDB.userSettings.update(1, { appMode: appMode });
            });
        } catch (error: any) {
            throw error;
        }
    }

    async checkShopsState() {
        return await fetchAction<any>('post', `${getBaseURL()}/stores/get_stores_state`, {});
    }
    async getDashboardMetaLocal(outletId: number, currentDate: string) {
        try {
            const yearStart = startOfYear(new Date(currentDate));
            const yearEnd = endOfYear(new Date(currentDate));
            const monthStart = startOfMonth(new Date(currentDate));
            const monthEnd = endOfMonth(new Date(currentDate));

            return localDB.transaction('rw', localDB.salesTransactions, localDB.expenditures, localDB.customers, async () => {
                const currentMonthSales = await localDB.salesTransactions
                    .where('outletId')
                    .equals(outletId)
                    .filter((transaction) => isWithinInterval(new Date(transaction.transactionDate as Date), { start: monthStart, end: monthEnd }))
                    .toArray();
                const expenditures = await localDB.expenditures
                    .where('outletId')
                    .equals(outletId)
                    .filter((expenditure) => isWithinInterval(new Date(expenditure.expenditureDate as Date), { start: yearStart, end: yearEnd }))
                    .toArray();

                const todaySales = (await localDB.salesTransactions.where('transactionDate').equals(formatDate(new Date())).toArray()).reduce((previousValue: number, currentValue) => {
                    return previousValue + (currentValue.transactionCost - currentValue.refundAmount);
                }, 0);
                const yesterdaySales = (
                    await localDB.salesTransactions
                        .where('transactionDate')
                        .equals(formatDate(subDays(new Date(), 1)))
                        .toArray()
                ).reduce((previousValue: number, currentValue) => {
                    return previousValue + (currentValue.transactionCost - currentValue.refundAmount);
                }, 0);
                const todayExpenditures = (await localDB.expenditures.where('expenditureDate').equals(formatDate(new Date())).toArray()).reduce((previousValue: number, currentValue) => {
                    return previousValue + currentValue.expenditureAmount;
                }, 0);
                const yesterdayExpenditures = (
                    await localDB.expenditures
                        .where('expenditureDate')
                        .equals(formatDate(subDays(new Date(), 1)))
                        .toArray()
                ).reduce((previousValue: number, currentValue) => {
                    return previousValue + currentValue.expenditureAmount;
                }, 0);

                const salesVariance = formatToTwoDecimalPlaces(((todaySales - yesterdaySales) / yesterdaySales) * 100);
                const expenditureVariance = formatToTwoDecimalPlaces(((todayExpenditures - yesterdayExpenditures) / yesterdayExpenditures) * 100);
                const todayRevenue = todaySales - todayExpenditures;
                const yesterdayRevenue = yesterdaySales - yesterdayExpenditures;
                const revenueVariance = formatToTwoDecimalPlaces(((todayRevenue - yesterdayRevenue) / yesterdayRevenue) * 100);
                const dashBoardMeta: TDashboardMeta = {
                    todaySales,
                    yesterdaySales,
                    todayExpenditures,
                    yesterdayExpenditures,
                    salesVariance: isNaN(salesVariance) ? 0 : salesVariance,
                    expenditureVariance: isNaN(expenditureVariance) ? 0 : expenditureVariance,
                    todayRevenue,
                    yesterdayRevenue,
                    revenueVariance: isNaN(revenueVariance) ? 0 : revenueVariance
                };
                const customers = await localDB.customers.where('outletId').equals(outletId).toArray();
                const customerDistributions: TCustomerDistribution[] = this.getCustomersTransactions(currentMonthSales, customers);
                return { dashBoardMeta, salesTransactions: currentMonthSales, expenditures, customerDistributions };
            });
        } catch (error) {
            throw error;
        }
    }

    getCustomersTransactions(transactions: TSaleTransactionItem[], customers: TCustomer[]) {
        const transactionsByCustomer = _.groupBy(transactions, 'customerId');

        const result = _.map(customers, (customer: TCustomer) => {
            const customerTransactions = transactionsByCustomer[customer.customerId!] || [];
            const totalTransaction = _.sumBy(customerTransactions, 'transactionCost');

            return {
                customerId: customer.customerId,
                fullName: customer.fullName,
                totalTransaction
            };
        });
        return result;
    }

}
export default GeneralPurpose;
