import { IconTextInput } from '../../helpers/utilComponents';
import React from 'react';
import { usePasswordContext } from '../ChangePassword';
import { Button } from 'primereact/button';

const ConfirmPinNumber=()=>{
    const {componentState,stateValueChange,confirmPinCode}=usePasswordContext();
    return (
        <>
            <div className="flex justify-content-center flex-wrap">
                <div className="grid">
                    <IconTextInput value={componentState!.receivedPINCode!} onInputChange={stateValueChange!} 
                                   placeholderValue="6 Digit PIN CODE" iconText="pi pi-pencil" componentId="receivedPINCode"
                                   customClasses="lg:col-12 md:col-12 col-12" />
                    <Button onClick={confirmPinCode} >Confirm Pin Code</Button>
                </div>
            </div>
        </>
    )
}
export default ConfirmPinNumber;