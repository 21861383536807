import Dexie, { Table } from 'dexie';
import 'dexie-export-import';
import { TCustomer, TPerson, TSupplier } from '../types/TPerson';
import { TStorePage } from '../helpers/customTypesUtils';
import { exportDB} from 'dexie-export-import';
import { ExportProgress } from 'dexie-export-import/dist/export';
import {
    IGiftCards,
    IOutlet,
    IOutLetItem,
    IPromotionalDiscounts, ISaleTransactions,
    ITaxes,
    ITermsConditions, TAdjustmentTransaction,
    TCategory, TCustomerPayment, TCustomerTypes,
    TDiscountTypes, TExpenditure, TExpenditureCategory, TItemBrand,
    TItemSubcategory, TPreviousQueuedData, TPriceChangeTransaction, TProformaInvoice, TPurchaseTransactionItem, TQueuedTable,
    TrackTable,
    TRelatedItemCategory, TSaleTransactionItem, TStoreSubscription, TSupplierPayment, TTransferTransaction, TUserRoles, TUserSettings
} from '../types/UtilTypes';
import { TStoreOutletCategory, TStoreType } from '../types/TStore';
import { formatDate } from '@fullcalendar/core';


// Step 1: Backup Data

export class DB extends Dexie {
    users!: Table<TPerson>;
    customers!: Table<TCustomer>;
    suppliers!: Table<TSupplier>;
    appStores!: Table<TStorePage>;
    outlets!: Table<IOutlet>;
    outletItems!: Table<IOutLetItem>;
    itemCategories!:Table<TCategory>;
    itemRelatedCategories!:Table<TRelatedItemCategory>;
    itemSubCategories!:Table<TItemSubcategory>;
    promotionalDiscounts!:Table<IPromotionalDiscounts>;
    taxes!:Table<ITaxes>;
    giftCards!:Table<IGiftCards>;
    termsConditions!:Table<ITermsConditions>;
    expenditures!:Table<TExpenditure>;
    salesTransactions!:Table<TSaleTransactionItem>;
    purchaseTransactions!:Table<TPurchaseTransactionItem>;
    adjustmentTransactions!:Table<TAdjustmentTransaction>;
    accountsPayments!:Table<TCustomerPayment>;
    supplierPayments!:Table<TSupplierPayment>;
    storeTypes!:Table<TStoreType>;
    storeOutletCategories!:Table<TStoreOutletCategory>;
    userRoles!:Table<TUserRoles>;
    expenseCategories!:Table<TExpenditureCategory>;
    customerTypes!:Table<TCustomerTypes>;
    customerCategories!:Table<TCategory>;
    itemBrands!:Table<TItemBrand>;
    queuedTables!:Table<TQueuedTable>;
    userSettings!:Table<TUserSettings>;
    proformaInvoice!:Table<TProformaInvoice>;
    transfers!:Table<TTransferTransaction>;
    priceChangeHistory!:Table<TPriceChangeTransaction>;
    trackTable!:Table<TrackTable>;
    currentUser!:Table<TPerson>;
    storeSubscriptions!:Table<TStoreSubscription>;
    previousQueuedData!:Table<TPreviousQueuedData>
    constructor() {
        super('easyAppLocal');
        this.version(13).stores({
            users: '++personId, username, fullName,userRole,storeId',
            customers: '++customerId,username,fullName,outletId,storeId',
            suppliers: '++supplierId,username,fullName,outletId,storeId',
            appStores: '++storeId,storeDescription',
            outlets: '++outletId,outletDescription,storeDescription,parentStore',
            outletItems: '++itemId,itemName,expiryDate,[outletId+itemDetailsId],storeId',//using compound index
            itemCategories:'++categoryId,categoryDescription',
            itemRelatedCategories:'++relatedCategoryId,relatedCategoryDescription,outletId',
            itemSubCategories:'++itemSubCategoryId,subCategoryDescription',
            promotionalDiscounts:'++discountId,discountDescription,outletId,storeId',
            taxes:'++taxId,taxDescription,outletId,storeId',
            giftCards:'++giftCardId,cardNumber,outletId,storeId',
            termsConditions:'++termsConditionsId,termConditionsDescription,outletId,storeId',
            expenditures:'++expenditureId,description,expenditureDate,expenditureCategory,outletId,storeId',
            salesTransactions:'++transactionId,transactionDate,customerId,invoiceNumber,storeId,transactionItems.itemId,outletId',
            purchaseTransactions:'++transactionId,transactionDate,supplierId,outletId',
            adjustmentTransactions:'++adjustmentId,adjustmentDate,adjustmentType,outletId',
            accountsPayments:'++paymentId,customerId,paymentDate,outletId',
            supplierPayments:'++supplierPaymentId,supplierId,paymentDate,outletId',
            storeTypes:'++storeTypeId,storeTypeDescription',
            storeOutletCategories:'++outletCategoryId',
            userRoles:'++roleId',
            expenseCategories:'++categoryId',
            customerTypes:'++customerTypeId',
            customerCategories:'++categoryId',
            itemBrands:'++brandId',
            queuedTables:'++tableId,tablesDesc',
            userSettings:'++settingsId',
            proformaInvoice:'++transactionId,transactionDate,customerId,outletId',
            transfers:'++transferId,transferDate,receivingOutletId,transferringOutletId',
            priceChangeHistory:'++transactionId,[outletId+storeId],invoiceNumber',
            trackTable:'++trackId',
            currentUser:'personId,username',
            storeSubscriptions:'storeSubscriptionId',
            previousQueuedData:'++previousQueuedDataId'
        });
    }
}

export const localDB = new DB();

export const exportDexieDB = async () => {
    try {
        // Export the database
        const blob = await exportDB(localDB);
        const serializedData = await blob.text();

        // Create a Blob with the serialized data
        const exportBlob = new Blob([serializedData], { type: 'application/json' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(exportBlob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = `localDatabase.json_${formatDate(new Date())}`; // Set the filename
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error: any) {
        throw new Error(error.message);
    }
};

// Function to import and restore the database from a file
export async function importAndRestoreDatabase(file: File) {
    try {
        // Read the contents of the file
        const serializedData = await readFile(file);

        // Create a Blob with the serialized data
        const importBlob = new Blob([serializedData], { type: 'application/json' });

        // await importInto(localDB, importBlob);
        await localDB.import(importBlob, {
            clearTablesBeforeImport: true,
            noTransaction: false,
            overwriteValues: true,
            progressCallback: (e: ExportProgress) => {
                console.log(e.completedRows, ' completed Row');
                // console.log(e.totalRows);
                // console.log(e.totalTables);
                return e.done;
            }
        });
    } catch (error: any) {
        throw new Error(error.message);
    }
}

/**
 * Method clears local index tables of all data.
 * User should have at least backed up the database or should be
 * completely aware of his actions since cleared tables cannot be restored.
 @returns {Promise<void>} - Promise resolve with void since no
  further data is returned by method.
 */
export const clearOfflineDatabase = async (): Promise<void> => {
    await localDB.delete();
    return;
    localDB
        .transaction('rw', localDB.tables, async () => {
            localDB.tables.forEach((table) => {
                if(table.name!=='userSettings'){
                    table.clear();
                }
                console.log(`Data cleared from ${table.name}`);
            });
        })
        .then(async () => {
            await localDB.queuedTables.put({
                tableId: 1,
                taxes: [],
                adjustments: [],
                customers: [],
                discounts: [],
                gifts: [],
                expenditures: [],
                itemCategories: [],
                outlets: [],
                relatedCategories: [],
                outletItems: [],
                purchases: [],
                sales: [],
                stores: [],
                subCategories: [],
                suppliers: [],
                terms: [],
                users: [],
                accountsPayments:[],
                supplierPayments:[],
                proformaInvoices:[],
                transfers:[],
                priceChangeHistory:[],
                barcodes:[],
                expenseCategories:[]
            });
        })
        .catch((error) => {
            console.error('Error clearing data: ', error);
        });
};

// Function to read the contents of a file
function readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result) {
                resolve(reader.result.toString());
            } else {
                reject(new Error('Failed to read file'));
            }
        };
        reader.onerror = () => {
            reject(reader.error);
        };
        reader.readAsText(file);
    });
}