import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { GlobalContext } from './helpers/GlobeManagement';
import Dashboard from './components/Dashboard';
import { TPerson } from 'TPerson';
import { Login } from './pages/Login';
import AdminDefiners from './components/registration/AdminDefiners';
import { Loader } from './components/sharedComponents/SharedComponents';
import ChangePassword from './pages/ChangePassword';
import ErrorBoundary from './components/sharedComponents/ErrorBoundary';
import { getBaseURL, getParsedId, useUserLocalStorage } from './helpers/utils';

import io, { Socket } from 'socket.io-client';


const socketio=io(getBaseURL());

const LazyHomePage = lazy(() => import('../src/components/Dashboard'));
const LazyUsers = lazy(() => import('../src/components/persons/Users'));
const LazyCustomers = lazy(() => import('../src/components/persons/Customers'));
const LazySuppliers = lazy(() => import('../src/components/persons/Supplier'));
const LazyStores = lazy(() => import('../src/components/company/Stores'));
const LazyOutlets = lazy(() => import('../src/components/company/Outlets'));
const LazyRegistration = lazy(() => import('../src/components/registration/NewRegistration'));
const LazyTermsConditions = lazy(() => import('../src/components/company/TermsConditions'));
const LazyDiscounts = lazy(() => import('../src/components/company/PromotionalDiscount'));
const LazyTaxes = lazy(() => import('../src/components/company/Taxes'));
const LazyGiftCards = lazy(() => import('../src/components/company/GiftCards'));
const LazyOutletItems = lazy(() => import('../src/components/company/OutletItem'));
const LazySalesTransactions = lazy(() => import('../src/components/company/SaleTransactions'));
const LazyPurchase = lazy(() => import('../src/components/company/Purchases'));
const LazyAdjustments = lazy(() => import('../src/components/company/Adjustments'));
const LazyUserSettings = lazy(() => import('../src/pages/UserSettings'));
const LazyExpenditures = lazy(() => import('../src/components/company/Expenditures'));
const LazyReporting = lazy(() => import('../src/components/Reporting'));
const LazySupervision = lazy(() => import('../src/components/company/SuperVision'));
const LazyRestaurants=lazy(()=>import('../src/components/company/Restaurants'));
const LazyTransfers=lazy(()=>import('../src/components/company/Transfers'));
const LazyChangeItemPrices=lazy(()=>import('../src/components/company/ChangeItemPrice'));
const LazyChoicePage=lazy(()=>import('../src/components/company/OutletChoice'));
const LazyUserSyncPage=lazy(()=>import('../src/components/company/UserSync'));
const LazyBarcodePage=lazy(()=>import('../src/components/company/Barcode'));
const LazySubscriptionPayment=lazy(()=>import('../src/components/registration/SubscriptionPayment'));
const LazySyncing=lazy(()=>import('../src/components/company/Synching'))
const App = (props: any) => {
    const appModeState = localStorage.getItem('appState');
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const [currentUser, setCurrentUser] = useState<TPerson | undefined>(/* initial value */);
    const [appMode, setAppMode] = useState<string>(appModeState !== null ? appModeState : 'onLine');
    const [personData] = useUserLocalStorage();
    const [socket,setSocket]=useState<Socket>(socketio);
    PrimeReact.ripple = true;


    const menu = getParsedId(personData?.userRole as number)>2 ? [
        {
            label: 'Favorites',
            icon: 'pi pi-home',
            items: [{ label: 'Dashboard', icon: 'pi pi-home', to: '/' }]
        },
        {
            label: 'Persons',
            icon: 'pi pi-user',
            items: [
                { label: 'Users', icon: 'pi pi-user', to: '/users' },
                { label: 'Customers', icon: 'pi pi-users', to: '/customers' },
                { label: 'Suppliers', icon: 'pi pi-users', to: '/suppliers' },
                { label: 'Password', icon: 'pi pi-lock', to: '/passwords' }
            ]
        },

        {
            label: 'Store',
            icon: 'pi pi-briefcase',
            items: [
                { label: 'Stores', icon: 'pi pi-building', to: '/stores' },
                { label: 'Outlets', icon: 'pi pi-calendar-plus', to: '/outlets' },
                { label: 'Items', icon: 'pi pi-plus-circle', to: '/items' },
                { label: 'Pricing', icon: 'pi pi-dollar', to: '/pricing' },
                { label: 'Location Change', icon: 'pi pi-building', to: '/choice' },
                { label: 'Setup Barcodes', icon: 'pi pi-qrcode', to: '/barcodes' },
                { label: 'Subscription Payment', icon: 'pi pi-dollar', to: '/subscription' },
            ]
        },
        {
            label: 'Offers',
            icon: 'pi pi-dollar',
            items: [
                { label: 'Discounts', icon: 'pi pi-dollar', to: '/discounts' },
                { label: 'Taxes', icon: 'pi pi-percentage', to: '/taxes' },
                { label: 'Gifts', icon: 'pi pi-gift', to: '/gifts' },
                { label: 'Terms', icon: 'pi pi-book', to: '/terms' },
                { label: 'Expenditures', icon: 'pi pi-money-bill', to: '/expenditure' }
            ]
        },

        {
            label: 'Transactions',
            icon: 'pi pi-shopping-cart',
            items: [
                {
                    label: 'Sales',
                    icon: 'pi pi-cart-plus',
                    to: '/sales'
                },
                {
                    label: 'Purchases',
                    icon: 'pi pi-shopping-cart',
                    to: '/purchases'
                },
                {
                    label: 'Adjustments',
                    icon: 'pi pi-cart-plus',
                    to: '/adjustments'
                },
                {
                    label: 'Transfers',
                    icon: 'pi pi-cart-plus',
                    to: '/transfers'
                }
            ]
        },

        {
            label: 'Reports',
            icon: 'pi pi-file',
            items: [
                {
                    label: 'Reports',
                    icon: 'pi pi-file',
                    to: '/reports'
                }
            ]
        },
        {
            label: 'Settings',
            icon: 'pi pi-cog',
            items: [
                {
                    label: 'App Settings',
                    icon: 'pi pi-cog',
                    to: '/settings'
                }
            ]
        },
        {
            label: 'Restaurants',
            icon: 'pi pi-ticket',
            items: [
                {
                    label: 'Restaurant',
                    icon: 'pi pi-cart-plus',
                    to: '/restaurants'
                },
                {
                    label: 'Supervise',
                    icon: 'pi pi-list',
                    to: '/supervision'
                }
            ]
        },
    ]:getParsedId(personData?.userRole as number)===1?[
        {
            label: 'Store',
            icon: 'pi pi-briefcase',
            items: [
                { label: 'Location Change', icon: 'pi pi-building', to: '/choice' }
            ]
        },
        {
            label: 'Transactions',
            icon: 'pi pi-shopping-cart',
            items: [
                {
                    label: 'Sales',
                    icon: 'pi pi-cart-plus',
                    to: '/sales'
                }
            ]
        },
    ]:[
        { label: 'Admin', icon: 'pi pi-box', items:[
                {
                    label: 'Purchases',
                    icon: 'pi pi-shopping-cart',
                    to: '/purchases'
                },
                { label: 'Items', icon: 'pi pi-plus-circle', to: '/items' },
            ] },

    ];

    const routes = [
        { parent: 'Pages', label: 'Crud' },
        { parent: 'Pages', label: 'Calendar' },
        { parent: 'Pages', label: 'Timeline' },
        { parent: 'Pages', label: 'Invoice' },
        { parent: 'Pages', label: 'Login' },
        { parent: 'Pages', label: 'Help' },
        { parent: 'Pages', label: 'Empty' },
        { parent: 'Pages', label: 'Access' },
        { parent: 'Store', label: 'Items' },
        { parent: 'Store', label: 'Pricing' },
        { parent: 'Start', label: 'NewUser' },
        { parent: 'Person', label: 'Customers' },
        { parent: 'Person', label: 'Suppliers' },
        { parent: 'Start', label: 'Registration' },
        { parent: 'Store', label: 'Stores' },
        { parent: 'Store', label: 'Outlets' },
        { parent: 'Transactions', label: 'Sales' },
        { parent: 'Transactions', label: 'Purchases' },
        { parent: 'Transactions', label: 'Adjustments' },
        { parent: 'User', label: 'Settings' },
        { parent: 'Person', label: 'Users' },
        { parent: 'Offers', label: 'Expenditure' },
        { parent: 'Company', label: 'Reports' },
        { parent: 'Offers', label: 'Discounts' },
        { parent: 'Offers', label: 'Taxes' },
        { parent: 'Offers', label: 'Terms' },
        { parent: 'Offers', label: 'Gifts' },
        { parent: 'Person', label: 'Passwords' },
        { parent: 'Restaurant', label: 'Restaurants' },
        { parent: 'Transactions', label: 'Transfers' },
        { parent: 'Barcodes', label: 'barcodes' },
        { parent: 'Payments', label: 'subscription' },
    ];

    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();

    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    // useEffect(() => {
    //
    //     const handleBeforeUnload = (e:any) => {
    //         e.preventDefault();
    //         e.returnValue = ''; // Chrome requires this
    //     };
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode: any) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active: any) => {
        setRightMenuActive(active);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event: any) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <ErrorBoundary>
            <GlobalContext.Provider
                value={{
                    currentUser: currentUser,
                    setCurrentUser,
                    userMenuOption: menuMode,
                    changeMenuMode: setMenuMode,
                    currentAppMode: appMode,
                    changeAppMode: setAppMode,
                    daysLeftToExpiry:currentUser?.daysLeftToExpiry!,
                    socket
                }}
            >
                <div className={layoutClassName} onClick={onDocumentClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                    <div className="layout-main">
                        <AppTopbar
                            items={menu}
                            menuMode={menuMode}
                            colorScheme={props.colorScheme}
                            menuActive={menuActive}
                            topbarMenuActive={topbarMenuActive}
                            activeInlineProfile={activeInlineProfile}
                            onTopbarItemClick={onTopbarItemClick}
                            onMenuButtonClick={onMenuButtonClick}
                            onSidebarMouseOver={onSidebarMouseOver}
                            onSidebarMouseLeave={onSidebarMouseLeave}
                            onToggleMenu={onToggleMenu}
                            onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                            onMenuClick={onMenuClick}
                            onMenuItemClick={onMenuItemClick}
                            onRootMenuItemClick={onRootMenuItemClick}
                            resetActiveIndex={resetActiveIndex}
                        />
                        <AppMenu
                            model={menu}
                            onRootMenuItemClick={onRootMenuItemClick}
                            onMenuItemClick={onMenuItemClick}
                            onToggleMenu={onToggleMenu}
                            onMenuClick={onMenuClick}
                            menuMode={menuMode}
                            colorScheme={props.colorScheme}
                            menuActive={menuActive}
                            sidebarActive={sidebarActive}
                            sidebarStatic={sidebarStatic}
                            pinActive={pinActive}
                            onSidebarMouseLeave={onSidebarMouseLeave}
                            onSidebarMouseOver={onSidebarMouseOver}
                            activeInlineProfile={activeInlineProfile}
                            onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                            resetActiveIndex={resetActiveIndex}
                        />
                        <AppBreadcrumb
                            routes={routes}
                            onMenuButtonClick={onMenuButtonClick}
                            menuMode={menuMode}
                            onRightMenuButtonClick={onRightMenuButtonClick}
                            onInputClick={onInputClick}
                            searchActive={searchActive}
                            breadcrumbClick={breadcrumbClick}
                            currentUser={currentUser}
                        />
                        <div className="layout-main-content">
                            <Suspense fallback={<Loader />}>
                                <Routes>
                                    <Route path="/" element={<LazyHomePage />} />
                                    <Route path="/users" element={<LazyUsers />} />
                                    <Route path="/customers" element={<LazyCustomers />} />
                                    <Route path="/suppliers" element={<LazySuppliers />} />
                                    <Route path="/stores" element={<LazyStores />} />
                                    <Route path="/outlets" element={<LazyOutlets />} />
                                    <Route path="/registration" element={<LazyRegistration />} />
                                    <Route path="/terms" element={<LazyTermsConditions />} />
                                    <Route path="/discounts" element={<LazyDiscounts />} />
                                    <Route path="/taxes" element={<LazyTaxes />} />
                                    <Route path="/gifts" element={<LazyGiftCards />} />
                                    <Route path="/items" element={<LazyOutletItems />} />
                                    <Route path="/sales" element={<LazySalesTransactions />} />
                                    <Route path="/purchases" element={<LazyPurchase />} />
                                    <Route path="/adjustments" element={<LazyAdjustments />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/settings" element={<LazyUserSettings />} />
                                    <Route path="/expenditure" element={<LazyExpenditures />} />
                                    <Route path="/reports" element={<LazyReporting />} />
                                    <Route path="/admin_panel" element={<AdminDefiners />} />
                                    <Route path="/passwords" element={<ChangePassword />} />
                                    <Route path="/supervision" element={<LazySupervision />} />
                                    <Route path="/restaurants" element={<LazyRestaurants />} />
                                    <Route path="/transfers" element={<LazyTransfers />} />
                                    <Route path="/pricing" element={<LazyChangeItemPrices />} />
                                    <Route path="/choice" element={<LazyChoicePage />} />
                                    <Route path="/sync" element={<LazyUserSyncPage />} />
                                    <Route path="/barcodes" element={<LazyBarcodePage />} />
                                    <Route path="/subscription" element={<LazySubscriptionPayment />} />
                                    <Route path="/syncs" element={<LazySyncing />} />
                                    <Route path="*" element={<Dashboard />} />
                                </Routes>
                            </Suspense>
                        </div>
                        <AppFooter colorScheme={props.colorScheme} />
                    </div>
                    <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />
                    <AppConfig
                        configActive={configActive}
                        onConfigButtonClick={onConfigButtonClick}
                        onConfigClick={onConfigClick}
                        menuMode={menuMode}
                        changeMenuMode={onMenuModeChange}
                        colorScheme={props.colorScheme}
                        changeColorScheme={props.onColorSchemeChange}
                        theme={props.theme}
                        changeTheme={props.onMenuThemeChange}
                        componentTheme={props.componentTheme}
                        changeComponentTheme={props.onComponentThemeChange}
                        ripple={ripple}
                        onRippleChange={onRippleChange}
                    />
                </div>
            </GlobalContext.Provider>
        </ErrorBoundary>
    );
};

export default App;
