import { Steps } from 'primereact/steps';
import { useNavigate } from 'react-router-dom';
import React, { createContext, useContext, useRef, useState } from 'react';
import { PasswordChange } from './PasswordChangeComponent';
import ConfirmPhoneNumber from './PasswordChangeComponents/ConfirmPhoneNumber';
import ConfirmPinNumber from './PasswordChangeComponents/ConfirmPinNumber';
import { Person } from '../classes/Person';
import { Loader } from '../components/sharedComponents/SharedComponents';
import { GeneralPageProps } from '../helpers/utilComponents';
import { displayMessage, pageDataValidation, useUserLocalStorage } from '../helpers/utils';
import Joi from 'joi';
import { TPerson } from 'TPerson';

type TPasswordState = {
    activeIndex?: number;
    phoneNumber?: string;
    email?: string;
    password?: string;
    repeatPassword?: string;
    stateValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    componentState?: TPasswordState;
    requestPinCode?:(e:React.MouseEvent<HTMLButtonElement>)=>void;
    confirmPinCode?:(e:React.MouseEvent<HTMLButtonElement>)=>void;
    resetPassword?:(e:React.MouseEvent<HTMLButtonElement>)=>void;
    isLoading?:boolean,
    userPIN?:string,
    receivedPINCode?:string
};
const validatePasswordReset=Joi.object({
    password: Joi.string()
        .pattern(new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[@#$%^&*(),.?":{}|<>]).*$'))
        .messages({
            'string.empty': 'Password is required for user to continue',
            'string.pattern.base': 'Password must contain at least one uppercase letter, one number, and one special character.'
        }),
    repeatedPassword: Joi.string()
        .equal(Joi.ref('password'))
        .required()
        .messages({
            'string.empty': 'Repeat entered password',
            'any.only': 'Password and repeated password must be equal'
        })
});
const validatePinRequest = Joi.object({
    phoneNumber: Joi.string()
        .length(10) // Assuming the phone number length is exactly 10 digits
        .regex(/^0\d{9}$/) // Ensures the phone number starts with '0' followed by exactly 9 digits
        .messages({
            'string.empty': 'Phone number is required.',
            'string.length': 'Phone number must be exactly 10 digits.',
            'string.pattern.base': 'Phone number must start with 0.'
        })
});
const PasswordContext = createContext<TPasswordState>({});
export const usePasswordContext = () => useContext(PasswordContext);
const user=new Person();
const ChangePassword = () => {
    const toastRef = useRef(null);
    const navigateOut=useNavigate();
    const [passResetState, setPasswordResetState] = useState<TPasswordState>({
        activeIndex: 0,
        phoneNumber: '',
        email: '',
        receivedPINCode: '',
        password: '',
        repeatPassword: '',
        isLoading:false
    });
    const [personData] = useUserLocalStorage();
    const wizardItems = [{ label: 'Personal' }, { label: 'Confirm' }, { label: 'Change Password' }, { label: 'Completed' }];

    const stateValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordResetState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const setstateValues=(stateValues:Partial<TPasswordState>)=>{
        setPasswordResetState((prevState)=>{
           return {...prevState,...stateValues}
        });
    }
    const onPinCodeRequest=async ()=>{
        if(!pageDataValidation(validatePinRequest,{phoneNumber:passResetState.phoneNumber},toastRef)){
            return;
        }
        try{
            setstateValues({isLoading:true});
            const pinCodeResponse=await user.requestResetPin(passResetState.phoneNumber!);
            console.log(pinCodeResponse);
            setstateValues({userPIN:pinCodeResponse.data.uniqueCode});
            localStorage.setItem('uniqueCode',pinCodeResponse.data.uniqueCode.toString())
        }catch(error:any){
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        }finally {
            setstateValues({isLoading:false});
        }

    }
    const onConfirmPIN=()=>{
        const receivedPINCode=localStorage.getItem('uniqueCode');
        if(receivedPINCode!==null){
            if(passResetState.receivedPINCode!==receivedPINCode){
                console.log(passResetState.receivedPINCode);
                displayMessage({
                    header: 'Error',
                    message: 'An error occurred!',
                    infoType: 'error',
                    toastComponent: toastRef,
                    life: 3000
                });
                return
            }
            setstateValues({activeIndex:2})
        }
    }
    const onResetPassword=async ()=>{
        if(!pageDataValidation<Partial<TPerson>>(validatePasswordReset, {password:passResetState.password,repeatedPassword:passResetState.repeatPassword}, toastRef)) return;
        try{
            setstateValues({isLoading:true});
            const passwordResetResponse=await user.resetPassword(passResetState.password!,personData?.personId!);
            if(passwordResetResponse.data.status===1){
                setstateValues({activeIndex:3});
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Reset Success',
                    message: 'Your password was successfully reset. You will be redirected to login',
                    infoType: 'success',
                    life: 3000,
                    stickyStatus:true,
                    allowClose:true
                });
            }
            setTimeout(()=>{
                navigateOut('/login');
            },5000);
        }catch(error:any){
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
        }finally {
            setstateValues({isLoading:false});
        }
    }
    return (
        <>
            {passResetState.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef} toastPosition="top-right"/>
            <div className="flex justify-content-center flex-wrap" style={{ height: '50vh' }}>
                <div className="col-12 md:col-12">
                    <div className="card card-w-title">
                        <h5>Steps</h5>
                        <Steps
                            model={wizardItems}
                            activeIndex={passResetState.activeIndex}
                            onSelect={(e) =>
                                setPasswordResetState((prevState) => {
                                    return { ...prevState, activeIndex: e.index };
                                })
                            }
                            readOnly={false}
                        />
                        <PasswordContext.Provider value={{ stateValueChange: stateValueChange, componentState: passResetState,
                            requestPinCode:onPinCodeRequest,confirmPinCode:onConfirmPIN,resetPassword:onResetPassword }}>
                            {passResetState.activeIndex === 0 ? <ConfirmPhoneNumber /> : passResetState.activeIndex === 1 ? <ConfirmPinNumber /> :
                                passResetState.activeIndex === 2 ? <PasswordChange /> : <>Your Password was completely reset. You will be redirected to login.</>}
                        </PasswordContext.Provider>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ChangePassword;
