import React, { useState } from 'react';
import { Toast } from 'primereact/toast';
import { format } from 'date-fns';
import Joi from 'joi';
import { RegistrationPageInit, TOutletTypes, TStore, TStoreOutletCategory, TStoreType } from 'TStore';
import { get, set, del } from 'idb-keyval';
import { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { TCustomer, TPerson, TSupplier } from 'TPerson';
import {
    IGiftCards,
    IOutlet,
    IOutLetItem,
    IPromotionalDiscounts,
    ITaxes,
    ITermsConditions,
    TAdjustmentTransaction,
    TAppUser,
    TCategory,
    TCustomerTypesAndCategories,
    TDashboardMetaData,
    TDiscountTypes,
    TExpenditure,
    TExpenditureCategory,
    TItemSubcategory,
    TMetaSummary,
    TProformaInvoice,
    TPurchaseTransactionItem,
    TRelatedItemCategory,
    TSaleTransactionItem,
    TTransactionItem,
    TTransactionPayment,
    TTransferTransaction,
    TUserSettings
} from 'UtilTypes';
import { IOutletSettings, REDUCER_ACTION_TYPE, StateReducerAction, TStorePage } from './customTypesUtils';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { QueryClient, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import * as XLSX from 'xlsx';
import { fetchData } from './reactQueryUtils';
import { getCode, getNames } from 'country-list';
import fetchAction from './axiosConfig';
import { localDB } from '../offline/db';
import { promptUserAction } from './utilComponents';
import isOnline from 'is-online';

const currencyFormatter = require('currency-formatter');
const _ = require('lodash');

export function sliceObject(selectableProperties: Array<keyof any>, parentObject: any): any {
    const selectedValues: any = {};
    for (const prop of selectableProperties) {
        selectedValues[prop] = parentObject[prop];
    }
    return selectedValues;
}

export interface DropdownOption {
    name: string;
    id: string | number;
    value: string;
}

interface Gender {
    id: number;
    desc: string;
}

interface CustomerType {
    customerTypeId: number;
    customerTypeDesc: string;
}

interface CustomerCategory {
    categoryId: number;
    categoryDescription: string;
}

interface Country {
    countryId: string;
    countryDesc: string;
}

export const remakeDropdownSelects = (data: any[], nameOption: string, idOption: string | number): DropdownOption[] => {
    return data.map((selectData: any) => {
        const nameDisplay = selectData[nameOption];
        return {
            name: nameDisplay,
            id: nameDisplay,
            value: selectData[idOption]
        };
    });
};
export const storesDropDownOptions = (stores: TStore[]) => {
    return remakeDropdownSelects(stores, 'storeDescription', 'storeId');
};
export const outletsDropDownOptions = (outlets: IOutlet[]) => {
    return remakeDropdownSelects(outlets, 'outletDescription', 'outletId');
};

interface DMProps {
    toastComponent: React.RefObject<Toast>;
    header: string;
    message: string;
    infoType: 'success' | 'info' | 'warn' | 'error' | undefined;
    life: number;
    stickyStatus?: boolean;
    allowClose?: boolean;
}

export const usePendingListFetch = (outletId: number, appState: string) => {
    return useQuery(['pendingList'], async () => (appState === 'onLine' ? await fetchAction<TSaleTransactionItem[]>('get', `${getBaseURL()}/sales/get_today_sale_transactions?outletId=${outletId}`, {}) : getLocalSupervisedTransactions()), {
        select: (data) => {
            return data.data;
        },
        refetchInterval: 5000
    });
};
const getLocalSupervisedTransactions = async (): Promise<{ data: TSaleTransactionItem[] }> => {
    const saleData = await localDB.salesTransactions.where('transactionDate').equals(formatDate(new Date())).toArray();
    return { data: saleData };
};
export const displayMessage = ({ header, message, infoType, toastComponent, life, stickyStatus = false, allowClose = false }: DMProps) => {
    toastComponent.current?.show({
        severity: infoType,
        summary: header,
        detail: message,
        life,
        sticky: stickyStatus,
        closable: allowClose
    });
};
export const checkInternetStatus=async(toastComponent:any,checkConnection:boolean=false)=>{
    if(checkConnection===false) return true;
    if(await isOnline()===false){
        displayMessage({
            header:'No Internet',
            message:"You seem to have lost internet connection. Check and retry!",
            infoType:'error',
            toastComponent,
            life:3000
        });
        return false;
    }
    return true;
}
/**
 *
 * @param date
 * @returns  A string representation of the given date parameter (YYYY-MM-DD)
 */
export const formatDate = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
};

export async function addNewItemToCache<T>(existingArray: T[], newItem: T) {
    return [...existingArray, newItem];
}

export async function updateCacheItem<T>(updatedItem: T, itemBeforeUpdate: T, allItems: T[]) {
    const indexOfItem = _.findIndex(allItems, itemBeforeUpdate);

    const newItems: T[] = [...allItems];

    _.set(newItems, indexOfItem, updatedItem);

    return newItems;
}

export async function deleteCacheItem<T>(deletingItem: T, itemBeforeDelete: T, allItems: T[]) {
    const indexOfItem = _.findIndex(allItems, itemBeforeDelete);

    const clonedItems = [...allItems];

    _.pullAt(clonedItems, indexOfItem);

    return clonedItems;
}

export const displayGenders = (): DropdownOption[] => {
    const genders: Gender[] = [
        { id: 1, desc: 'Male' },
        { id: 2, desc: 'Female' }
    ];

    return remakeDropdownSelects(genders, 'desc', 'id');
};

export const displayCountry = (): DropdownOption[] => {
    const countryList = getNames().map((country: string) => {
        return { countryDesc: country, countryId: country };
    });
    return remakeDropdownSelects(countryList, 'countryDesc', 'countryId');
};
export const displayReportTypes = (): DropdownOption[] => {
    // {reportId:'Company',desc:'Company'},
    return remakeDropdownSelects(
        [
            { reportId: 'Store', desc: 'Store' },
            { reportId: 'Outlet', desc: 'Outlet' }
        ],
        'desc',
        'reportId'
    );
};
export const displayOutletCategories = (outletCategory: TStoreOutletCategory[]) => {
    return remakeDropdownSelects(outletCategory, 'outletCategoryDescription', 'outletCategoryId');
};
export const getCountryCode = (selectedCountry: string) => {
    return getCode(selectedCountry);
};
export const displayOutletTypes = (): DropdownOption[] => {
    const outLetTypesList: TOutletTypes[] = [
        { outletTypeId: 1, outletTypeDescription: 'Branch' },
        { outletTypeId: 2, outletTypeDescription: 'Warehouse' }
    ];
    return remakeDropdownSelects(outLetTypesList, 'outletTypeDescription', 'outletTypeId');
};
export const displayStoreTypes = (storeTypes: TStoreType[]) => {
    return remakeDropdownSelects(storeTypes, 'storeTypeDescription', 'storeTypeId');
};
export const displayDiscountTypes = (): DropdownOption[] => {
    const discountTypesList: TDiscountTypes[] = [
        { id: 1, name: 'Percentage' },
        { id: 2, name: 'Fixed Amount' }
    ];
    return remakeDropdownSelects(discountTypesList, 'name', 'id');
};
export const displayProducts = (itemsList: IOutLetItem[]): DropdownOption[] => {
    return remakeDropdownSelects(itemsList, 'itemName', 'itemId');
};
export const commonValidation = Joi.object({
    fullName: Joi.string()
        .regex(/^[A-Za-z .]+$/)
        .required()
        .messages({
            'string.empty': 'Please enter user full name',
            'string.pattern.base': 'Numbers are not allowed for person name.'
        }),
    emailAddress: Joi.string()
        .email({ tlds: { allow: ['com', 'net'] } })
        .optional()
        .allow('')
        .messages({ 'string.email': 'Enter a valid email address or leave the field blank.' }),
    phoneNumber: Joi.string()
        .regex(/^\d{10}$/)
        .required()
        .messages({
            'string.empty': 'Add telephone number',
            'string.pattern.base': 'Please ensure that phone numbers are only numbers and are not exceeding 10 characters'
        }),
    username: Joi.string().required().messages({ 'string.empty': 'Add username before proceeding' }),
    gender: Joi.number().min(1).messages({ 'number.min': 'Please select a gender for user' }),
    storeId: Joi.number().min(1).messages({ 'number.min': 'Please select user store!' }),
    outletId: Joi.number().min(1).messages({ 'number.min': 'Please select user store outlet' }),
    addressLine: Joi.string().messages({ 'string.empty': 'Address Line value is required.' }),
    addressCity: Joi.string().optional().allow(''),
    addressState: Joi.string().optional().allow(''),
    addressCountry: Joi.string().optional().allow(''),
    postalCode: Joi.string().optional().allow(''),
    personId: Joi.number(),
    addressId: Joi.number()
});
export const outletValidation = Joi.object({
    outletDescription: Joi.string().required().messages({ 'string.empty': 'Add a valid initial branch description' }),
    outletEmail: Joi.string()
        .email({ tlds: { allow: ['com', 'net'] } })
        .optional()
        .allow('')
        .messages({ 'string.email': 'Enter a valid email' }),
    outletPhoneNumber: Joi.string()
        .regex(/^\d{10}$/)
        .required()
        .messages({
            'string.empty': 'Input a phone number',
            'string.pattern.base': 'Please ensure that outlet phone numbers are only numbers and are not exceeding 10 characters'
        }),
    outletAddressLine: Joi.string().required().messages({ 'string.empty': 'Add outlet address for identification' }),
    outletAddressCity: Joi.string().required().messages({ 'string.empty': 'Add outlet address city' }),
    outletAddressState: Joi.string().required().messages({ 'string.empty': 'Add a state of province for outlet address' }),
    outletAddressCountry: Joi.string().required().messages({ 'string.empty': 'Select outlet address country' }),
    outletType: Joi.number().min(1).required().messages({ 'number.min': 'Please select a valid type for outlet' }),
    categoryId: Joi.number().required().messages({ 'number.base': 'Select a valid type of business that outlet runs' })
});

export function createIDBPersister(idbValidKey: IDBValidKey = 'virtualStore') {
    return {
        persistClient: async (client: PersistedClient) => {
            await set(idbValidKey, client);
        },
        restoreClient: async () => {
            return await get<PersistedClient>(idbValidKey);
        },
        removeClient: async () => {
            await del(idbValidKey);
        }
    } as Persister;
}

export const getTableRowId = (e: React.MouseEvent<HTMLButtonElement>, rowProp: string) => {
    //@ts-ignore
    if (e.target.closest('button')[rowProp]) {
        //@ts-ignore
        return e.target.closest('button')[rowProp]!;
    }
    return 'Not Found';
};

export const useUserLocalStorage = (): [TPerson | null, (userData: TPerson) => void, () => void] => {
    const [user, setUser] = useState<TPerson | null>(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const updateUser = (userData: TPerson) => {
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const clearUser = () => {
        setUser(null);
        localStorage.removeItem('user');
    };

    return [user, updateUser, clearUser];
};

export const pageDataValidation = <T>(validationObject: Joi.ObjectSchema<T>, stateValues: Partial<T>, toastRef: React.MutableRefObject<null>): boolean => {
    const value = validationObject.validate(stateValues, { abortEarly: true });
    if (value.error) {
        console.log(value);
        displayMessage({
            toastComponent: toastRef,
            header: 'Error',
            message: value.error.details[0].message,
            infoType: 'error',
            life: 3000
        });
        return false;
    }
    return true;
};

export const changeState = <T>(stateValues: Partial<T>, dispatch: React.Dispatch<StateReducerAction>) => {
    dispatch({
        type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
        payload: { ...stateValues }
    });
};
export const inputChange = (e: React.ChangeEvent<HTMLInputElement>, dispatch: React.Dispatch<StateReducerAction>) => {
    changeState({ [e.target.id]: e.target.value }, dispatch);
};
export const selectControlChange = (e: DropdownChangeEvent, dispatch: React.Dispatch<StateReducerAction>) => {
    changeState({ [e.target.id]: e.value }, dispatch);
};
export const onDialogShown = () => {};

export function generateUnique13DigitNumber(): number {
    return parseInt(
        Math.floor(Math.random() * 10000000000000)
            .toString()
            .padStart(14, '0')
    ); // Generate a 14-digit random number
}

export const getBaseURL = () => {
    // return `https://storesdb.vitalfacilitiesgroup.com`;
    return 'https://servicedb.myeasysalesapp.com';
    // return `http://localhost:5000`;
    // return `http://192.168.206.151:5000`;
};

export const encodeFilesToData = (file: File, maxWidth: number = 0, maxHeight: number = 0): Promise<{ fileData: string; fName: string }> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            const imageWidth = img.width;
            const imageHeight = img.height;

            if (imageWidth > maxWidth || imageHeight > maxHeight) {
                reject(new Error(`Image dimensions are too wide. Image dimensions should not exceed width: ${maxWidth}px and height: ${maxHeight}px`));
            } else {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const base64Data = reader.result as string;
                    const fileExtension = file.name.split('.').pop() || '';
                    const fName = `file_${Date.now()}.${fileExtension}`;
                    resolve({ fileData: base64Data, fName });
                };

                reader.onerror = () => {
                    reject(reader.error);
                };

                reader.readAsDataURL(file);
            }
        };

        img.onerror = () => {
            reject(new Error('Failed to load the image.'));
        };
    });
};

export const getCurrentlyLoggedOutlet = (queryClient: QueryClient, currentUserOutletId: string | number) => {
    try {
        return _.find(queryClient.getQueryData<IOutlet[]>(['storeOutletsPageData']), function (outlet: IOutlet) {
            return outlet.outletId === currentUserOutletId;
        });
    } catch (error) {
        console.log(error);
    }
};
export const getOutletSettings = (currentlyLoggedOutlet: IOutlet) => {
    return JSON.parse(currentlyLoggedOutlet!.outletSettings!); //has undefined
};
export const getCurrentOutletCategories = (stateCategories: TCategory[], currentlyLoggedOutlet: IOutlet) => {
    const outletSettings: IOutletSettings[] = getOutletSettings(currentlyLoggedOutlet);

    const outletCategories = outletSettings[0].items.categories; //already selected categories for outlet;

    const notSelected: TCategory[] = outletCategories ? _.reject(stateCategories, (pageCategory: any) => outletCategories.includes(pageCategory.categoryId)) : [];

    const alreadySelected: TCategory[] = outletCategories ? _.filter(stateCategories, (pageCategory: any) => outletCategories.includes(pageCategory.categoryId)) : [];

    return { notSelected, alreadySelected };
};
export const getCurrentOutletRelatedCategories = (relatedCategories: TRelatedItemCategory[], currentlyLoggedOutlet: IOutlet) => {
    const currentOutletSettings: IOutletSettings[] = getOutletSettings(currentlyLoggedOutlet);

    const outletRelatedCategories = currentOutletSettings[0].items.relatedCategories;

    const notSelected: TRelatedItemCategory[] = outletRelatedCategories ? _.reject(relatedCategories, (pageRelatedCategory: any) => outletRelatedCategories.includes(pageRelatedCategory.relatedCategoryId)) : [];

    const alreadySelected: TRelatedItemCategory[] = outletRelatedCategories ? _.filter(relatedCategories, (pageRelatedCategory: any) => outletRelatedCategories.includes(pageRelatedCategory.relatedCategoryId)) : [];

    return { notSelected, alreadySelected };
};
export const getCurrentOutletSubCategories = (subCategories: TItemSubcategory[], currentlyLoggedOutlet: IOutlet) => {
    const currentOutletSettings: IOutletSettings[] = getOutletSettings(currentlyLoggedOutlet);

    const outletSubCategories = currentOutletSettings[0].items.subCategories;

    const notSelected: TItemSubcategory[] = outletSubCategories ? _.reject(subCategories, (pageRelatedCategory: any) => outletSubCategories.includes(pageRelatedCategory.itemSubCategoryId)) : [];

    const alreadySelected: TItemSubcategory[] = outletSubCategories ? _.filter(subCategories, (pageRelatedCategory: any) => outletSubCategories.includes(pageRelatedCategory.itemSubCategoryId)) : [];

    return { notSelected, alreadySelected };
};
export const responsiveOptions = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
];
export const formatAccountingNumbers = (formattingValue: number) => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(formattingValue);
};
export const getAdjustedItems = async <T>(currentItem: T, newQtyValue: number, selectedAdjustmentItems: T[]) => {
    return await updateCacheItem<T>({ ...currentItem, itemQty: newQtyValue }, currentItem, selectedAdjustmentItems);
};
export const straightLine = (hStartFrom = 0, hEndTo = 540, vStartFrom = 1, vEndTo = 1, lineWidth = 0) => {
    return {
        canvas: [
            {
                type: 'line',
                x1: hStartFrom,
                y1: vStartFrom,
                x2: hEndTo,
                y2: vEndTo,
                lineWidth: lineWidth,
                color: 'rgba(23,22,22,0.95)'
            }
        ]
    };
};

export const displayUsingStoreOutlet = (currentOutlet: IOutlet) => {
    return {
        stack: [
            {
                text: currentOutlet.outletDescription,
                fontSize: 20,
                bold: true
            },
            {
                text: `${currentOutlet.outletAddressLine}, ${currentOutlet.outletAddressCity}`,
                alignment: 'left'
            },
            {
                text: `${currentOutlet.outletAddressState} ${currentOutlet.outletAddressCountry}`,
                alignment: 'left'
            }
        ]
    };
};
export const invoiceHeading = () => {
    return {
        columns: [
            {
                width: 300,
                text: 'Description',
                alignment: 'left'
            },
            {
                width: '*',
                text: 'Quantity'
            },
            {
                width: '*',
                text: 'Unit Price'
            },
            {
                width: '*',
                text: 'Sub Total'
            }
        ],
        marginTop: 15,
        fontSize: 14,
        bold: true,
        alignment: 'right'
    };
};

export const lineItemsDisplay = (transactionItems: TTransactionItem[]) => {
    return transactionItems.map((listItem: TTransactionItem) => {
        return lineItemDetails(listItem);
    });
};
const lineItemDetails = (lineItem: TTransactionItem) => {
    return [
        {
            columns: [
                {
                    width: 300,
                    text: `${lineItem.itemName} ${parseFloat(lineItem.qtyAdded) > 0 ? `[${lineItem.qtyAdded}]` : ''} ${parseFloat(lineItem.qtyReturned) > 0 ? `(${lineItem.qtyReturned})` : ''}`,
                    alignment: 'left'
                },
                {
                    width: '*',
                    text: lineItem.itemQty
                },
                {
                    width: '*',
                    text: lineItem.itemPrice
                },
                {
                    width: '*',
                    text: lineItem.itemTotalCost.toFixed(2)
                }
            ],
            alignment: 'right'
        },
        straightLine()
    ];
};
export const summaryDisplayItem = (title: string, displayValue: number) => {
    return {
        columns: [
            {
                text: title,
                width: '*',
                bold: true
            },
            {
                text: formatAccountingNumbers(displayValue),
                width: '*',
                alignment: 'right'
            }
        ],
        marginTop: 5
    };
};
export const configureExcelUpload = async (file: File, uploadFileHeaders: string[], uploadObjectNameFields: string[]): Promise<any[]> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
            const bufferArray = e.target?.result as ArrayBuffer;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const headers: any = XLSX.utils.sheet_to_json(ws, { header: 1 })[0]; // Get excel file headers
            const sheetHeaders = headers.map((header: string) => header.trim());
            if (!checkExcelDataIntegrity(sheetHeaders, uploadFileHeaders)) {
                reject(new Error('File Dishonesty detected'));
                return;
            }
            const parsedData = XLSX.utils.sheet_to_json(ws, { range: 1, header: uploadObjectNameFields }); // Starting file read from row 2;

            resolve(parsedData);
        };

        fileReader.readAsArrayBuffer(file);
    });
};

export const checkExcelDataIntegrity = (excelFileHeaders: [], localHeaders: string[]) => {
    const validData = excelFileHeaders.every((header: string) => localHeaders.includes(header));
    let arrangementIsValid = true;
    localHeaders.forEach((uploadHeader, index) => {
        // @ts-ignore
        const index2 = excelFileHeaders.indexOf(uploadHeader.trim());
        if (index !== index2) {
            arrangementIsValid = false;
            return false;
        }
    });
    return validData && arrangementIsValid;
};
export const performItemQtyEdit = async (transactionItemId: number, transactionItems: TTransactionItem[], selectedEditingOption: number, editingQtyValue: number) => {
    const currentItem = transactionItems.find((listItem: TTransactionItem) => listItem.itemId === transactionItemId);
    if (currentItem) {
        const itemQty = parseFloat(currentItem.itemQty);
        if (selectedEditingOption === 2) {
            if (editingQtyValue > itemQty + parseFloat(currentItem.qtyAdded) - parseFloat(currentItem.qtyReturned)) {
                return false;
            }
        }
        const currentItemQty = selectedEditingOption === 1 ? itemQty + editingQtyValue : itemQty + parseFloat(currentItem.qtyAdded);
        const updatedItem: TTransactionItem = {
            ...currentItem,
            qtyAdded: selectedEditingOption === 1 ? (editingQtyValue + parseFloat(currentItem.qtyAdded)).toFixed(2) : currentItem.qtyAdded,
            qtyReturned: selectedEditingOption === 2 ? (editingQtyValue + parseFloat(currentItem.qtyReturned)).toFixed(2) : currentItem.qtyReturned,
            itemTotalCost: selectedEditingOption === 1 ? currentItemQty * parseFloat(currentItem.itemPrice) : currentItem.itemTotalCost
        };
        return await updateCacheItem<TTransactionItem>(updatedItem, currentItem, transactionItems);
    }
};

/** Function gets a transactionID by combination of today date,time,current minute and the current store acronym or abbreviation.
 @returns {string} - returns string concatenation of the exuding results.
 * @param uniqueString
 * @param transactionsToday
 */
export const getTransactionId = (uniqueString: string, transactionsToday: number): string => {
    const now = new Date();
    const dateStr = now.toISOString().slice(0, 10).replace(/-/g, '');
    const timeStr = now.toTimeString().slice(0, 8).replace(/:/g, ''); // Remove colons
    const minutesStr = now.getMinutes();
    const initials = uniqueString
        .split(' ')
        .map((str) => str[0])
        .join('');
    return `${initials}${dateStr}${timeStr}${minutesStr}000${transactionsToday}`;
};
export const getTimeInElapsedEquivalent = (minutes: number) => {
    if (minutes < 0 || !minutes) {
        return '0';
    }
    if (minutes < 60) {
        return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (minutes < 1440) {
        // 60 minutes * 24 hours = 1 day
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
    } else if (minutes < 10080) {
        // 60 minutes * 24 hours * 7 days = 1 week
        const days = Math.floor(minutes / 1440);
        const remainingHours = Math.floor((minutes % 1440) / 60);
        return `${days} day${days !== 1 ? 's' : ''} ${remainingHours} hour${remainingHours !== 1 ? 's' : ''}`;
    } else if (minutes < 43829) {
        // 60 minutes * 24 hours * 365.25 days = 1 year (approximately)
        const weeks = Math.floor(minutes / 10080);
        const remainingDays = Math.floor((minutes % 10080) / 1440);
        return `${weeks} week${weeks !== 1 ? 's' : ''} ${remainingDays} day${remainingDays !== 1 ? 's' : ''}`;
    } else {
        const months = Math.floor(minutes / 43829);
        const remainingWeeks = Math.floor((minutes % 43829) / 10080);
        return `${months} month${months !== 1 ? 's' : ''} ${remainingWeeks} week${remainingWeeks !== 1 ? 's' : ''}`;
    }
};
export const shouldExecuteReload = (dateValue: number, userStaleSetMinutes: number) => {
    // Get the current date and time in milliseconds since the epoch
    const currentDate = Date.now();

    // Calculate the difference between the current date and time and the given date value
    const differenceInMillis = currentDate - dateValue;

    // Convert the difference from milliseconds to minutes
    const elapsedMinutes = Math.floor(differenceInMillis / (1000 * 60));
    return elapsedMinutes >= userStaleSetMinutes;
};
export const storeTypesAndOutletCategoriesQuery = async (queryClient: QueryClient) => {
    return await fetchData<RegistrationPageInit>(`store_types/get_store_types`, queryClient, ['regDetails']);
};
export const ownerStoresAndOutletsQuery = async (queryClient: QueryClient, user: TPerson) => {
    const storesAndOutlets = await fetchData<[TStorePage[], IOutlet[]]>(`store_users/init_users_page?userId=${user?.personId}&storeId=${user?.storeId}`, queryClient, ['usersPageData'], 5000);
    return { stores: storesAndOutlets[0], outlets: storesAndOutlets[1] };
};
export const storeOutletsListQuery = async (queryClient: QueryClient, currentUser: TPerson) => {
    return await fetchData<IOutlet[]>(`outlets/get_store_outlets?storeId=${currentUser?.storeId}`, queryClient, ['storeOutletsPageData']);
};
export const suppliersListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TSupplier[]>(`suppliers/get_store_suppliers?storeId=${personData?.storeId}`, queryClient, ['suppliersPageData']);
};
export const usersListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TPerson[]>(`store_users/get_app_users?storeId=${personData?.storeId}`, queryClient, ['storeUsers']);
};
export const customersListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TCustomer[]>(`customers/get_store_customers?storeId=${personData?.storeId}`, queryClient, ['customersPageData']);
};
export const expiringItemsListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<Partial<IOutLetItem>[]>(`items/get_items_expiring?outletId=${personData?.outletId}`, queryClient, ['expiringItems'], 1000);
};
export const storesListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TStorePage[]>(`stores/get_stores_list?ownerId=${personData?.personId}`, queryClient, ['storePageData'],1000);
};
export const customersCategoriesAndTypesQuery = async (queryClient: QueryClient) => {
    return await fetchData<TCustomerTypesAndCategories>(`customers/get_customer_types_and_categories`, queryClient, ['customerTypesCategories'], 5000);
};
export const categoriesRelatedCatSubCatQuery = async (queryClient: QueryClient, personData: TPerson) => {
    const setupData = await fetchData<any>(`items/init_item_page?a=${personData?.outletId}&b=${personData?.outletId}&c=${personData?.outletId}&d=${personData?.outletId}`, queryClient, ['itemsPageData']);
    return { categories: setupData[0], relatedCategories: setupData[1], subCategories: setupData[2], brands: setupData[3] };
};
export const itemsListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<IOutLetItem[]>(`items/get_items_list?storeOutlet=${personData?.outletId}`, queryClient, ['itemsList']);
    // return remoteItems;
};
export const storeItemsListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<IOutLetItem[]>(`items/sync_items_list?storeId=${personData?.storeId}`, queryClient, ['storeItemsList']);
};
export const promoListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<IPromotionalDiscounts[]>(`promotional_discount/get_promotional_discounts?discountStore=${personData?.storeId}`, queryClient, ['promotionalDiscounts']);
};
export const taxListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<ITaxes[]>(`taxes/get_taxes?storedId=${personData?.storeId}`, queryClient, ['taxes']);
};
export const outletCustomersListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    const remoteCustomers = await fetchData<TCustomer[]>(`customers/get_outlet_customers?outletId=${personData?.outletId}`, queryClient, ['outletCustomers'], 5000);

    return remoteCustomers.filter((customer) => customer.outletId == personData.outletId);
};
export const giftCardsListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<IGiftCards[]>(`gift_cards/get_gift_cards?storeId=${personData?.storeId}`, queryClient, ['giftCards']);
};
export const termsConditionsQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<ITermsConditions[]>(`terms_conditions/get_terms_conditions?storeId=${personData?.storeId}`, queryClient, ['storeTermsAndConditions']);
};
export const expenditureListQuery = async (queryClient: QueryClient, personData: TPerson,expenditureDates:Date[]) => {
    return await fetchData<TExpenditure[]>(`expenditure/get_expenditures?storeId=${personData?.storeId}&startDate=${formatDate(expenditureDates[0])}&endDate=${formatDate(expenditureDates[1])}`, queryClient, ['storesExpenses']);
};
export const expenditureCategoriesListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TExpenditureCategory[]>(`expenditure/get_expenditure_categories?outletId=${personData?.outletId}`, queryClient, ['expenseCategories'],5000);
};
export const currentlyLoggedOutletQuery = async (queryClient: QueryClient, personData: TPerson) => {
    const currentOutlet = await fetchData<IOutlet[]>(`outlets/get_current_logged_outlet?outletId=${personData?.outletId}`, queryClient, ['currentLoggedOutlet']);
    if (currentOutlet.length === 0) {
        const outlet = await fetchAction<IOutlet[]>('get', `${getBaseURL()}/outlets/get_current_logged_outlet?outletId=${personData?.outletId}`, {});
        queryClient.setQueryData(['currentLoggedOutlet'], outlet.data[0]);
        return outlet.data[0];
    }
    return currentOutlet[0];
};

export const saleTransactionsQuery = async (personData: TPerson):Promise<TSaleTransactionItem[]> => {
    const salesData=await fetchAction<TSaleTransactionItem[]>('get',`${getBaseURL()}/sales/get_sale_transactions?outletId=${personData?.outletId}`,{});
    return salesData.data;
    // return []
    // const remoteSales = await fetchData<TSaleTransactionItem[]>(`sales/get_sale_transactions?outletId=${personData?.outletId}`, queryClient, ['saleTransactions']);
    // return remoteSales.filter((sale) => sale.outletId === personData.outletId);
};
export const proformaInvoicesQuery = async (queryClient: QueryClient, personData: TPerson) => {
    const remoteProformas = await fetchData<TProformaInvoice[]>(`sales/get_proforma_invoices?outletId=${personData?.outletId}`, queryClient, ['proformaTransactions']);
    return remoteProformas.filter((proforma) => proforma.outletId === personData.outletId);
};
export const todaySalesTransactionsQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TSaleTransactionItem[]>(`sales/get_today_sale_transactions?outletId=${personData?.outletId}&todayDate=${formatDate(new Date())}`, queryClient, ['salesTransactionsToday']);
};

export const outletSuppliersQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TSupplier[]>(`suppliers/get_outlet_suppliers?outletId=${personData?.outletId}`, queryClient, ['outletSuppliers'], 5000);
};
export const purchaseTransactionQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TPurchaseTransactionItem[]>(`purchases/get_purchase_transactions?outletId=${personData?.outletId}`, queryClient, ['purchaseTransactions']);
};
export const adjustmentsListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TAdjustmentTransaction[]>(`adjustment/get_adjustments?storeOutletId=${personData?.outletId}`, queryClient, ['adjustmentTransactions']);
};
export const userSettingsQuery = async (queryClient: QueryClient, personData: TPerson) => {
    const settingsData = await fetchData<TAppUser[]>(`store_users/get_user_settings?outletId=${personData?.outletId}`, queryClient, ['userSettings']);
    const settingsReceivedData: TUserSettings = settingsData.length ? (typeof settingsData[0].userSettings === 'string' ? JSON.parse(`${settingsData[0].userSettings}`) : getDefaultUserSettings()) : getDefaultUserSettings();
    return settingsReceivedData;
};
export const transfersListQuery = async (queryClient: QueryClient, personData: TPerson) => {
    return await fetchData<TTransferTransaction[]>(`transfers/get_transfer_items?storeId=${personData?.storeId}`, queryClient, ['transfersList']);
};
export const queryDashboardMetaData = async (queryClient: QueryClient, personData: TPerson,currentDate:string) => {
    return await fetchData<TDashboardMetaData>(`stores/dashboard_meta?outletId=${personData?.outletId}&currentDate=${currentDate}`, queryClient, ['dashboardMetaData'], 5000);
};
export const showPageTitle = (pageTitle: string) => {
    document.title = pageTitle;
};

export const currencyFormat = (amountToFormat: number = 0, countryCode: string = 'USD') => {
    return currencyFormatter.format(amountToFormat, {
        code: countryCode
    });
};

export const getDefaultUserSettings = (): TUserSettings => {
    return {
        allowNegativeSales: true,
        allowTaxes: false,
        allowSalesConfirmation: false,
        printerType: 'Thermal',
        currencyType: '',
        printPageOrientation: 'Portrait',
        allowAfterSalesPrint: true,
        showTodaySales: true,
        allowItemQtyDecimal: false,
        appMode: 'onLine',
        checkInternetConnection:false,
        allowMarkupPrice:true,
        colorScheme:'dark',
        theme:'blue',
        componentTheme:'blue',
        showTransactionDate:false,
        allowSalesPriceEdit:true,
        enableWholeSaleSelling:false
    };
};

export const loadImageAsBase64 = (url: string) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx!.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.src = url;
    });
};

export const getAccessLevels = (userAccessLevel: number) => {
    switch (userAccessLevel) {
        case 1:
            return ['sales', 'settings'];
        case 2:
            return ['sales', '/', 'supervise', 'settings','items','purchases'];
        case 3:
            return [
                '/',
                'users',
                'customers',
                'suppliers',
                'stores',
                'outlets',
                'terms',
                'discounts',
                'taxes',
                'gifts',
                'items',
                'sales',
                'purchases',
                'adjustments',
                'login',
                'settings',
                'expenditure',
                'reports',
                'passwords',
                'supervise',
                'admin_panel',
                'transfers',
                'choice'
            ];
        default:
            return ['sales'];
    }
};

export const allowAccessState = (currentPage: string, accessLevel: number) => {
    return _.includes(getAccessLevels(accessLevel), currentPage);
};

const fetchItems = async ({ pageParam = 1, ...params }): Promise<IOutLetItem[]> => {
    const response = await fetch(`${getBaseURL()}/items/get_infinite_items?page=${pageParam}&outletId=${params.outletId}`);
    const data: IOutLetItem[] = await response.json();
    return data;
};

export const useInfiniteQueryHook = (otherInfo: any) => {
    return useInfiniteQuery(['stock'], async ({ pageParam }) => (otherInfo.currentAppMode === 'onLine' ? await fetchItems({ pageParam, ...otherInfo }) : await fetchLocalDBItems({ pageParam, ...otherInfo })), {
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.length > 0 ? allPages.length : undefined;
        },
        getPreviousPageParam: (firstPage, allPages) => {
            return allPages.length - 1;
        }
    });
};

const fetchLocalDBItems = async ({ pageParam = 1, ...params }) => {
    const page: any = pageParam || 1; // Default page 1
    const pageSize: any = 10; // Default page size 10
    const offset = Math.abs((page - 1) * pageSize);
    return await localDB.outletItems.where({ outletId: params.outletId }).offset(offset).limit(10).toArray();
};
export const getDates = (startDate: Date, endDate: Date) => {
    const dates: Date[] = [];
    let currentDate = new Date(startDate);

    // Iterate through each date until the end date
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};
export const getPeriodSales = (saleTransactions: TSaleTransactionItem[], transactionType: string) => {
    return _.filter(saleTransactions, (transaction: TSaleTransactionItem) => transaction.saleMode === transactionType);
};
export const getPaymentOptionAggregate = (paymentData: any[], paymentMode: string) => {
    const filteredPayments = paymentData.filter((data) => data !== undefined);
    const paymentModeData = _.filter(filteredPayments, (payment: any) => payment.paymentOption === paymentMode);
    return _.reduce(paymentModeData, (accumulator: number, data: any) => accumulator + data.amountTendered, 0);
};
export const getPeriodMeta = (saleTransactionItems: TSaleTransactionItem[] | TPurchaseTransactionItem[]): TMetaSummary => {
    //TODO:ADD PAYMENTS ON ACCOUNTS TO REPORT

    const periodTransactionCost = _.reduce(
        saleTransactionItems,
        (accumulator: number, transactionItem: TSaleTransactionItem) => {
            const transactionItems: TTransactionItem[] = typeof transactionItem.transactionItems === 'string' ? JSON.parse(transactionItem.transactionItems) : transactionItem.transactionItems;
            const realItemsCost = transactionItems.reduce((previousValue, currentValue) => {
                const realSoldQty = parseFloat(currentValue.itemQty) + parseFloat(currentValue.qtyAdded) - parseFloat(currentValue.qtyReturned);
                return previousValue + parseFloat(currentValue.itemPrice) * realSoldQty;
            }, 0);
            return accumulator + realItemsCost;
        },
        0
    );

    const transactionPayments: any[] = saleTransactionItems.map((transactionItem: TSaleTransactionItem | TPurchaseTransactionItem) => {
        const transactionPayment: TTransactionPayment = typeof transactionItem.transactionPayments === 'string' ? JSON.parse(`[${transactionItem.transactionPayments}]`)[0] : transactionItem.transactionPayments;

        const amountPaid = transactionPayment.amountTendered - (transactionPayment.paymentChange > 0 ? transactionPayment.paymentChange : 0);

        const periodTransactionItems = typeof transactionItem.transactionItems === 'string' ? JSON.parse(transactionItem.transactionItems) : transactionItem.transactionItems;

        const transactionReturns = periodTransactionItems.reduce((previousValue: number, currentValue: TTransactionItem) => {
            return previousValue + parseFloat(currentValue.qtyReturned) > 0 ? parseFloat(currentValue.qtyReturned) * parseFloat(currentValue.itemPrice) : 0;
        }, 0);
        const costOfSales = periodTransactionItems.reduce((previousValue: number, currentValue: TTransactionItem) => {
            const realSoldQty = parseFloat(currentValue.itemQty) + parseFloat(currentValue.qtyAdded) - parseFloat(currentValue.qtyReturned);
            return previousValue + realSoldQty * currentValue.itemCost;
        }, 0);

        return {
            amountTendered: amountPaid,
            paymentOption: transactionPayment.paymentOption,
            refundAmount: transactionItem.refundAmount,
            discountAmount: transactionItem.discountAmount,
            returnedCost: transactionReturns,
            costOfSales: costOfSales
        };
    });
    const transactionOtherPayments: any[] = saleTransactionItems.map((transactionItem: TSaleTransactionItem | TPurchaseTransactionItem) => {
        const transactionPayment: TTransactionPayment = typeof transactionItem.transactionPayments === 'string' ? JSON.parse(`[${transactionItem.transactionPayments}]`)[0] : transactionItem.transactionPayments;
        return transactionPayment?.otherPayments?.map((otherPayment: TTransactionPayment) => {
            return otherPayment !== undefined
                ? {
                      amountTendered: otherPayment.amountTendered - otherPayment.paymentChange,
                      paymentOption: otherPayment.paymentOption,
                      refundAmount: 0,
                      discountAmount: 0,
                      returnedCost: 0,
                      costOfSales: 0
                  }
                : {
                      amountTendered: 0,
                      paymentOption: '',
                      refundAmount: 0,
                      discountAmount: 0,
                      returnedCost: 0,
                      costOfSales: 0
                  };
        });
    });
    const mergedPayments = _.concat(...transactionOtherPayments.concat(transactionPayments));

    const cashPayments = getPaymentOptionAggregate(mergedPayments, 'Cash');

    const momo = getPaymentOptionAggregate(mergedPayments, 'Momo');

    const cheque = getPaymentOptionAggregate(mergedPayments, 'Cheque');

    const discountAmount = _.reduce(transactionPayments, (accumulator: number, transactionItem: any) => accumulator + transactionItem.discountAmount, 0);
    const refundAmount = _.reduce(transactionPayments, (accumulator: number, transactionItem: any) => accumulator + transactionItem.refundAmount, 0);
    const returnedAmount = _.reduce(transactionPayments, (accumulator: number, transactionItem: any) => accumulator + transactionItem.returnedCost, 0);
    const costOfSales = _.reduce(transactionPayments, (accumulator: number, transactionItem: any) => accumulator + transactionItem.costOfSales, 0);
    const unpaidAmount = periodTransactionCost - (cashPayments + momo + cheque) + refundAmount;

    return {
        cashPayments,
        momo,
        cheque,
        discountAmount,
        refundAmount,
        returnedAmount,
        costOfSales,
        periodTransactionCost,
        expectedAmountToday: cashPayments + momo + cheque - (refundAmount + discountAmount),
        unpaidAmount: unpaidAmount
    };
};
export const isAppOnline = (userSetting: TUserSettings) => {
    return userSetting.appMode === 'onLine';
};
export const getItemsIds = <T>(arrayOfItems: T[], idProperty: string = 'itemId') => {
    return _.map(arrayOfItems, idProperty);
};
export const getItemsDetailsIds = <T>(arrayOfItems: T[]) => {
    return _.map(arrayOfItems, 'itemDetailsId');
};
const updateStockQty = (stockQuantity: number, realAddedQty: number, realReturnedQty: number, transactionType: string) => {
    return transactionType === 'sale' ? stockQuantity - realAddedQty + realReturnedQty : stockQuantity + realAddedQty - realReturnedQty;
};
export const updateDatabaseItems = (databaseItems: any, updatingItems: TTransactionItem[], oldTransactionItems: TTransactionItem[], transactionItemType: string) => {
    const updatedDBItems: IOutLetItem[] = _.map(databaseItems, (databaseItem: IOutLetItem) => {
        const currentTransactionItem = _.find(updatingItems, (currentItem: TTransactionItem) => currentItem.itemId === databaseItem.itemId);
        const oldTransactionItem = _.find(oldTransactionItems, (oldSaleItem: TTransactionItem) => oldSaleItem.itemId === databaseItem.itemId);
        const realReturns = parseFloat(currentTransactionItem.qtyReturned) - parseFloat(oldTransactionItem.qtyReturned);
        const realAdded = parseFloat(currentTransactionItem.qtyAdded) - parseFloat(oldTransactionItem.qtyAdded);
        const updatedItemQty = updateStockQty(parseFloat(databaseItem.stockQuantity), realAdded, realReturns, transactionItemType);
        return { ...databaseItem, stockQuantity: updatedItemQty.toString() };
    });
    return updatedDBItems;
};
/**
 * This is a generic function that takes a given url and retrieve remote data to be synced with local database;
 * @param url
 */
export const getOnlineData = async <T>(url: string) => {
    try {
        const onlineData = await fetchAction<T>('get', `${getBaseURL()}/${url}`, {});
        return onlineData.data;
    } catch (error: any) {
        throw error;
    }
};
export const getOnlineDataPaginated = async <T>(url: string,currentPage:number,lastSyncDate:string,storeIdOrOutletId:number) => {
    try {
        const onlineData = await fetchAction<T>('post', `${getBaseURL()}/${url}`, {storeIdOrOutletId,pageTracker:currentPage,lastSyncDate});
        return onlineData.data;
    } catch (error: any) {
        throw error;
    }
};
export const getExplainedItemsPaginated = async <T>(url: string,currentPage:number,lastSyncDate:string,storeIdOrOutletId:number,requestOption:number=1) => {
    try {
        const onlineData = await fetchAction<T>('post', `${getBaseURL()}/${url}`, {storeIdOrOutletId,pageTracker:currentPage,lastSyncDate,requestOption});
        return onlineData.data;
    } catch (error: any) {
        throw error;
    }
};
export const promptTableStateRefresh = (yesAction: () => {}, event: React.MouseEvent<HTMLButtonElement>) => {
    promptUserAction({
        yesAction: yesAction,
        event,
        displayText: 'Are you sure you want to save user?'
    });
};
export const checkForTransactionReturns = (transactionItems: TSaleTransactionItem[] | TPurchaseTransactionItem[]) => {
    return transactionItems.map((transaction) => {
        let containsReturn = false;
        const parsedItems = typeof transaction.transactionItems === 'string' ? JSON.parse(transaction.transactionItems) : transaction.transactionItems;
        parsedItems.forEach((item: TTransactionItem) => {
            if (parseInt(item.qtyReturned) > 0) {
                containsReturn = true;
                return false;
            }
        });
        return { ...transaction, hasReturn: containsReturn };
    });
};
export const onlyNumbersAndDecimal = (inputValue: string) => {
    return inputValue.replace(/[^\d.]/g, '');
};
export const onInputControlFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    switch (e.type) {
        case 'focus':
            if (e.target.value === '0') {
                e.target.value = '';
                e.target.select();
            }
            break;
        case 'blur':
            if (e.target.value === '') {
                e.target.value = '0';
            }
            break;
    }
};
export const checkItemCurrentQty = (allowNegativeState: boolean, itemCurrentQty: number): boolean => {
    return !(!allowNegativeState && itemCurrentQty < 0);

};

export const getItemNameAndQuantity = (itemsList: IOutLetItem[]) => {
    return itemsList.map((item) => {
        return { ...item, itemName: `${item.itemName} (${item.stockQuantity})` };
    });
};
export const displayPrintItems = <T>(adjustmentItems: TTransactionItem[]) => {
    return adjustmentItems.map((listItem: TTransactionItem) => {
        return [
            {
                columns: [
                    {
                        width: '*',
                        text: listItem.itemName,
                        alignment: 'left'
                    },
                    {
                        width: '*',
                        text: listItem.itemQty,
                        alignment: 'right'
                    }
                ]
            },
            straightLine()
        ];
    });
};
export const getCurrentOutletItems = (storeItems: IOutLetItem[], currentOutlet: number): IOutLetItem[] => {
    return _.filter(storeItems, (item: IOutLetItem) => item.outletId === currentOutlet);
};

/**
 * get list of query keys we wish to invalidate and retrieve them anytime user tries to access them
 * these data are outlet specific and needs not to be conflicted with other outlets.
 * @returns {string[]}
 */
export const getQueryKeys = (): string[] => [
    'itemsPageData',
    'itemsList',
    'outletCustomers',
    'currentLoggedOutlet',
    'saleTransactions',
    'proformaTransactions',
    'outletSuppliers',
    'purchaseTransactions',
    'adjustmentTransactions',
    'dashboardMetaData',
    'outletSuppliers'
];
export const getNewSyncItems = <T>(localDBItems: T[], remoteServerItems: T[], comparingProperty: string) => {
    const incomingNewItems: T[] = _.differenceBy(remoteServerItems, localDBItems, comparingProperty);
    return incomingNewItems;
};
export const getParsedId = (idToParse: number) => {
    return typeof idToParse !== 'number' ? parseInt(idToParse) : idToParse;
};

export const getDateAndTime=(dateTime:Date)=>{
    return new Date(dateTime).toISOString().slice(0, 19).replace('T', ' ');
}

export const displayNegativeAsZero=(value:number|string)=>{
    const convertedNumber=typeof value==='string'?parseFloat(value):value;
    return convertedNumber<0?0:convertedNumber;
}

/**
 * ensures that no letters are present in a value but allows for  the dot symbol
 * @param value
 * @returns
 */
export const checkLettersInNumber=(value:string)=>{
    return /[a-zA-Z]/.test(value);
}
/**
 * ensures that only numbers and numbers alone are present in a value.
 * @param value
 * @returns
 */
export const onlyNumbers = (value: string) => {
    return !/^\d*$/.test(value);
};
export const applyExpiredConstraints=(daysLeftToExpiry:number)=>{
    // Disable all buttons and inputs
    if(getParsedId(daysLeftToExpiry===undefined?0:daysLeftToExpiry)<1){
        const buttons = document.querySelectorAll('button');
        const inputs = document.querySelectorAll('input');

        buttons.forEach((button) => {
          button.disabled = true;
        });

        inputs.forEach((input) => {
          input.disabled = true;
        });
    }
}

export const generateEAN13=()=> {
    // Generate a random 12-digit number (excluding the first digit)
    const twelveDigits = Math.floor(100000000000 + Math.random() * 900000000000).toString();

    // Calculate the check digit
    const checkDigit = calculateEAN13CheckDigit(twelveDigits);

    // Combine the twelve digits and the check digit to form the full EAN-13 barcode
    const ean13Barcode = twelveDigits + checkDigit;

    return ean13Barcode;
}

function calculateEAN13CheckDigit(twelveDigits:string) {
    // Validate input
    if (typeof twelveDigits !== 'string' || twelveDigits.length !== 12 || !/^\d+$/.test(twelveDigits)) {
        throw new Error('Input must be a string of 12 digits.');
    }

    // Calculate check digit using the EAN-13 algorithm
    const digits = twelveDigits.split('').map(Number);
    let sum = 0;

    for (let i = 0; i < digits.length; i++) {
        sum += (i % 2 === 0) ? digits[i] * 1 : digits[i] * 3;
    }

    const checksum = (10 - (sum % 10)) % 10;

    return checksum.toString();
}

export const isValidEAN13=(barcode: string): boolean =>{
    // Check if barcode is exactly 13 digits long and consists only of digits
    if (barcode.length !== 13 || !/^\d{13}$/.test(barcode)) {
        return false;
    }

    // Extract the 12 digits and the check digit
    const checkDigit = parseInt(barcode[12]);
    const digits = barcode.substr(0, 12).split('').map(Number);

    // Calculate the checksum
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        sum += (i % 2 === 0) ? digits[i] : digits[i] * 3;
    }
    const calculatedCheckDigit = (10 - (sum % 10)) % 10;

    // Compare the calculated check digit with the provided check digit
    return calculatedCheckDigit === checkDigit;
}

export const getRandomHexidecimalColor=()=> {
    // Generate a random number between 0 and 16777215 (which is FFFFFF in hexadecimal)
    var color = Math.floor(Math.random() * 16777215).toString(16);
    // Pad the color with zeros if it's shorter than 6 characters
    return '#' + ('000000' + color).slice(-6);
}

export const getBarChartOptions=(maxValue:number)=>{
    return  {
        plugins: {
            legend: {
                position: 'top',
                align: 'end'
            }
        },
        responsive: true,
        hover: {
            mode: 'index'
        },
        scales: {
            y: {
                min: 0,
                max: maxValue,
                grid: {
                    display: false
                }
            },
            x: {
                grid: {
                    display: false
                }
            }
        }
    };
}

export const getRevenueChart=(chartSales:number[],chartExpenses:number[],chartRevenue:number[])=>{
    return  {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Sales',
                data: chartSales,
                borderColor: '#EEE500',
                pointBackgroundColor: '#EEE500',
                backgroundColor: 'rgba(238, 229, 0, 0.05)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Revenue',
                data: chartRevenue,
                borderColor: '#00D0DE',
                pointBackgroundColor: '#00D0DE',
                backgroundColor: 'rgba(0, 208, 222, 0.05)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Expenses',
                data: chartExpenses,
                borderColor: '#FC6161',
                pointBackgroundColor: '#FC6161',
                backgroundColor: 'rgba(253, 72, 74, 0.05)',
                fill: true,
                tension: 0.4
            }
        ]
    };
}

export const getChartOptions=(maxValue:number)=>{
    return {
        responsive: true,
        hover: {
            mode: 'index'
        },
        scales: {
            y: {
                min: 0,
                max: maxValue,
            }
        }
    };
}
export const formatToTwoDecimalPlaces=(value:number|string)=>{
    return parseFloat(Number(value).toFixed(2));
}
