import { usePasswordContext } from '../ChangePassword';
import { IconTextInput } from '../../helpers/utilComponents';
import React from 'react';
import { Button } from 'primereact/button';

const ConfirmPhoneNumber=()=>{
    const {componentState,stateValueChange,requestPinCode}=usePasswordContext();
    return (
        <>
            <div className="flex justify-content-center flex-wrap">
                <div className="grid">
                    <IconTextInput value={componentState!.phoneNumber!} onInputChange={stateValueChange!}
                                   placeholderValue="Phone Number *"
                                   iconText="pi pi-user" componentId="phoneNumber"
                                   customClasses="lg:col-12 md:col-12 col-12" />
                    <Button onClick={requestPinCode} >Receive Pin Code</Button>
                </div>

            </div>
        </>
    )
}
export default ConfirmPhoneNumber;