import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import { useUserLocalStorage } from './helpers/utils';
import { Avatar } from 'primereact/avatar';

const AppInlineMenu = (props: any) => {
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const [user, updateUser, clearUser] = useUserLocalStorage();
    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };
    const logUserOut = async () => {
        clearUser();
        navigate('/login');
    };
    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
                <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                    <Avatar icon="pi pi-user" size="xlarge" shape="circle" className="layout-inline-menu-action p-link ml-4" onClick={props.onChangeActiveInlineMenu}/>

                    <CSSTransition nodeRef={menuRef} classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
                        <ul ref={menuRef} className="layout-inline-menu-action-panel">
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={logUserOut}>
                                    <i className="pi pi-power-off pi-fw"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link">
                                    <i className="pi pi-cog pi-fw"></i>
                                    <span>Settings</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link">
                                    <i className="pi pi-user pi-fw"></i>
                                    <span>Profile</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default AppInlineMenu;
