import { displayMessage, onInputControlFocus, useUserLocalStorage } from '../../helpers/utils';
import GeneralPurpose from '../../classes/GeneralPurpose';
import { useEffect, useRef, useState } from 'react';
import { TStore } from 'TStore';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Loader } from '../sharedComponents/SharedComponents';
import { GeneralPageProps } from '../../helpers/utilComponents';
import { Card } from 'primereact/card';
import isOnline from 'is-online';
import { Dialog } from 'primereact/dialog';

type TSubscription = {
    subscriber?: TStore;
    paymentAmount: number;
    phoneNumber: string;
    emailAddress: string;
    isLoading: boolean;
    showChargeText: boolean;
    otpNumber: string;
    transactionRef: string;
    showOTPDialog: boolean;
};
const generalPurpose = new GeneralPurpose();

const SubscriptionPayment = () => {
    const toastRef = useRef(null);
    const [personData] = useUserLocalStorage();
    const [subscriptionState, setSubscriptionState] = useState<TSubscription>({
        subscriber: undefined,
        paymentAmount: 0,
        emailAddress: '',
        phoneNumber: '',
        isLoading: true,
        showChargeText: false,
        otpNumber: '0',
        transactionRef: '',
        showOTPDialog: false
    });
    useEffect(() => {
        initSubscriptionPage().catch(console.error);
    }, []);
    const setStateValues = (stateValues: Partial<TSubscription>) => {
        setSubscriptionState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const initSubscriptionPage = async () => {
        const subscriptionDetails = await generalPurpose.getSubscriptionDetails(personData?.storeId!);

        setStateValues({
            subscriber: subscriptionDetails,
            phoneNumber: subscriptionDetails.phoneNumber,
            emailAddress: subscriptionDetails.emailAddress,
            paymentAmount: 0,
            isLoading: false
        });
    };
    const payWithPayStack = async () => {
        if ((await isOnline()) === false) {
            displayMessage({
                header: 'No Internet',
                message: 'Please ensure that you have a stable internet connection before proceeding',
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
            return;
        }
        if (subscriptionState.paymentAmount <= 0) {
            displayMessage({
                header: 'Payment Error',
                message: 'Ensure that all required fields are provided input and amount paying is greater than 0',
                infoType: 'error',
                toastComponent: toastRef,
                life: 3000
            });
            return;
        }
        try {
            setStateValues({ isLoading: true });
            const paymentResponse = await generalPurpose.initiatePaystackPayment({
                emailAddress: subscriptionState!.emailAddress,
                paymentAmount: subscriptionState.paymentAmount,
                phoneNumber: subscriptionState?.phoneNumber,
                storeId: personData?.storeId!,
                subscribedMonths: 3 //replace with actual subscribing months by user.
            });
            console.log(paymentResponse);
            if (paymentResponse.data.status === true && paymentResponse.data.data.status === 'pay_offline') {
                setStateValues({ showChargeText: true, transactionRef: paymentResponse.data.data.reference });
                displayMessage({
                    header: 'Confirm Charge!',
                    message: 'Charge has been initiated for your acceptance. Confirm notice on your phone and accept payment.',
                    infoType: 'info',
                    toastComponent: toastRef,
                    life: 10000
                });
                setTimeout(() => {
                    setStateValues({ showChargeText: false, paymentAmount: 0 });
                }, 10000);
            } else if (paymentResponse.data.status === true && paymentResponse.data.data.status === 'send_otp') {
                setStateValues({ transactionRef: paymentResponse.data.data.reference, showOTPDialog: true });
            }
        } catch (error: any) {
            console.log(error);
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const verifyPaystackPayment = async () => {
        const verifyResponse = await generalPurpose.verifyPaystackPayment('r1st1rf1y15erky');
    };
    const verifyOTP = async () => {
        console.log(subscriptionState.transactionRef);
        const verifyResponse = await generalPurpose.verifyOTPNumber(subscriptionState.transactionRef, subscriptionState.otpNumber);
        console.log(verifyResponse, ' after OTP');
        if (verifyResponse.data.data.status === 'pay_offline' || verifyResponse.data.data.status === 'requery') {
            displayMessage({
                header: 'Confirm Charge!',
                message: 'Charge has been initiated for your acceptance. Confirm notice on your phone and accept payment.',
                infoType: 'info',
                toastComponent: toastRef,
                life: 10000
            });
            setTimeout(() => {
                setStateValues({ showChargeText: false, paymentAmount: 0 });
            }, 10000);
        }
    };
    return (
        <>
            {subscriptionState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} toastPosition="top-right" />
            <div className="p-fluid lg:pl-5">
                <p>
                    Default Email:<span className="ml-3 text-green-400 mr-2">{subscriptionState.subscriber?.emailAddress}</span>
                    Default Phone Number: <span className="ml-3 text-green-400">{subscriptionState.subscriber?.phoneNumber}</span>
                </p>
                <div className="grid">
                    <div className="col-12 lg:col-3 md:col-6 sm:col-12">
                        <label>Your Email:</label>
                        <InputText value={subscriptionState.emailAddress} onChange={(e) => setStateValues({ emailAddress: e.target.value })} />
                    </div>
                    <div className="col-12 lg:col-3 md:col-6 sm:col-12">
                        <label>Your Phone:</label>
                        <InputText value={subscriptionState.phoneNumber} onChange={(e) => setStateValues({ phoneNumber: e.target.value })} />
                    </div>
                    <div className="col-12 lg:col-3 md:col-6 sm:col-12">
                        <label>Amount Paying:</label>
                        <InputNumber onFocus={onInputControlFocus} onBlur={onInputControlFocus} value={subscriptionState.paymentAmount} onChange={(e) => setStateValues({ paymentAmount: e.value! })} />
                    </div>
                    <div className="col-12 lg:col-3 md:col-6 sm:col-12">
                        <Button label="Click to Pay" className="mt-4" onClick={payWithPayStack} />
                    </div>
                </div>

                <div className="grid mt-5">
                    <Dialog header="Verify OTP" onHide={() => setStateValues({ showOTPDialog: false })} visible={subscriptionState.showOTPDialog} className='w-7'>
                        <div className='grid'>
                        <div className="col-12">
                            <InputText className='w-full' onFocus={onInputControlFocus} onBlur={onInputControlFocus} value={subscriptionState.otpNumber} onChange={(e) => setStateValues({ otpNumber: e.target.value! })} />
                        </div>
                        <div className="col-12">
                            <Button label="Verify One Time Password" className="mt-3" onClick={verifyOTP} />
                        </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <div className="flex justify-content-center flex-wrap">
                {subscriptionState.showChargeText && <Card className="text-lg font-bold">Charge has been submitted to your mobile phone for approval. Please confirm Payment to complete charge process!</Card>}
            </div>
        </>
    );
};

export default SubscriptionPayment;
