import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { TPerson } from '../types/TPerson';
import { Socket } from 'socket.io-client';

export interface GlobalContextProps {
    currentUser?: TPerson;
    setCurrentUser?: Dispatch<SetStateAction<TPerson | undefined>>;
    changeMenuMode?: Dispatch<SetStateAction<string>>;
    userMenuOption?: string;
    currentAppMode: string;
    changeAppMode?: Dispatch<SetStateAction<string>>;
    daysLeftToExpiry:number,
    socket?:Socket
}

export const GlobalContext = createContext<GlobalContextProps>({
    currentUser: undefined,
    setCurrentUser: () => {},
    userMenuOption: '',
    currentAppMode: 'onLine',
    daysLeftToExpiry:0,
    socket:undefined
});

export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalContextProvider');
    }
    return context;
};
