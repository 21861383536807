import React from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { SelectableOptions } from '../../types/SelectableOptions';
import { DropdownOption } from '../utils';

interface FilterSelectProps {
    selectableOptions: DropdownOption[];
    selectedOption?: number | string;
    onSelectChange: (e: DropdownChangeEvent) => void;
    customClasses?: string;
    elementId: string;
    defaultValue: string;
    showClearIcon?: boolean;
    showLabel?: boolean;
    pageTabIndex?: number;
}

const FilterSelect: React.FC<FilterSelectProps> = ({ selectableOptions = [], selectedOption = '', onSelectChange, customClasses = '', elementId = '', defaultValue = '', showClearIcon = false, showLabel = true, pageTabIndex }) => {
    const selectedValueTemplate = (option: SelectableOptions) => {
        if (option) {
            return (
                <div className="item-value flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>Select</span>;
    };

    const optionsTemplate = (option: { name: string }) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className={`filter-select ${customClasses}`}>
            {showLabel ? (
                <div className="mb-2">
                    <label htmlFor="dateTo">{defaultValue}</label>
                </div>
            ) : (
                ''
            )}
            <Dropdown
                value={selectedOption}
                options={selectableOptions}
                onChange={onSelectChange}
                optionLabel="name"
                filter
                filterBy="name"
                placeholder={defaultValue}
                id={elementId}
                defaultValue={defaultValue}
                valueTemplate={selectedValueTemplate}
                itemTemplate={optionsTemplate}
                name={elementId}
                className="w-full"
                showClear={showClearIcon}
                tabIndex={pageTabIndex}
            />
        </div>
    );
};

export default FilterSelect;
