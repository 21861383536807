import {ReactNode} from "react";
import {TStore} from "../types/TStore";
import {IAddress} from "../interfaces/IAddress";
import {IOutlet, TCategory, TCommonFields, TItemSubcategory, TRelatedItemCategory} from "../types/UtilTypes";
import {DropdownOption} from "./utils";

export type TResponseData<T>={
    data?:T,
    status:number
}
export type TUsersListData={

}
export type TUserPageResponseData={
    data?:[IOutlet[],TStore[]],
    status:number
}
export type TLoginData={
    username:string,
    password:string
}
export interface MenuModel {
    label: string,
    icon: string,
    command: () => void
}
export interface ColumnDefinition<T> {
    field?: string;
    header: string;
    body?: (rowData: T) => ReactNode;
}

export type TFetchListArg ={
    userId?:number,
    storeId?:number,
    urlLink?:string,
    enableFetch?:boolean,
}
export const enum REDUCER_ACTION_TYPE {
    CHANGE_STATE_VALUES
}
export type StateReducerAction = {
    type: REDUCER_ACTION_TYPE,
    payload: {}
}


export function customReducer<T> (state:  T, action: StateReducerAction):  T {

    switch (action.type) {
        case REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES:
            return {...state, ...action.payload}
        default:
            throw new Error();
    }
}
export const useStateDispatch = (stateValues: {},dispatch: React.Dispatch<StateReducerAction>) => {
    return dispatch({
        type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
        payload: {...stateValues}
    });
}

export type TStorePage = TStore  & IAddress & TCommonFields & {
    storeTypes?:DropdownOption[],
    countries?:DropdownOption[],
}

export type TOutletSettingsData={
    outletSettings:[]
}
export interface IOutletSettings{
    items:{categories:TCategory[],relatedCategories:TRelatedItemCategory[],subCategories:TItemSubcategory[]}
}
