import { useState, useRef, Fragment, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { Timeline } from 'primereact/timeline';
import { useNavigate } from 'react-router-dom';
import { allowAccessState, formatDate, getBarChartOptions, getChartOptions, getDefaultUserSettings, getRandomHexidecimalColor, getRevenueChart, isAppOnline, queryDashboardMetaData,  useUserLocalStorage } from '../helpers/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useGlobalContext } from '../helpers/GlobeManagement';
import { TChart, TCustomerDistribution, TDashboardMeta, TExpenditure, TSaleTransactionItem, TUserSettings } from 'UtilTypes';
import GeneralPurpose from '../classes/GeneralPurpose';
import { CustomerDistributionsLabel, DashboardSnippet } from './sharedComponents/SharedComponents';
import { addMonths, format, parseISO } from 'date-fns';


type TDashBoard = {
    userSettings: TUserSettings;
    dashboardMetaData?: TDashboardMeta;
    salesChart: TChart;
    selectedSalesYear: any;
    saleTransactions: TSaleTransactionItem[];
    selectedYearTotalSales: number;
    customersDistribution?:TCustomerDistribution[],
    customerDistributionChart:TChart,
    barChartOptions:any,
    revenueChart:any,
    revenueChartOptions:any,
    stateDate:string
};



const countryChartOptions = {
    responsive: true
};

const generalPurpose = new GeneralPurpose();
const Dashboard = () => {
    const queryClient = useQueryClient();
    const [personData] = useUserLocalStorage();
    const [state, setState] = useState<TDashBoard>({
        userSettings: getDefaultUserSettings(),
        dashboardMetaData: undefined,
        salesChart: { labels: [], datasets: [] },
        selectedSalesYear: '',
        saleTransactions: [],
        selectedYearTotalSales: 0,
        customersDistribution:undefined,
        customerDistributionChart:{labels:[],datasets:[]},
        barChartOptions:{},
        revenueChart:[],
        revenueChartOptions:{},
        stateDate:''
    });

    const { changeAppMode, currentAppMode,currentUser,setCurrentUser } = useGlobalContext();
    const navigate = useNavigate();
    const orderYear = [
        { name: '2021', code: '0' },
        { name: '2020', code: '1' }
    ];
    const salesYears = [
        { name: '2024', code: '0' },
        { name: '2025', code: '1' }
    ];
    const revenueMonth = [
        { name: 'January - July 2021', code: '0' },
        { name: 'August - December 2020', code: '1' }
    ];

  const [selectedRevenueMonth, setSelectedRevenueMonth] = useState(revenueMonth[0]);
    const [selectedOrderYear, setSelectedOrderYear] = useState(orderYear[0]);

    const salesChartRef = useRef<any>(null);
    const revenue = useRef<any>(null);

    let growth = '$620,076';
    let avgCustomer = '$1,120';

    useEffect(()=>{
        if (!personData){
            navigate('/login');
            return;
        };
        if (!allowAccessState('/', personData?.userRole as number)) navigate('/sales');
        
    },[]);
    useEffect(() => {
        syncDashboardMeta().catch(console.error);
    }, []);
    useEffect(() => {
        salesChartRef.current.refresh();
    }, [state.selectedSalesYear]);
    const syncDashboardMeta = async () => {
        

       
        const userSettings = {
            ...state.userSettings,
            ...await generalPurpose.getUserSettings()
        };
      
        const dashBoardData = isAppOnline(userSettings)?await queryDashboardMetaData(queryClient, personData!,formatDate(new Date())):await generalPurpose.getDashboardMetaLocal(personData?.outletId!,formatDate(new Date()));
        // const saleTransactions = await saleTransactionsQuery(personData!);

        const modifiedCustomersDistributionData=dashBoardData.customerDistributions!.map((customerDist)=>{return {...customerDist,customerColor:getRandomHexidecimalColor()}});
        const monthlySalesCount=getMonthlyCountSales(parseInt(salesYears[0].name),dashBoardData.salesTransactions);
        const accumulatedMonthlySales=getAccumulatedMonthlySales(parseInt(salesYears[0].name),dashBoardData.salesTransactions);
        const accumulatedMonthlyExpenditures=getAccumulatedMonthlyExpenditures(parseInt(salesYears[0].name),dashBoardData.expenditures);
        const accumulatedRevenue=getAccumulatedRevenue(accumulatedMonthlySales,accumulatedMonthlyExpenditures);
        setStateValues({
            dashboardMetaData: typeof dashBoardData.dashBoardMeta === 'string' ? JSON.parse(dashBoardData.dashBoardMeta) : dashBoardData.dashBoardMeta,
            saleTransactions:dashBoardData.salesTransactions,
            customersDistribution:modifiedCustomersDistributionData,
            salesChart:salesChart(monthlySalesCount),
            selectedSalesYear:salesYears[0],
            customerDistributionChart:getCustomerDistributionsChart(modifiedCustomersDistributionData),
            barChartOptions:getBarChartOptions(Math.max(...monthlySalesCount)+100),
            revenueChart:getRevenueChart(accumulatedMonthlySales,accumulatedMonthlyExpenditures,accumulatedRevenue),
            revenueChartOptions:getChartOptions(Math.max(...accumulatedMonthlySales))
        });
        changeAppMode!(userSettings.appMode);
        setCurrentUser!(personData!);
    };
    const timelineEvents = [
        {
            transaction: 'Payment from #28492',
            amount: '+$250.00',
            date: 'June 13, 2020 11:09 AM',
            icon: 'pi pi-check',
            iconColor: '#0F8BFD',
            amountColor: '#00D0DE'
        },
        {
            transaction: 'Process refund to #94830',
            amount: '-$570.00',
            date: 'June 13, 2020 08:22 AM',
            icon: 'pi pi-refresh',
            iconColor: '#FC6161',
            amountColor: '#FC6161'
        },
        {
            transaction: 'New 8 user to #5849',
            amount: '+$50.00',
            date: 'June 12, 2020 02:56 PM',
            icon: 'pi pi-plus',
            iconColor: '#0BD18A',
            amountColor: '#0BD18A'
        },
        {
            transaction: 'Payment from #3382',
            amount: '+$3830.00',
            date: 'June 11, 2020 06:11 AM',
            icon: 'pi pi-check',
            iconColor: '#0F8BFD',
            amountColor: '#00D0DE'
        },
        {
            transaction: 'Payment from #4738',
            amount: '+$845.00',
            date: 'June 11, 2020 03:50 AM',
            icon: 'pi pi-check',
            iconColor: '#0F8BFD',
            amountColor: '#00D0DE'
        },
        {
            transaction: 'Payment failed form #60958',
            amount: '$1450.00',
            date: 'June 10, 2020 07:54 PM',
            icon: 'pi pi-exclamation-triangle',
            iconColor: '#EC4DBC',
            amountColor: '#EC4DBC'
        },
        {
            transaction: 'Payment from #5748',
            amount: '+$50.00',
            date: 'June 09, 2020 11:37 PM',
            icon: 'pi pi-check',
            iconColor: '#0F8BFD',
            amountColor: '#00D0DE'
        },
        {
            transaction: 'Removed 32 users from #5849',
            amount: '-$240.00',
            date: 'June 09, 2020 08:40 PM',
            icon: 'pi pi-minus',
            iconColor: '#FC6161',
            amountColor: '#FC6161'
        }
    ];

    const setStateValues = (stateValues: Partial<TDashBoard>) => {
        setState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };

    const changeRevenueChart = (event: any) => {
        setSelectedRevenueMonth(event.value);
        const dataSet1 = [
            [37, 34, 21, 27, 10, 18, 15],
            [31, 27, 30, 37, 23, 29, 20],
            [21, 7, 13, 3, 19, 11, 6],
            [47, 31, 35, 20, 46, 39, 25]
        ];
        const dataSet2 = [
            [31, 27, 30, 37, 23, 29, 20],
            [47, 31, 35, 20, 46, 39, 25],
            [37, 34, 21, 27, 10, 18, 15],
            [21, 7, 13, 3, 19, 11, 6]
        ];

        if (event.value.code === '1') {
            state.revenueChart.datasets[0].data = dataSet2[parseInt('0')];
            state.revenueChart.datasets[1].data = dataSet2[parseInt('1')];
            state.revenueChart.datasets[2].data = dataSet2[parseInt('2')];
        }

        revenue.current.refresh();
    };

    const changeSalesChartYear = (event: any) => {
        /**
         * !TODO: GO INTO DB TO RETRIEVE SALES DATA FOR THE SELECTED YEAR ONLY
         */
        setStateValues({ salesChart: salesChart(getMonthlyCountSales(parseInt(event.value.name),state.saleTransactions)), selectedSalesYear: event.value });
    };

    const getMonthlyCountSales=(selectedYear:number,saleTransactions:TSaleTransactionItem[])=>{
        try{
            const currentYearSales = saleTransactions.filter((transaction) => parseISO(transaction.transactionDate as string).getFullYear() == selectedYear);
            const firstMonthOfYear = new Date(`${selectedYear}-01-01T00:00:00.000Z`);
            const lastMonthOfYear = new Date(`${selectedYear+ 1}-01-01T00:00:00.000Z`);
            const monthlyCounts = [];
            let currentMonth = firstMonthOfYear;
    
            while (currentMonth < lastMonthOfYear) {
                const thisMonth = format(currentMonth, 'yyyy-MM');
                const salesThisMonth = currentYearSales.filter((sale) => format(parseISO(sale.transactionDate as string), 'yyyy-MM') === thisMonth);
                monthlyCounts.push(salesThisMonth.length);
                currentMonth = addMonths(currentMonth, 1);
            }
            return monthlyCounts;
        }catch(error:any){
            throw error;
        }

    }
    const getAccumulatedMonthlySales=(selectedYear:number,saleTransactions:TSaleTransactionItem[])=>{
        try{
            const currentYearSales = saleTransactions.filter((transaction) => parseISO(transaction.transactionDate as string).getFullYear() == selectedYear);
            const firstMonthOfYear = new Date(`${selectedYear}-01-01T00:00:00.000Z`);
            const lastMonthOfYear = new Date(`${selectedYear+ 1}-01-01T00:00:00.000Z`);
            const monthlySales = [];
            let currentMonth = firstMonthOfYear;
    
            while (currentMonth < lastMonthOfYear) {
                const thisMonth = format(currentMonth, 'yyyy-MM');
                const salesThisMonth = currentYearSales.filter((sale) => format(parseISO(sale.transactionDate as string), 'yyyy-MM') === thisMonth).reduce((previousValue:number,currentValue)=>{
                    return previousValue+currentValue.transactionCost
                },0);
                monthlySales.push(salesThisMonth);
                currentMonth = addMonths(currentMonth, 1);
            }
            return monthlySales;
        }catch(error:any){
            throw error;
        }

    }
    const getAccumulatedMonthlyExpenditures=(selectedYear:number,expenditures:TExpenditure[])=>{
        try{
            const currentYearExpenses = expenditures.filter((transaction) => parseISO(transaction.expenditureDate as string).getFullYear() == selectedYear);
            const firstMonthOfYear = new Date(`${selectedYear}-01-01T00:00:00.000Z`);
            const lastMonthOfYear = new Date(`${selectedYear+ 1}-01-01T00:00:00.000Z`);
            const monthlyExpenditure = [];
            let currentMonth = firstMonthOfYear;
    
            while (currentMonth < lastMonthOfYear) {
                const thisMonth = format(currentMonth, 'yyyy-MM');
                const expensesThisMonth = currentYearExpenses.filter((expense) => format(parseISO(expense.expenditureDate as string), 'yyyy-MM') === thisMonth).reduce((previousValue:number,currentValue)=>{
                    return previousValue+currentValue.expenditureAmount
                },0);
                monthlyExpenditure.push(expensesThisMonth);
                currentMonth = addMonths(currentMonth, 1);
            }
            return monthlyExpenditure;
        }catch(error:any){
            throw error;
        }

    }
    const getAccumulatedRevenue=(salesArray:number[],expensesArray:number[])=>{
        let result = [];

        // Calculate the positional subtraction
        for (let i = 0; i < salesArray.length; i++) {
            result.push(salesArray[i] - expensesArray[i]);
        }
        return result;
    }
    const marker = (item: any) => {
        return (
            <span className="custom-marker" style={{ backgroundColor: item.iconColor }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const content = (item: any) => {
        return (
            <>
                <div className="flex align-items-center justify-content-between">
                    <p>{item.transaction}</p>
                    <h6 style={{ color: item.amountColor }}> {item.amount}</h6>
                </div>
                <span>{item.date}</span>
            </>
        );
    };

    const salesChart = (monthlySalesData: number[]): TChart => {
        return {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: 'Plan',
                    data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    borderColor: '#FC6161',
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    type: 'line',
                    fill: false,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    label: 'Growth actual',
                    data: monthlySalesData,
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
                    fill: true,
                    barPercentage: 0.5
                }
            ]
        };
    };
    const getCustomerDistributionsChart=(customersData:TCustomerDistribution[]):TChart=>{
        const colors=customersData.map((customer)=>customer.customerColor);
        return {
            labels: customersData.map((customer)=>customer.fullName),
            datasets: [
                {
                    data: customersData.map((customer)=>customer.totalTransaction),
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    borderColor: 'transparent',
                    fill: true,
                    barPercentage: 0
                }
            ]
        }
    }
    return (
        <>
            <div className="layout-dashboard">
                <div className="grid">
                    <DashboardSnippet
                        title="TODAY SALES"
                        variance={state.dashboardMetaData?.salesVariance}
                        titleFigure={state.dashboardMetaData?.todaySales}
                        descriptiveImage={state.dashboardMetaData?.salesVariance == 0 ? 'quantity' : state.dashboardMetaData?.salesVariance! < 0 ? 'rate' : 'value'}
                    />
                    <DashboardSnippet
                        title="TODAY EXPENDITURE"
                        variance={state.dashboardMetaData?.expenditureVariance}
                        titleFigure={state.dashboardMetaData?.todayExpenditures}
                        descriptiveImage={state.dashboardMetaData?.expenditureVariance == 0 ? 'quantity' : state.dashboardMetaData?.expenditureVariance! < 0 ? 'rate' : 'value'}
                    />
                    <DashboardSnippet
                        title="TODAY REVENUE"
                        variance={state.dashboardMetaData?.revenueVariance}
                        titleFigure={state.dashboardMetaData?.todayRevenue}
                        descriptiveImage={state.dashboardMetaData?.revenueVariance == 0 ? 'quantity' : state.dashboardMetaData?.revenueVariance! < 0 ? 'rate' : 'value'}
                    />

                    <div className="col-12 md:col-8">
                        <div className="card widget-visitor-graph">
                            <div className="card-header">
                                <span>Monthly Sales Count</span>
                                <Dropdown options={salesYears} value={state.selectedSalesYear} optionLabel="name" onChange={changeSalesChartYear}></Dropdown>
                            </div>

                            {/*<div className="graph-content grid">*/}
                            {/*    <div className="col-12 md:col-6">*/}
                            {/*        <h2>{growth}</h2>*/}
                            {/*        <h6>MRR GROWTH</h6>*/}
                            {/*        <p>*/}
                            {/*            Measure how fast you’re growing mothly recurring revenue. <button className="p-link">Learn more</button>*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-12 md:col-6">*/}
                            {/*        <h2>{avgCustomer}</h2>*/}
                            {/*        <h6>AVG. MRR/CUSTOMER</h6>*/}
                            {/*        <p>*/}
                            {/*            The revenue generated per account on a monthly or yearly basis. <button className="p-link">Learn more</button>*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="graph">
                                <h6>Monthly Sales</h6>
                                <Chart ref={salesChartRef} type="bar" data={state.salesChart} options={state.barChartOptions} id="sales-chart"></Chart>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <div className="card widget-timeline hidden">
                            <div className="timeline-header flex justify-content-between align-items-center">
                                <p>Transaction history</p>
                                <div className="header-icons">
                                    <i className="pi pi-refresh"></i>
                                    <i className="pi pi-filter"></i>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <Timeline value={timelineEvents} marker={marker} content={content} className="custimized-timeline" />
                            </div>
                            <div className="timeline-footer flex align-items-center justify-content-center">
                                <button className="p-link">
                                    View all transactions <i className="pi pi-arrow-down"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <div className="card widget-country-graph">
                            <Fragment>
                                <div className="flex align-items-center justify-content-between mb-2">
                                    <div className="country-title">Customers distrubutions [Current Month]</div>
                                </div>
                            </Fragment>
                            <div className="country-graph flex justify-content-center">
                                <Chart type="doughnut" id="country-chart" data={state.customerDistributionChart} options={countryChartOptions} style={{ position: 'relative', width: '75%' }}></Chart>
                            </div>
                            <div className="country-content">
                                <ul>
                                    {state.customersDistribution!==undefined &&
                                        state.customersDistribution!.map((customer,index)=>{
                                            return <CustomerDistributionsLabel backgroundColor={customer.customerColor} customerName={customer.fullName} customerTransactionsTotal={customer.totalTransaction} key={index}/>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-8">
                        <div className="card widget-revenue-graph">
                            <div className="card-header">
                                <span>Monthly revenue</span>
                                <Dropdown options={revenueMonth} value={selectedRevenueMonth} optionLabel="name" onChange={changeRevenueChart}></Dropdown>
                            </div>

                            <div className="graph">
                                <Chart ref={revenue} type="line" id="revenue-chart" data={state.revenueChart} options={state.revenueChartOptions}></Chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
